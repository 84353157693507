import React, {useEffect, useState} from "react";
import {
  Box, Checkbox, FormControl,
  IconButton, InputLabel, MenuItem,
  Modal, Select,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import {NIL} from "uuid";
import { IOSSwitch } from "../../components/admin/venueManagement/iosSwitch";
import { getMonitorAlertSettings, setMonitorAlertSettings } from "../../_services/indicators.MonitorAlertSetting";
import { getUserGroups } from "../../_services/venue.userGroup.service";

import {Button, ButtonType} from "../_common/htmlTags";
import {makeStyles} from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {toast} from "react-toastify";

const useStyles = makeStyles((theme) => {
  return {
    popup: {
      width: "800px",
      backgroundColor: "#FFFFFF",
      position: "absolute",
      top: "50%",
      left: "50%",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      padding: theme.spacing(3),
    },
    title: {
      fontSize: "24px",
      fontWeight: "500",
      flexGrow: 1
    },
    closeIcon: {
      marginTop:"-20px",
      marginRight: "-20px"
    },
    enableMonitorToggle: {
      marginTop: theme.spacing(2),
    },
    field: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2)
    },
    checkboxGroupLabel: {
      marginTop: theme.spacing(2),
    },
    buttons: {
      marginTop: theme.spacing(2),
    }
  };
});
const MonitorAlertSettingPopup = ({selectedVenueId, show, onSave, onCancel}) =>
{
  const classes = useStyles();
  const [isSaving, setIsSaving] = useState(false);
  const [monitorAlertSetting, setMonitorAlertSetting] = useState({
    id: NIL,
    venueId: selectedVenueId,
    enabled: false,
    thresholdInMinutes: 2,
    resendAllLocationsInMinutes: 10,
    userGroupId: NIL,
    sendBySMS: false,
    sendByEmail: true,
    monitorOccupancy: true,
    monitorTrafficInOut: false,
    monitorQueueMetrics: false,
    monitorDemographics: false
  });
  const [userGroups, setUserGroups] = useState([]);
  useEffect(() => {
    if (selectedVenueId) {
      getUserGroups(selectedVenueId).then(d => {
        if (monitorAlertSetting.userGroupId === NIL && d.data.length > 0)
          setMonitorAlertSetting({...monitorAlertSetting, userGroupId: d.data[0].id}); // set userGroupId to first value when it's set to EMPTY GUID
        setUserGroups(d.data);
      }).catch(error => {
        console.error(`getUserGroups ${selectedVenueId}`, error);
      });
    }
  }, [selectedVenueId]);

  useEffect(() => {
    getMonitorAlertSettings(selectedVenueId).then (d => {
      console.log("ddd", d);
      if (d.data !== "") {
        let data = d.data;
        setMonitorAlertSetting(data); // set only the first object for now... If we need to have ability to store multiple then we need to update this
      }
    }).catch(error => {
      console.error(`getMonitorAlertSettings ${selectedVenueId}`, error);
    });    
  }, [userGroups]);

  useEffect(() => {
  }, [monitorAlertSetting, isSaving]);

  console.log("monitorAlertSetting", monitorAlertSetting);
  const handleSave = (e) => {
    e.preventDefault();
    setIsSaving(true);
    // validate the input when the alert is enabled
    if (monitorAlertSetting.enabled) {
      if (!monitorAlertSetting.sendBySMS && !monitorAlertSetting.sendByEmail) {
        toast.error("Please select at least one or more notification method(s).", {autoClose: false});
        setIsSaving(false);
        return;
      }
      if (monitorAlertSetting.userGroupId === NIL) {
        toast.error("Please select user group.", {autoClose: false});
        setIsSaving(false);
        return;
      }
      if (!monitorAlertSetting.monitorOccupancy && !monitorAlertSetting.monitorTrafficInOut
        && !monitorAlertSetting.monitorQueueMetrics && !monitorAlertSetting.monitorDemographics) {
        toast.error("Please select at least one or more data type to monitor", {autoClose: false});
        setIsSaving(false);
        return;
      }
    }
    
    /// saving into the database
    setMonitorAlertSettings(monitorAlertSetting).then(d => {
      if (d.status === 200) {
        setMonitorAlertSetting({...monitorAlertSetting, id: d.data});
        toast.success("Monitor alert settings successfully!");
      }
      else {
        toast.error(`Save Monitor alert settings failed: ${d.data}`, {autoClose: false});
      }
      onSave();
    }).catch(error => {
      toast.error(`Save Monitor alert settings failed: ${error.message}`, {autoClose: false});
      console.error("    setMonitorAlertSettings(monitorAlertSetting).then(d => {\n", monitorAlertSetting);
    })
  };
  
  return (
    <Modal open={show}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          maxHeight: "920px",
          alignItems: "stretch"
        }}
        className={classes.popup}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start"
          }}
        >
          <Typography className={classes.title}>
            Monitor alert
          </Typography>
          <Tooltip
            title="Close"
          >
            <IconButton
              aria-label="view"
              className={classes.closeIcon}
              onClick={(e) => {
                onCancel();
              }}
            >
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </Box>
        <FormControlLabel
          control={<IOSSwitch checked={monitorAlertSetting.enabled} onChange={(e) => {
            setMonitorAlertSetting({...monitorAlertSetting, enabled: e.target.checked});
          }} name="enableMonitoring" />}
          label="Enable monitor alert"
          className={classes.enableMonitorToggle}
        />
        <TextField
          id="threshold"
          label="Threshold in minutes"
          name="threshold"
          value={monitorAlertSetting.thresholdInMinutes}
          type={"number"}
          disabled={!monitorAlertSetting.enabled}
          onChange={(e) => {
            setMonitorAlertSetting({...monitorAlertSetting, thresholdInMinutes: e.target.value});
          }}
          variant="outlined"
          required
          fullWidth
          className={classes.field}
        />
        <TextField
          id="resend"
          label="Reminder in minutes"
          name="resend"
          value={monitorAlertSetting.resendAllLocationsInMinutes}
          type={"number"}
          disabled={!monitorAlertSetting.enabled}
          onChange={(e) => {
            setMonitorAlertSetting({...monitorAlertSetting, resendAllLocationsInMinutes: e.target.value});
          }}
          variant="outlined"
          required
          fullWidth
          className={classes.field}
        />
        <FormControl variant="outlined" fullWidth required className={classes.field}>
          <InputLabel id="userGroup-label">User group</InputLabel>
          <Select
            labelId="userGroup-label"
            id="userGroupId"
            name="userGroupId"
            value={monitorAlertSetting.userGroupId}
            disabled={!monitorAlertSetting.enabled}
            onChange={(e)=> {
              setMonitorAlertSetting({...monitorAlertSetting, userGroupId: e.target.value});
            }}
            label="userGroupId"
          >
            {userGroups.map((e) => (
                <MenuItem key={e.id} value={e.id}>
                    {e.name}
                </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Typography className={classes.checkboxGroupLabel}>
          Notification method *:
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start"
          }}
        >
          <FormControlLabel
            control={<Checkbox name="sendBySMS" checked={monitorAlertSetting.sendBySMS} disabled={!monitorAlertSetting.enabled} onChange={(e) => {
              setMonitorAlertSetting({...monitorAlertSetting, sendBySMS: e.target.checked});
            }} />}
            label="SMS"
          />
          <FormControlLabel
            control={<Checkbox name="sendByEmail" checked={monitorAlertSetting.sendByEmail} disabled={!monitorAlertSetting.enabled} onChange={(e) => {
              setMonitorAlertSetting({...monitorAlertSetting, sendByEmail: e.target.checked});
            }} />}
            label="Email"
          />
        </Box>
        <Typography className={classes.checkboxGroupLabel}>
          Data type to monitor *:
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start"
          }}
        >
          <FormControlLabel
            control={<Checkbox name="monitorOccupancy" checked={monitorAlertSetting.monitorOccupancy} disabled={!monitorAlertSetting.enabled} onChange={(e) => {
              setMonitorAlertSetting({...monitorAlertSetting, monitorOccupancy: e.target.checked});
            }} />}
            label="Occupancy"
          />
          <FormControlLabel
            control={<Checkbox name="monitorTrafficInOut" checked={monitorAlertSetting.monitorTrafficInOut} disabled={!monitorAlertSetting.enabled} onChange={(e) => {
              setMonitorAlertSetting({...monitorAlertSetting, monitorTrafficInOut: e.target.checked});
            }} />}
            label="Traffic in/out"
          />
          <FormControlLabel
            control={<Checkbox name="monitorQueueMetrics" checked={monitorAlertSetting.monitorQueueMetrics} disabled={!monitorAlertSetting.enabled} onChange={(e) => {
              setMonitorAlertSetting({...monitorAlertSetting, monitorQueueMetrics: e.target.checked});
            }} />}
            label="Queue metrics"
          />
          <FormControlLabel
            control={<Checkbox name="monitorDemographics" checked={monitorAlertSetting.monitorDemographics} disabled={!monitorAlertSetting.enabled} onChange={(e) => {
              setMonitorAlertSetting({...monitorAlertSetting, monitorDemographics: e.target.checked});
            }} />}
            label="Demographics"
          />
        </Box>
        <div className={classes.buttons}>
          <Button
            variant="contained"
            type="submit"
            disabled={isSaving}
            buttonType={ButtonType.Primary}
            onClick={handleSave}
            style={{
              padding: "10px",
              marginRight: "10px",
            }}
          >
            {isSaving ? "Saving..." : "Save"}
          </Button>&nbsp;
          <Button
            className="btn primary"
            variant="outlined"
            type="button"
            buttonType={ButtonType.Secondary}
            onClick={() => {
              onCancel();
            }}
            style={{
              padding: "9px"
            }}
          >Cancel</Button>
        </div>
      </Box>
    </Modal>
  );
}
export default MonitorAlertSettingPopup;