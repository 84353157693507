export const convertDateStrToIanaTz = (dateStr, ianaTimeZoneId) => {
  const dateInBrowserLocale = new Date(dateStr);
  const dateStrInEnUs = dateInBrowserLocale.toLocaleString('en-US', {hourCycle: 'h23'});

  for(let offsetInMins = -25 * 60; offsetInMins<= 25 * 60; offsetInMins += 30) {
    const offsetDate = new Date(new Date(dateStr).getTime() + offsetInMins * 60 * 1000);
    const offsetDateStr = offsetDate.toLocaleString('en-US', {timeZone: ianaTimeZoneId, hourCycle: 'h23'});
    if (offsetDateStr == dateStrInEnUs) {
      return offsetDate;
    }
  }
};

export const convertDateToISOStringInIanaTz = (date, ianaTimeZoneId) => {
  const year = date.toLocaleDateString(undefined, {
    timeZone: ianaTimeZoneId,
    year: 'numeric',
  });
  const month = date.toLocaleDateString(undefined, {
    timeZone: ianaTimeZoneId,
    month: '2-digit',
  });
  const day = date.toLocaleDateString(undefined, {
    timeZone: ianaTimeZoneId,
    day: 'numeric',
  });
  return year + '-' + month + '-' + day;
};

// This method has l10n issue. It only works with full stop as the decimal point.
export const isNumeric = (str) => {
  if (typeof str != "string") return false // we only process strings!  
  return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
         !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
};

export const isInteger = (str) => {
  if (typeof str != "string") return false // we only process strings!  
  return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
     (str >>> 0 === parseFloat(str));  // checks for non-negative integer.
};