import axios from "axios";
import { AuthService} from ".";
import { ApiRootConstants } from "../_constants/index";

const constants = {
  api: `${ApiRootConstants.venueManagementApiRoot}api/VenueAccredit`,
};

const authService = new AuthService();

export async function getAccreditApiSettings(venueId) {
  const user = await authService.getUser();
  if (user && user.access_token) {
    const headers = {
      Accept: "application/json",
      Authorization: "Bearer " + user.access_token,
    };

    if (headers !== null) {
      return axios
        .get(
          `${constants.api}/GetApiSettings/${venueId}`,
          { headers }
        )
        .catch((error) => {
          console.error(error);
          if (error.response) {
            if (error.response.status === 401) {
              throw new Error("User is not logged in");
            }
            else {
              throw error;
            }
          }
        });
    }
  }
};

export async function updateAccreditApiSettings(apiSettings) {
  const user = await authService.getUser();
  if (user && user.access_token) {
    const options = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + user.access_token,
        ContentType: "multipart/form-data"
      },
    };

    const formData = apiSettings;
    
    return await axios
      .put(`${constants.api}/UpdateApiSettings`, 
        formData,
        options)
      .catch((error) => {
        console.error(error);
        if (error.response.status === 401) {
          throw new Error("User is not logged in");
        } else if (error.response.status === 422) {
          throw new Error(error.response.data.message);
        } else if (error.response.status === 400) {
          throw new Error(error.response.data);
        }

        throw error;
      });
  }
};
/*************************************************************** */
/* AccreditLocationExtensions */
export async function getAccreditLocationExtensions(venueId) {
  const user = await authService.getUser();
  if (user && user.access_token) {
    const options = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + user.access_token
      },
    };

    return await axios
      .get(`${constants.api}/LocationExtensions/${venueId}`, 
        options)
      .catch((error) => {
        console.error(error);
        if (error.response.status === 401) {
          throw new Error("User is not logged in");
        } else if (error.response.status === 422) {
          throw new Error(error.response.data.message);
        } else if (error.response.status === 400) {
          throw new Error(error.response.data);
        }

        throw error;
      });
  }
};

export async function addAccreditLocationExtensions(accreditLocationExtension) {
  const user = await authService.getUser();
  if (user && user.access_token) {
    const options = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + user.access_token,
        ContentType: "multipart/form-data"
      },
    };

    const formData = accreditLocationExtension;
    
    return await axios
      .post(`${constants.api}/LocationExtensions`, 
        formData,
        options)
      .catch((error) => {
        console.error(error);
        if (error.response.status === 401) {
          throw new Error("User is not logged in");
        } else if (error.response.status === 422) {
          throw new Error(error.response.data.message);
        } else if (error.response.status === 400) {
          throw new Error(error.response.data);
        }

        throw error;
      });
  }
};

export async function updateAccreditLocationExtensions(accreditLocationExtension) {
  const user = await authService.getUser();
  if (user && user.access_token) {
    const options = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + user.access_token,
        ContentType: "multipart/form-data"
      },
    };

    const formData = accreditLocationExtension;
    
    return await axios
      .put(`${constants.api}/LocationExtensions`, 
        formData,
        options)
      .catch((error) => {
        console.error(error);
        if (error.response.status === 401) {
          throw new Error("User is not logged in");
        } else if (error.response.status === 422) {
          throw new Error(error.response.data.message);
        } else if (error.response.status === 400) {
          throw new Error(error.response.data);
        }

        throw error;
      });
  }
};


export async function deleteAccreditLocationExtensions(id) {
  const user = await authService.getUser();
  if (user && user.access_token) {
    const options = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + user.access_token,
      },
    };
    
    return await axios
      .delete(`${constants.api}/LocationExtensions/${id}`, 
        options)
      .catch((error) => {
        console.error(error);
        if (error.response.status === 401) {
          throw new Error("User is not logged in");
        } else if (error.response.status === 422) {
          throw new Error(error.response.data.message);
        } else if (error.response.status === 400) {
          throw new Error(error.response.data);
        }

        throw error;
      });
  }
};

/*************************************************************** */
/* AccreditRegistrationSubTypes */
export async function getAccreditRegistrationSubTypes(venueId) {
  const user = await authService.getUser();
  if (user && user.access_token) {
    const options = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + user.access_token,
      },
    };

    return await axios
      .get(`${constants.api}/RegistrationSubTypes/${venueId}`, 
        options)
      .catch((error) => {
        console.error(error);
        if (error.response.status === 401) {
          throw new Error("User is not logged in");
        } else if (error.response.status === 422) {
          throw new Error(error.response.data.message);
        } else if (error.response.status === 400) {
          throw new Error(error.response.data);
        }

        throw error;
      });
  }
};

export async function addAccreditRegistrationSubTypes(accreditRegistrationSubType) {
  const user = await authService.getUser();
  if (user && user.access_token) {
    const options = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + user.access_token,
        ContentType: "multipart/form-data"
      },
    };

    const formData = accreditRegistrationSubType;
    
    return await axios
      .post(`${constants.api}/RegistrationSubTypes`, 
        formData,
        options)
      .catch((error) => {
        console.error(error);
        if (error.response.status === 401) {
          throw new Error("User is not logged in");
        } else if (error.response.status === 422) {
          throw new Error(error.response.data.message);
        } else if (error.response.status === 400) {
          throw new Error(error.response.data);
        }

        throw error;
      });
  }
};

export async function updateAccreditRegistrationSubTypes(accreditRegistrationSubType) {
  const user = await authService.getUser();
  if (user && user.access_token) {
    const options = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + user.access_token,
        ContentType: "multipart/form-data"
      },
    };

    const formData = accreditRegistrationSubType;
    
    return await axios
      .put(`${constants.api}/RegistrationSubTypes`, 
        formData,
        options)
      .catch((error) => {
        console.error(error);
        if (error.response.status === 401) {
          throw new Error("User is not logged in");
        } else if (error.response.status === 422) {
          throw new Error(error.response.data.message);
        } else if (error.response.status === 400) {
          throw new Error(error.response.data);
        }

        throw error;
      });
  }
};


export async function deleteAccreditRegistrationSubTypes(id) {
  const user = await authService.getUser();
  if (user && user.access_token) {
    const options = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + user.access_token,
        ContentType: "multipart/form-data"
      },
    };

    return await axios
      .delete(`${constants.api}/RegistrationSubTypes/${id}`, 
        options)
      .catch((error) => {
        console.error(error);
        if (error.response.status === 401) {
          throw new Error("User is not logged in");
        } else if (error.response.status === 422) {
          throw new Error(error.response.data.message);
        }

        throw error;
      });
  }
};
/*************************************************************** */
/* AccreditRegistrationSubTypeGroup */
export async function getAccreditRegistrationSubTypeGroup() {
  const user = await authService.getUser();
  if (user && user.access_token) {
    const options = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + user.access_token
      },
    };

    return await axios
      .get(`${constants.api}/AccreditRegistrationSubTypeGroup`, 
        options)
      .catch((error) => {
        console.error(error);
        if (error.response.status === 401) {
          throw new Error("User is not logged in");
        } else if (error.response.status === 422) {
          throw new Error(error.response.data.message);
        } else if (error.response.status === 400) {
          throw new Error(error.response.data);
        }

        throw error;
      });
  }
};