import { alpha } from '@material-ui/core';

const globalStyleOverrides = (theme) => {
  const colorForDateTimePickerIcon = theme.palette.common.panelBorderColor.replace("#", "%23");
  
  return {
    // When a TextField is focused, the border color should be blue-green.
    MuiTextField: {
      root: {
        "& label": {
          color: theme.palette.common.panelBorderColor,
        },
        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
          borderColor: theme.palette.common.panelBorderColor,
        },
        '& label.Mui-focused': {
          color: theme.palette.color.primary.main,
        },
        "& .MuiOutlinedInput-root.Mui-focused  .MuiOutlinedInput-notchedOutline": {
          borderColor: theme.palette.color.primary.main,
          borderWidth: 1
        },
        "& .MuiOutlinedInput-root input": {
          paddingTop: "12.5px",
          paddingBottom: "12.5px",
        },
        "& .MuiInput-underline:before": {
          borderBottomColor: theme.palette.common.panelBorderColor,
        },
        "& .MuiInput-underline:after": {
          borderBottomColor: theme.palette.color.primary.main,
        },
        "& label:not(.MuiInputLabel-outlined)" : {
          transform: "translate(0px, 5px) scale(0.75)",
        },
        "& .MuiInput-underline input[type^=date]::-webkit-calendar-picker-indicator": {
          // Changing the whole image seems to be the only way to change the color
          // of the icon in the date or time picker.
          // The SVG is taken from CalendarTodayIcon
          backgroundImage: "url('data:image/svg+xml;utf8,<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"16\" height=\"15\" viewBox=\"0 0 24 24\"><path fill=\"" + colorForDateTimePickerIcon + "\" d=\"M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V8h16v13z\"/></svg>');"
        },
        "& .MuiInput-underline input[type=time]::-webkit-calendar-picker-indicator": {
          // The SVG is taken from ScheduleIcon
          backgroundImage: "url('data:image/svg+xml;utf8,<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"16\" height=\"15\" viewBox=\"0 0 24 24\"><path fill=\"" + colorForDateTimePickerIcon + "\" d=\"M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z\"/><path fill=\"" + colorForDateTimePickerIcon + "\" d=\"M12.5 7H11v6l5.25 3.15.75-1.23-4.5-2.67z\"/></svg>');"
        },
        "& .MuiInput-underline .MuiInputBase-input": {
          paddingTop: "3px",
          paddingBottom: "6px"
        }
      },
    },
    MuiFormControl: {
      root: {
        "& label": {
          color: theme.palette.common.panelBorderColor,
          transform: "translate(14px, 14px) scale(1)"
        },
        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
          borderColor: theme.palette.common.panelBorderColor,
        },
        "& .MuiOutlinedInput-root .MuiSelect-icon": {
          color: theme.palette.common.panelBorderColor,
        },
        '& label.Mui-focused': {
          color: theme.palette.color.primary.main,
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
          borderColor: theme.palette.color.primary.main,
          borderWidth: 1
        },
        "& .MuiSelect-root" : {
          paddingTop: "12.5px",
          paddingBottom: "12.5px",
        }
      }
    },
    MuiCheckbox: {
      colorSecondary: {
        '&$checked': {
          color: theme.palette.color.primary.main,
        },
      },
    },
    MuiRadio: {
      root: {
        color: theme.palette.color.secondary.main,
        '&$checked': {
          color:  theme.palette.color.primary.main,
        }  
      },
      colorPrimary: {
        '&$checked': {
          color:  theme.palette.color.primary.main,
        },
        checked: {
        },
      },
    },
    MuiDataGrid: {
      root: {
        border: 0,
        '& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell': {
          border: `0px`,
        },
        "& .MuiDataGrid-cell" : {
          paddingLeft: "16px",
          paddingRight: "16px"
        },
        "& .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within" : {
          outline: "none"
        },
        "& .MuiDataGrid-columnHeader": {
          backgroundColor: theme.palette.common.gridColumnHeaderBackground,
          outline: "none"
        },
        "& .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-columnHeader:focus": {
          outline: "none",
        },
        "& .MuiDataGrid-row:hover": {
          backgroundColor: theme.palette.common.gridRowHoverBackground + " !important"
        },
        "& .MuiDataGrid-row": {
          "&.Mui-selected" : {
            backgroundColor: theme.palette.common.gridRowSelectedBackground
          }
        },
        "& .MuiDataGrid-columnHeaderWrapper" : {
          alignItems: "stretch"
        },
        "& .MuiDataGrid-overlay": {
          backgroundColor: "none"
        }
      },
    },
    MuiTab: {
      root: {
        "&.MuiTab-root": {
          "&:hover": {
            backgroundColor: alpha(theme.palette.color.primary.background, 0.5),
          },
        },
      }
    },
    MuiSelect: {
      outlined: {
        '&:focus': {
          backgroundColor: "white",
        }
      }
    },
    MuiListItemIcon: {
      root: {
        color: null
      }
    }
  };
};

export default globalStyleOverrides;