import React from 'react';
import {Box, IconButton, SvgIcon, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: 400,
    fontSize: 14,
    color: theme.palette.color.primary.dark,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    [theme.breakpoints.down("xs")] : {
      display: "none"
    }
  },
  breakpointButton: {
    width: "36px",
    height: "36px"
  },
  selectedBreakpoint: {
    color: theme.palette.color.primary.main
  },
  notSelectedBreakpoint: {
    color: theme.palette.color.secondary.main
  },
  separator: {
    color: theme.palette.common.panelBorderColor,
    fontSize: "18px",
    paddingLeft: theme.spacing(1)
  }
  // resetLabel: {
  //   fontWeight: 400,
  //   fontSize: 14,
  //   color: theme.palette.color.primary.dark,
  // }
}));


export const BreakpointMenu = ({selectedBreakpoint, onBreakpointSelected, className}) => {
  const classes = useStyles();

  const breakpoints = [
    {
      size: 'XXS',
      path: <path d="M12 18H2V4H12M12 0H2C0.89 0 0 0.89 0 2V20C0 20.5304 0.210714 21.0391 0.585786 21.4142C0.960859 21.7893 1.46957 22 2 22H12C12.5304 22 13.0391 21.7893 13.4142 21.4142C13.7893 21.0391 14 20.5304 14 20V2C14 0.89 13.1 0 12 0Z" />,
      left: 9, top: 4, scale: 1
    },
    {
      size: 'XS',
      path: <path d="M18 2V12L4 12L4 2M0 2L0 12C0 13.11 0.89 14 2 14L20 14C20.5304 14 21.0391 13.7893 21.4142 13.4142C21.7893 13.0391 22 12.5304 22 12V2C22 1.46957 21.7893 0.960859 21.4142 0.585787C21.0391 0.210714 20.5304 0 20 0L2 0C0.89 0 0 0.9 0 2Z" />,
      left: 4, top: 10, scale: 1
    },
    {
      size: 'S',
      path: <path d="M18 14H4V2H18M20 0L2 0C0.89 0 0 0.89 0 2L0 14C0 14.5304 0.210714 15.0391 0.585786 15.4142C0.960859 15.7893 1.46957 16 2 16H20C20.5304 16 21.0391 15.7893 21.4142 15.4142C21.7893 15.0391 22 14.5304 22 14V2C22 0.89 21.1 0 20 0Z" />,
      left: 3, top: 8, scale: 1
    },
    {
      size: 'M',
      path: <path d="M4 2H20V12H4M20 14C20.5304 14 21.0391 13.7893 21.4142 13.4142C21.7893 13.0391 22 12.5304 22 12V2C22 0.89 21.1 0 20 0H4C2.89 0 2 0.89 2 2V12C2 12.5304 2.21071 13.0391 2.58579 13.4142C2.96086 13.7893 3.46957 14 4 14H0V16H24V14H20Z" />,
      left: 2, top: 6, scale: 1
    },
    {
      size: 'L',
      path: <path d="M20 14H2V2H20M20 0H2C0.89 0 0 0.89 0 2V14C0 14.5304 0.210714 15.0391 0.585786 15.4142C0.960859 15.7893 1.46957 16 2 16H9V18H7V20H15V18H13V16H20C20.5304 16 21.0391 15.7893 21.4142 15.4142C21.7893 15.0391 22 14.5304 22 14V2C22 0.89 21.1 0 20 0Z" />,
      left: 2, top: 6, scale: 1
    }
  ];

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
      }}
      className={className}
    >
      <Typography className={classes.title} key="title">
        Responsive:
      </Typography>

      {breakpoints.map((b) => (
        <IconButton 
          key={b.size}
          size="small"
          className={classes.breakpointButton}
          onClick={() => {
            onBreakpointSelected(b.size);
          }}
        >
          <SvgIcon 
            className={selectedBreakpoint === b.size ? classes.selectedBreakpoint : classes.notSelectedBreakpoint}
            style={{marginLeft: b.left, marginTop: b.top, transform: `scale(${b.scale})`}}>
              {b.path}
          </SvgIcon>
        </IconButton>
      ))}
      
      {/* <Typography className={classes.resetLabel}>
        Reset
      </Typography> */}

      <Typography className={classes.separator} key="separator">
        |
      </Typography>
    </Box>
  );
};

export default BreakpointMenu;