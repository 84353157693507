import { widgetTypeConstants } from '../../_constants/widget.type.constants';
import { WidgetSample } from './WidgetSample';
import { WidgetUnknown } from './WidgetUnknown';
import { WidgetNumber } from './widgetNumber/WidgetNumber';
import { WidgetOccupancy } from './widgetOccupancy/WidgetOccupancy';
import { WidgetMap } from './widgetMap/WidgetMap';
import { WidgetTrafficInOut } from './widgetTraffic/WidgetTrafficInOut';
import { WidgetFootfallTraffic } from './widgetTraffic/WidgetFootfallTraffic';
import { WidgetLabel } from './widgetLabel/WidgetLabel';
import { WidgetCamera } from './widgetCamera/WidgetCamera';
import { WidgetEmbedding } from './widgetEmbedding/WidgetEmbedding';
import { WidgetDemographic } from './widgetDemographic/WidgetDemographic';
import { WidgetQueueModel } from './widgetQueueModel/WidgetQueueModel';
import { WidgetPatronDistribution } from './widgetPatronDistribution/WidgetPatronDistribution';
import { WidgetTicketScansAndSales } from './widgetTicketScansAndSales/WidgetTicketScansAndSales';
import { WidgetTicketAttendanceChart } from './widgetTicketAttendanceChart/WidgetTicketAttendanceChart';

export const WidgetFactory = (props) => {

    switch (props?.widgetType) {
        case widgetTypeConstants.widgetSample.id:
            return (<WidgetSample {...props} />);
        case widgetTypeConstants.widgetOccupancy.id:
            return (<WidgetOccupancy {...props} />);
        case widgetTypeConstants.widgetTrafficInOut.id:
            return (<WidgetTrafficInOut {...props} />);
        case widgetTypeConstants.widgetFootfall.id:
            return (<WidgetFootfallTraffic {...props} />);
        case widgetTypeConstants.widgetMap.id:
            return (<WidgetMap {...props} />);
        case widgetTypeConstants.widgetNumber.id:
            return (<WidgetNumber {...props} />);
        case widgetTypeConstants.widgetLabel.id:
            return (<WidgetLabel {...props} />);
        case widgetTypeConstants.widgetCamera.id:
            return (<WidgetCamera {...props} />);
        case widgetTypeConstants.widgetEmbedding.id:
            return (<WidgetEmbedding {...props} />);
        case widgetTypeConstants.widgetDemographic.id:
                return (<WidgetDemographic {...props} />);
        case widgetTypeConstants.widgetQueueModel.id:
            return (<WidgetQueueModel {...props} />);
        case widgetTypeConstants.widgetPatronDistribution.id:
            return (<WidgetPatronDistribution {...props} />);
        case widgetTypeConstants.widgetTicketScansAndSales.id:
            return (<WidgetTicketScansAndSales {...props} />);
        case widgetTypeConstants.widgetTicketAttendanceChart.id:
            return (<WidgetTicketAttendanceChart {...props} />);
        
        default:
            return (<WidgetUnknown />)
    }
}
