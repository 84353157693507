import React, {useState, useEffect} from 'react';
import {
  Box,
  Grid,
  IconButton,
  TextField, Tooltip,
  Typography
} from "@material-ui/core";
import LoadPleaseWait from "../../../notification/LoadingPleaseWait/LoadingMessage";
import DoneIcon from "@material-ui/icons/Done";
import ClearIcon from "@material-ui/icons/Clear";
import * as venueCameraApi from "../../../../_services/venue.camera.service";
import {DataGrid} from "@material-ui/data-grid";
import {NIL as NIL_UUID} from "uuid";
import DeleteIcon from "@material-ui/icons/Delete";
import {makeStyles} from "@material-ui/core/styles";
import {AlertDialog} from "../../../../_helpers";
import {toast} from "react-toastify";
import AddIcon from "@material-ui/icons/Add";
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import EditIcon from "@material-ui/icons/Edit";
import VenueCamerasImportPopup from "./VenueCamerasImportPopup";
import { sortByName } from '../../../../_helpers/functions.sorting';
import {Button, ButtonType} from "../../../_common/htmlTags";
import useLocalStorage from "../../../../_helpers/useLocalStorage";
import useSortModel from "../../../../_helpers/useSortModel";

const useStyles = makeStyles((theme) =>
{
  return {
    icon: {
      color: theme.palette.color.primary.dark
    },
    deleteIcon: {
      color: theme.palette.color.primary.red
    },
    rowSelected: {
      backgroundColor:theme.palette.common.gridRowEditBackground,
    },
    title: {
      flexGrow: 1
    },
    titleBar: {
      display: "flex",
      alignItems: "center",
      minWidth: 0,
      //width: "calc(100% + 35px)",
      width: "60%",
      marginBottom: "0px"
    },
    addButton: {
      marginLeft: theme.spacing(1)
    },
    grid: {
      marginTop: theme.spacing(2)
    },
    vendorCodeOk: {
      color: theme.palette.color.success.main,
      height: "100%",
      marginRight: "0px"
    },
    vendorCodeCancel: {
      color: theme.palette.color.danger.main,
      height: "100%"
    },
    deleteSelectedButton: {
      alignSelf: "start",
      marginTop: "-40px",
      marginBottom: theme.spacing(1)
    }
  };
});

export const VenueCamerasSetting = ({venue}) => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [venueCameras, setVenueCameras] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [deleteId, setDeleteId] = React.useState(null);
  const [pageSize, setPageSize] = useLocalStorage("pmy-venueCameraSettings-pageSize", 5);
  const [sortModel, setSortModel] = useSortModel("pmy-venueCameraSettings-sortModel", [{field: 'name', sort: 'asc'}]);
  const [currentPage, setCurrentPage] = useState(0);
  const [editRowModel, setEditRowModel] = useState({});
  const [editRowId, setEditRowId] = useState(null);
  const [showImportModal, setShowImportModal] = useState(false);
  const [selectionModel, setSelectionModel] = useState([]); // rows selected in the list of AI streams.
  const [showConfirmDeleteSelection, setShowConfirmDeleteSelection] = useState(false);

  useEffect(() => {
    if (venueCameras === null && venue.id !== null) {
      venueCameraApi.getCameras(venue.id).then((result) => {
        setVenueCameras(result.data.sort(sortByName));
        setIsLoading(false);
      }).catch((e) => {
        toast.error("Cannot get AI Stream list", {autoClose: false});
        console.log("Cannot get AI Streams list", e);
      })
    }
  }, [venueCameras, venue.id]);
  
  const cleanUpCameraListWithoutNew = () => {
    var listWithoutNew = venueCameras.filter(a => a.id !== NIL_UUID);
    setVenueCameras(listWithoutNew);
  }
  const handleClose = () => {
    setOpen(false);
  };

  const handleRemoveCamera = (cameraId) => {
    try {
      venueCameraApi.deleteVenueCamera(cameraId)
        .then(()=> {
          toast.success("AI stream deleted successfully.");
          setVenueCameras(null); // to refresh the venue AI stream list
        })
        .catch(error => toast.error("Failed to delete AI stream: " + error, { autoClose: false }));
    } catch (error) {
      toast.error("Delete failed. " + error.message, { autoClose: false });
    }
  };

  const handleRemoveCameraRange = (cameraIds) => {
    try {
      venueCameraApi.deleteVenueCameraRange(cameraIds)
        .then(()=> {
          toast.success("AI streams deleted successfully.");
          setVenueCameras(null); // to refresh the venue AI stream list
        })
        .catch(error => toast.error("Failed to delete AI streams: " + error, { autoClose: false }));
    } catch (error) {
      toast.error("Delete failed. " + error.message, { autoClose: false });
    }
  };

  const handleExport = evt => {
    evt.preventDefault();
    if (venue.id) {
        venueCameraApi.venueCamerasExport(venue.id)
            .then(() => toast.success("AI stream exported"))
            .catch(error => toast.error("Export failed. " + (error.response?.data ?? error.message ?? error), { autoClose: false }));
    }
  }

  const columns = [
    {
      field: "id",
      hide: true,
      valueFormatter: (params) => params.row?.id,
    },
    {
      field: "venueId",
      hide: true,
      valueFormatter: (params) => params.row?.venueId,
    },
    {
      field: "name",
      headerName: "NAME",
      width: 250,
      cellClassName: (params) => { 
        if(editRowId === params.row.id)
          return classes.rowSelected; 
        else return "";
        },
      renderCell: (params) => {
        if (editRowId === params.row.id) {
          return (
            <TextField required={true}
                       placeholder={"name"} 
                       type={"text"} 
                       value={editRowModel.name} 
                       variant={"outlined"} 
                       onChange={(e) => {
                         setEditRowModel({...editRowModel, name: e.target.value});
                       }}
                       style={{width:"100%", backgroundColor: "#ffffff"}} />
          )
        } else {
          return (<span>{params.row.name}</span>)
        }
      }
    },
    {
      field: "url",
      headerName: "URL",
      flex: 1,
      cellClassName: (params) => {
        if(editRowId === params.row.id)
          return classes.rowSelected;
        else return "";
      },
      renderCell: (params) => {
        if (editRowId === params.row.id) {
          return (
            <TextField required={true} 
                             placeholder={"http://example.com"} 
                             type={"text"} 
                             value={editRowModel.url} 
                             variant={"outlined"}
                             onChange={(e) => {
                               setEditRowModel({...editRowModel, url: e.target.value});
                             }}
                             style={{width:"100%", backgroundColor: "#ffffff"}} />
          )
        } else {
          return (<span>{params.row.url}</span>)
        }
      }
    },
    {
      field: "actions",
      headerName: "ACTIONS",
      sortable: false,
      width: 150,
      cellClassName: (params) => {
        if(editRowId === params.row.id)
          return classes.rowSelected;
        else return "";
      },
      renderCell: (params) => {
        if (editRowId === params.row.id) {
          return (
            <>
              <Tooltip title="Save">
                <IconButton
                  className={classes.vendorCodeOk}
                  onClick={(e) => {
                    editRowModel.name = editRowModel?.name?.trim();
                    editRowModel.url = editRowModel?.url?.trim();
                    
                    // validate required field
                    if ((editRowModel?.name ?? "") === "") {
                      toast.error("Please fill AI stream's name!", {autoClose: false})
                      return;
                    }
                    if ((editRowModel?.url ?? "") === "") {
                      toast.error("Please fill AI stream's url!", {autoClose: false})
                      return;
                    }
                    // check if the name already exists in the list or not
                    let a = venueCameras.find(a => a.id !== editRowModel.id && a.name === editRowModel.name);
                    if (a)
                    {
                      toast.error("The AI stream's name already exist! Please choose other name!", {autoClose: false});
                      return;
                    }
                    if (editRowModel.id === NIL_UUID) {
                      // add new
                      venueCameraApi.addCamera(editRowModel)
                        .then(response => {
                          setEditRowId(null);
                          setVenueCameras(null); // refresh data
                          toast.success("AI stream added successfully");
                        })
                        .catch(error => {
                          toast.error("Cannot add AI stream", {autoClose: false});
                          console.log("Cannot add AI stream", error);
                        });
                    }
                    else {
                      // update value
                      venueCameraApi.updateVenueCamera(editRowModel)
                        .then(response => {
                          setEditRowId(null);
                          setVenueCameras(null); // refresh data
                          toast.success("AI stream updated successfully");
                        })
                        .catch(error => {
                          toast.error("Cannot update AI stream", {autoClose: false});
                          console.log("Cannot update AI stream", error);
                        });
                    }

                  }}
                >
                  <DoneIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Cancel">
                <IconButton
                  className={classes.vendorCodeCancel}
                  onClick={(e) => {
                    cleanUpCameraListWithoutNew();
                    setEditRowId(null);
                  }}
                >
                  <ClearIcon />
                </IconButton>
              </Tooltip>
            </>
          );
        } else {
          return (
            <>
              <Tooltip title="Edit">
                <IconButton
                  aria-label="view"
                  className={classes.icon}
                  onClick={(e) => {
                    cleanUpCameraListWithoutNew();
                    setEditRowId(params.row.id);
                    setEditRowModel(JSON.parse(JSON.stringify(params.row)));
                  }}
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Delete">
                  <IconButton
                    aria-label="delete"
                    className={classes.deleteIcon}
                    onClick={() => {
                      setOpen(true);
                      setEditRowId(null);
                      setDeleteId(params.row.id);
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
              </Tooltip>
            </>
          );
        }
      },
    },
  ];
  
  return (
    <div>
      {venue !== null ? (
        <LoadPleaseWait show={isLoading} >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              minWidth: 0,
              alignItems: "stretch",
              width: "900px"
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center"
              }}
            >
              <Typography variant="h5" className={classes.title}>AI streams</Typography>
              <Button
                buttonType={ButtonType.TopBarSecondary}
                type="button"
                startIcon={<CloudDownloadIcon />}
                onClick={handleExport}
                size="small"
              >
                Export
              </Button>

              <Button
                buttonType={ButtonType.TopBarSecondary}
                type="button"
                startIcon={<CloudUploadIcon />}
                onClick={(e) => {
                  setShowImportModal(true);
                }}
                size="small"
              >
                Import
              </Button>
              
              <Button
                className={classes.addButton}
                buttonType={ButtonType.TopBarPrimary}
                variant="contained"
                color="primary"
                size="small"
                startIcon={<AddIcon />}
                onClick={(e) => {
                  let newData = venueCameras.find(a=> a.id === NIL_UUID);
                  let newList = [...venueCameras];
                  if (!newData) {
                    newList = [...venueCameras, {id: NIL_UUID, venueId: venue?.id, name: "", url: ""}]
                    setVenueCameras(newList);
                  }
                  if (sortModel[0].sort === "asc") // always  have 1 item in index that's why only pick up index 0
                    setCurrentPage(0); // set 1st page if the order is in ascending
                  else
                    setCurrentPage(parseInt(newList.length / pageSize));// set page to last page if the order is in descending
                  setEditRowId(NIL_UUID);
                  setEditRowModel({id: NIL_UUID, venueId: venue?.id, name: "", url: ""})
                }}
              >
                Add
              </Button>
            </Box>

            <DataGrid
                page={currentPage}
                onPageChange={(pageNo) => {
                  setCurrentPage(pageNo);
                }}
              disableSelectionOnClick={true}
              hideFooterRowCount={true}
              hideFooterSelectedRowCount={true}
              autoHeight
              editMode="row"
              onPageSizeChange={(val) => {
                cleanUpCameraListWithoutNew();
                setPageSize(val);
              }}
              pageSize={pageSize}
              rowsPerPageOptions={[5,10,20]}
              sortModel={sortModel}
              onSortModelChange={(model) => setSortModel(model)}
              sortingOrder={['desc','asc']}
              columns={columns}
              rows={venueCameras ? venueCameras : []}
              checkboxSelection
              onSelectionModelChange={(newSelectionModel) => {
                setSelectionModel(newSelectionModel);
              }}
              selectionModel={selectionModel}  
              className={classes.grid}
            />

            <Button
              variant="contained"
              component="span"
              buttonType={ButtonType.Dark}
              size="small"
              className={classes.deleteSelectedButton}
              disabled={selectionModel.length === 0}
              onClick={() => {
                setShowConfirmDeleteSelection(true);
              }}
              >
                {`Delete (${selectionModel.length})`}
            </Button>

            <AlertDialog open={open} onNoButtonClicked={handleClose} contentText={`Do you want to delete this AI stream?`} onYesButtonClicked={(e) => {
              handleRemoveCamera(deleteId);
              setOpen(false);
            }} />

          </Box>
          <VenueCamerasImportPopup
            showModal={showImportModal}
            setShowModal={setShowImportModal}
            refreshParent={() => {
              setVenueCameras(null); // reload venue ai streams
            }}/>
          <AlertDialog 
            open={showConfirmDeleteSelection}
            contentText={`Do you want to delete ${selectionModel.length} ${selectionModel.length === 1 ? "AI streams" : "AI streams"}?`}
            onNoButtonClicked={() => setShowConfirmDeleteSelection(false)}
            onYesButtonClicked={() => {
              handleRemoveCameraRange(selectionModel);
              setShowConfirmDeleteSelection(false);
            }}
          />

        </LoadPleaseWait>
      ) : (
        <div>
          <Typography variant="h4">Please select venue first</Typography>
        </div>
      )}
    </div>
  );
}