import React from "react"
import { Box } from "@material-ui/core";
import CommonWidgetLayout from "../shared/CommonWidgetLayout";
import appViewModeConstants from "../../../_constants/app.view.mode.constants";

const TrafficInOutValueComparison = (props) => {
    const { data, settings, height, classes, textColor, viewMode } = props;

    const isViewMode = viewMode === appViewModeConstants.VIEW_MODE;

    const location = data?.location ?? settings?.RefLocation;
    const baseLineLocation = data?.baseLineLocation ?? settings?.RefBaseLineLocation;

    const locationValue = (isViewMode ? (data?.value ?? "N/A") : 0)?.toLocaleString("en-US");
    const baseLineLocationValue = (isViewMode ? (data?.baseLineValue ?? "N/A") : 0)?.toLocaleString("en-US");

    const locationCustomName = settings?.WidgetSettingCustomName;
    const baseLineLocationCustomName = settings?.WidgetSettingBaseLineCustomName;

    const locationTimePeriodName = settings?.WidgetSettingTimePeriod?.name;
    const baseLineLocationTimePeriodName = settings?.WidgetSettingBaseLineTimePeriod?.name;

    return (
        <Box display="flex" height={height}>
            <Box display="flex" className={`${classes.valueComparisonContainer}`} style={{ paddingRight: "5px" }}>
                <Box className={classes.containerBox}>
                    <CommonWidgetLayout
                        value={locationValue}
                        name={!locationCustomName ? location?.name : locationCustomName}
                        timePeriod={locationTimePeriodName}
                        contrastFontColor={textColor}
                    />
                </Box>
            </Box>

            <Box display="flex" className={classes.valueComparisonDividerContainer} style={{ paddingTop: "7%" }}>
                <Box display="flex" className={classes.valueComparisonDividerTraffic}>/</Box>
            </Box>

            <Box display="flex" className={`${classes.valueComparisonContainer}`} style={{ paddingLeft: "5px" }}>
                <Box className={classes.containerBox}>
                    <CommonWidgetLayout
                        value={baseLineLocationValue}
                        name={!baseLineLocationCustomName ? baseLineLocation?.name : baseLineLocationCustomName}
                        timePeriod={baseLineLocationTimePeriodName}
                        contrastFontColor={textColor}
                    />
                </Box>
            </Box>
        </Box>
    );
}

export default TrafficInOutValueComparison;
