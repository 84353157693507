import {React, useState, useEffect, useRef} from "react";
import {useSelector} from "react-redux";
import { getMappedDataChecker } from "../../_services/MappedDataChecker.service";
import {DataGrid} from "@material-ui/data-grid";
import {NIL as NIL_UUID} from "uuid";

import {Checkbox, FormControlLabel, Grid, TextField, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import useLocalStorage from "../../_helpers/useLocalStorage";
import useSortModel from "../../_helpers/useSortModel";

const useStyles = makeStyles((theme) => {
    return {
        mapped: { color: "green"},
        notMapped: { color: "red"},
        timeExceedThresholdWarning: {
            color: "red",
        },
        settingCellStyle: {
            padding: "5px 10px 5px 5px"
        }
    };
});

const MappedDataChecker = () => {
    const classes = useStyles();
    const selectedVenue = useSelector(
        (state) => state.venueReducer.selectedVenue
    );

    const [messages, setMessages] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [showAboveThresholdOnly, setShowAboveThresholdOnly] = useState(true);
    const [differentInMinute, setDifferentInMinute] = useState(2);
    const [timeoutInSecond, setTimeoutInSecond] = useState(20);
    const [differentInSeconds, setDifferentInSeconds] = useState(differentInMinute * 100 );
    var date = new Date();
    const [lastUpdated, setLastUpdated] = useState(date.toLocaleString());
    const [pageSize, setPageSize] = useLocalStorage("mappedDataChecker-pageSize", 20);
    const [sortModel, setSortModel] = useSortModel("mappedDataChecker-sortModel", [{field: 'vendorName', sort: 'asc'}]);
    const [refreshRequired, setRefreshRequired] = useState(true);

    const isMounted = useRef(false);
    useEffect(() => {
        isMounted.current = true;
        return () => { isMounted.current = false }
    }, []);

    useEffect(() => {
        if (refreshRequired && selectedVenue !== null) {
            setRefreshRequired(false);
            setIsLoading(true);
            getMappedDataChecker(selectedVenue?.id ?? NIL_UUID)
                .then((response) => {
                    setMessages(response);
                    setIsLoading(false);
                    setLastUpdated((new Date()).toLocaleString());
                    setTimeout(() => {
                        if (isMounted.current) {
                            setRefreshRequired(true);
                        }
                    }, timeoutInSecond * 1000);
                }).catch(error => {
                setIsLoading(false);
                setLastUpdated((new Date()).toLocaleString());
                console.error(error);
            });
        }
    }, [ selectedVenue, timeoutInSecond, refreshRequired]);

    const renderExceedThreshold = (params) => {
        if(params.row.diffInSeconds > differentInSeconds || params.row.diff === "N/A")
            return classes.timeExceedThresholdWarning;
        else return "";
    };

    const messagesAboveThreshold = messages.filter(a => a.diffInSeconds > differentInSeconds || a.diff === "N/A");
    var messagesFiltered = messages;
    if (showAboveThresholdOnly) messagesFiltered = messagesAboveThreshold;

    const columns = [
        {
            field: "id",
            hide: true,
            valueFormatter: (params) => params.row?.id,
        },
        {
            field: "vendorName",
            width: 350,
            headerName: "VENDOR NAME",
            cellClassName: renderExceedThreshold,
        },
        {
            field: "vendorCode",
            width: 350,
            headerName: "VENDOR CODE",
            cellClassName: renderExceedThreshold,
        },
        {
            field: "locationName",
            width: 300,
            headerName: "LOCATION NAME",
            cellClassName: renderExceedThreshold,
        },
        {
            field: "operationalIndicatorName",
            width: 300,
            headerName: "INDICATOR",
            cellClassName: renderExceedThreshold,
        },
        {
            field: "cameraOrSensorName",
            width: 300,
            headerName: "CAMERA OR SENSOR NAME (UTC)",
            cellClassName: renderExceedThreshold,
        },
        {
            field: "value",
            width: 300,
            headerName: "LAST VALUE",
            cellClassName: renderExceedThreshold,
        },
        {
            field: "cameraOrSensorDate",
            width: 300,
            headerName: "LATEST CAMERA OR SENSOR DATE (UTC)",
            cellClassName: renderExceedThreshold,
        },
        {
            field: "diffInSeconds",
            width: 210,
            headerName: "TIME DIFFERENCE",
            renderCell: (params) => {
                return params.row.diff;
            },
            cellClassName: renderExceedThreshold,
        },
    ];

    return (
        <div>
            <Typography variant={"h5"}>Mapped data checker</Typography><br/>
            <Grid container spacing={1}>
                <Grid container item xs={12} md={12} lg={6} xl={4} spacing={3} style={{paddingRight: "30px"}}>
                    <Grid container item spacing={1}>
                        <Grid item xs={6} spacing={2} className={classes.settingCellStyle}>
                            <b>Time difference threshold</b><br />
                            <FormControlLabel
                                control={<Checkbox name="showAboveThresholdOnly" checked={showAboveThresholdOnly} onChange={(e) => {
                                    setShowAboveThresholdOnly(e.target.checked)
                                }} />}
                                label="Show data above threshold only."
                            />
                        </Grid>
                        <Grid item xs={6} spacing={2} className={classes.settingCellStyle}>
                            <TextField
                                id="differentInMinute"
                                variant="outlined"
                                size="small"
                                label="in minute(s)"
                                type="number"
                                name="differentInMinute"
                                value={differentInMinute}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                fullWidth={true}
                                onChange={(e) => {
                                    setDifferentInMinute(e.target.value);
                                    setDifferentInSeconds(e.target.value * 60);
                                }}
                            />
                        </Grid>

                        <Grid item xs={6} spacing={2} className={classes.settingCellStyle}>
                            <b>Refresh time</b>
                        </Grid>
                        <Grid item xs={6} spacing={2} className={classes.settingCellStyle}>
                            <TextField
                                id="timeoutInSecond"
                                variant="outlined"
                                size="small"
                                label="in second(s)"
                                type="number"
                                name="timeoutInSecond"
                                value={timeoutInSecond}
                                InputProps={{ inputProps: { min: 10 }}}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                fullWidth={true}
                                onChange={(e) => {
                                    setTimeoutInSecond(e.target.value);
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} spacing={2} className={classes.settingCellStyle}>
                            <i>Any changes to settings will be applied after next refresh</i>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={12} lg={6} xl={8} spacing={2}>
                    <b>The page will be auto refresh every {timeoutInSecond} seconds and shows data for selected
                        indicators (<i>Occupancy</i>,
                        <i>Traffic In/Out</i>, <i>Queue Metrics</i>, and <i>Demographic</i>) for <i>Digital
                            Mortar</i> and <i>PMY Vision</i> vendors only.</b><br/>
                    <b>The highlighted row means that it is above threshold: {differentInMinute} minute(s) or N/A (no
                        data received).</b><br/><br/>
                    <b><i>
                        Total rows retrieved: {messages?.length}<br/>
                        Total rows showed in the grid: {messagesFiltered?.length}<br/>
                        Total rows above the threshold : {messagesAboveThreshold?.length}<br/>
                        Last Updated: {lastUpdated}
                    </i></b>
                </Grid>
            </Grid>
            <br/>
            <DataGrid
                disableSelectionOnClick={true}
                hideFooterRowCount={true}
                hideFooterSelectedRowCount={true}
                autoHeight
                rowHeight={40}
                pageSize={pageSize}
                onPageSizeChange={(val) => setPageSize(val)}
                rowsPerPageOptions={[10,20,50,75,100]}
                sortModel={sortModel}
                onSortModelChange={(model) => setSortModel(model)}
                sortingOrder={['desc','asc']}
                columns={columns}
                rows={messagesFiltered === undefined ? [] : messagesFiltered}
            />
        </div>
    )
}
export default MappedDataChecker;