import React, {useState, useEffect, useRef} from 'react';
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  IconButton,
  InputAdornment,
  TextField, 
  FormControlLabel, 
  Checkbox,
  Tooltip,
  Typography,
  Select
} from "@material-ui/core";
import DoneIcon from "@material-ui/icons/Done";
import ClearIcon from "@material-ui/icons/Clear";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import {DataGrid} from "@material-ui/data-grid";
import { toast } from "react-toastify";
import LoadPleaseWait from "../../../../notification/LoadingPleaseWait/LoadingMessage";
import {useApiGet} from "../../../../../_helpers/useApiGet";
import {
  getAccreditApiSettings, updateAccreditApiSettings, 
  getAccreditLocationExtensions, getAccreditRegistrationSubTypes, 
  addAccreditLocationExtensions, addAccreditRegistrationSubTypes, 
  updateAccreditLocationExtensions, updateAccreditRegistrationSubTypes, 
  deleteAccreditLocationExtensions, deleteAccreditRegistrationSubTypes,
  getAccreditRegistrationSubTypeGroup
} from "../../../../../_services/venue.accredit.service";
import {getVenueLocationsList} from "../../../../../_services/venue.locations.service";
import { validate as isValidUUID, v4 as uuidv4 } from 'uuid';
import {Button, ButtonType} from "../../../../_common/htmlTags";
import {AlertDialog} from "../../../../../_helpers";
import useLocalStorage from "../../../../../_helpers/useLocalStorage";

const useStyles = makeStyles((theme) => ({
  ok: {
    color: theme.palette.color.success.main,
    width: "30px",
    height: "100%",
    marginRight: "0px"
  },
  cancel: {
    color: theme.palette.color.danger.main,
    width: "30px",
    height: "100%"
  },
  textField: {
    marginTop: theme.spacing(3),
    width: '500px'
  },
  eventIdsTitle: {
    flexGrow: 1,
  },
  eventIdsTitleBar: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(2),
    width: "1000px"
  },
  addEventIdButton: {

  },
  editIcon: {
    color: theme.palette.color.primary.dark
  },
  deleteIcon: {
    color: theme.palette.color.primary.red
  },
  rowEdit: {
    backgroundColor: theme.palette.common.gridRowEditBackground,
  },
  // vendorCodeOk: {
  //   color: theme.palette.color.success.main,http://localhost:3000/settings-datasources
  //   height: "100%",
  //   marginRight: "0px"
  // },
  // vendorCodeCancel: {
  //   color: theme.palette.color.danger.main,
  //   height: "100%"
  // },
}));

const getAccreditApiSettingsCall = async (venueId) => {
  if (venueId) {
    const result = await getAccreditApiSettings(venueId);
    return result.data;
  } else {
    return null;
  }
};

const successMessage = "Accredit API settings have been saved.";
const failureMessage = "Failed to save Accredit API settings. ";

export const AccreditApiSettings = ({venue}) => {
  const classes = useStyles();

  // The "data" here is the Accredit API settings as they were loaded from the database.
  const [{data: initialSettings, isLoading : isLoading1}] = useApiGet(getAccreditApiSettingsCall, venue.id, null);

  // The settings is the same as the initialSettings until the user edits and saves
  // one for the fields. The settings variable will be set to the newly saved value.
  const [settings, setSettings] = useState("");

  // If cached[field] is null, then the field have not been edited since
  // the page loaded from the database. If the cached[field] is not null (the empty string is not null), 
  // then the field has been edited.
  const [cachedBaseUrl, setCachedBaseUrl] = useState(null);
  const [cachedUsername, setCachedUsername] = useState(null);
  const [cachedPassword, setCachedPassword] = useState(null);
  const [eventIdRows, setEventIdRows] = useState([]);
  const [pageSize, setPageSize] = useLocalStorage("pmy-accreditApiSettings-pageSize", 10);
  const [currentPage, setCurrentPage] = useState(0);
  const [eventIdToDelete, setEventIdToDelete] = useState(null);

  const [isSaving, setIsSaving] = useState(false);

  const [locations, setLocations] = useState([]);
  const [locationExtensionsRows, setLocationExtensionsRows]= useState([]);
  const [locationExtensionPageSize, setLocationExtensionPageSize] = useLocalStorage("pmy-accreditLocationExtensions-pageSize", 10);
  const [locationExtensionCurrentPage, setLocationExtensionCurrentPage] = useState(0);
  const [locationExtensionToDeleteId, setLocationExtensionToDeleteId] = useState(null);
  const [locationExtensionToDeleteName, setLocationExtensionToDeleteName] = useState(null);

  const [registrationSubTypeGroups, setRegistrationSubTypeGroups]= useState([]);
  const [registrationSubTypeRows, setRegistrationSubTypeRows]= useState([]);
  const [registrationSubTypePageSize, setRegistrationSubTypePageSize] = useLocalStorage("pmy-accreditRegistrationSubType-pageSize", 10);
  const [registrationSubTypeCurrentPage, setRegistrationSubTypeCurrentPage] = useState(0);
  const [registrationSubTypeToDeleteId, setRegistrationSubTypeToDeleteId] = useState(null);
  const [registrationSubTypeToDeleteName, setRegistrationSubTypeToDeleteName] = useState(null);


  const isMounted = useRef(false);
  useEffect(() => {
    isMounted.current = true;
    return () => { isMounted.current = false }
  }, []);

  useEffect(() => {
    if (initialSettings) {
        // refresh page
      if (locations.length == 0) {
        getVenueLocationsList(venue.id).then((response) => {
          setLocations(response.data);
        });
      }
      if (locationExtensionsRows.length == 0) {
        getAccreditLocationExtensions(venue.id).then((response) => {
          setLocationExtensionsRows(response.data);
        });
      }
      if (registrationSubTypeRows.length == 0) {
        getAccreditRegistrationSubTypes(venue.id).then((response) => {
          setRegistrationSubTypeRows(response.data);
        });
      }
      if (registrationSubTypeGroups.length == 0) {
        getAccreditRegistrationSubTypeGroup().then((response) => {
          setRegistrationSubTypeGroups(response.data);
        });
      }

      setSettings(initialSettings);
      setCachedBaseUrl(null);
      setCachedUsername(null);
      setCachedPassword(null);
      setEventIdRows(() => initialSettings.eventIds.map((eventId, index) => ({
            id: uuidv4(),
            eventId: eventId,
            index: index
          })));
    }
  }, [initialSettings]);
  locations.sort(function(a, b){
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;

  });

  var refreshLocationData = () => {
    getVenueLocationsList(venue.id).then((response) => {
      setLocations(response.data);
    });
    getAccreditLocationExtensions(venue.id).then((response) => {
      setLocationExtensionsRows(response.data);
    });
  }
  var refreshRegistrationSubTypeData = () => {
    getAccreditRegistrationSubTypes(venue.id).then((response) => {
      setRegistrationSubTypeRows(response.data);
    });
    getAccreditRegistrationSubTypeGroup().then((response) => {
      setRegistrationSubTypeGroups(response.data);
    });
  }

  const saveBaseUrl = async () => {
    if (isSaving) {
      return;
    }

    let settingsToSave = {
      ...settings,
      VenueId: venue.id,
      EventId: settings.eventId,
      BaseUrl: cachedBaseUrl ?? settings.baseUrl,
      Username: settings.username,
      Password: null
    };

    setIsSaving(true);
    updateAccreditApiSettings(settingsToSave)
    .then(() => {
      toast.success(successMessage);
      setSettings(prev => ({
        ...prev,
        baseUrl: cachedBaseUrl ?? settings.baseUrl
      }));
      setCachedBaseUrl(null);
      setIsSaving(false);
    })
    .catch((error) => {
      toast.error(failureMessage + error.message, {autoClose: false});
      setIsSaving(false);
    });
  };

  const saveUsername = async () => {
    if (isSaving) {
      return;
    }

    let settingsToSave = {
      ...settings,
      VenueId: venue.id,
      EventId: settings.eventId,
      BaseUrl: settings.baseUrl,
      Username: cachedUsername ?? settings.username,
      Password: null
    };

    setIsSaving(true);
    updateAccreditApiSettings(settingsToSave)
    .then(() => {
      toast.success(successMessage);
      setSettings(prev => ({
        ...prev,
        username: cachedUsername ?? settings.username
      }));
      setCachedUsername(null);
      setIsSaving(false);
    })
    .catch((error) => {
      toast.error(failureMessage + error.message, {autoClose: false});
      setIsSaving(false);
    });
  };

  const savePassword = async () => {
    if (isSaving) {
      return;
    }

    let settingsToSave = {
      ...settings,
      VenueId: venue.id,
      EventIds: settings.eventIds,
      BaseUrl: settings.baseUrl,
      Username: settings.username,
      Password: cachedPassword
    }

    setIsSaving(true);
    updateAccreditApiSettings(settingsToSave)
    .then(() => {
      toast.success(successMessage);
      setSettings(prev => ({
        ...prev,
        password: cachedPassword ?? settings.password
      }));
      setCachedPassword(null);
      setIsSaving(false);
    })
    .catch((error) => {
      toast.error(failureMessage + error.message, {autoClose: false});
      setIsSaving(false);
    });
  };

  const saveEventIds = async (eventIds) => {
    if (isSaving) {
      return;
    }

    let settingsToSave = {
      ...settings,
      VenueId: venue.id,
      EventIds: eventIds,
      BaseUrl: settings.baseUrl,
      Username: settings.username,
      Password: null
    };

    setIsSaving(true);
    updateAccreditApiSettings(settingsToSave)
    .then(() => {
      toast.success(successMessage);
      setSettings(prev => ({
        ...prev,
        eventIds: eventIds
      }));
      setIsSaving(false);
    })
    .catch((error) => {
      toast.error(failureMessage + error.message, {autoClose: false});
      setIsSaving(false);
    });
  };

  const eventIdColumns = [
    {
      field: "eventId",
      headerName: "EVENT ID",
      width: 850,
      sortable: false,
      cellClassName: (params) => { 
        if (params.row.isEditing)
          return classes.rowEdit; 
        else return "";
      },
      renderCell: (params) => {
        if (params.row.isEditing) {
          return (
            <TextField required={true}
              placeholder={"Event ID"} 
              type={"text"} 
              value={params.row.eventId} 
              variant={"outlined"} 
              onChange={(e) => {
                setEventIdRows(eventIdRows.map((row) => {
                  if (row.index === params.row.index) {
                    return {...row, eventId: e.target.value};
                  } else {
                    return row;
                  }
                }));
              }}
              style={{width:"100%", backgroundColor: "#ffffff"}} />
          )
        } else {
          return (<span>{params.row.eventId}</span>)
        }
      }
    },
    {
      field: "actions",
      headerName: "ACTIONS",
      sortable: false,
      width: 150,
      cellClassName: (params) => {
        if(params.row.isEditing)
          return classes.rowEdit;
        else return "";
      },
      renderCell: (params) => {
        if (params.row.isEditing) {
          return (
            <>
              <Tooltip title="Save">
                <IconButton
                  className={classes.ok}
                  onClick={(e) => {
                    // validate Event ID guid
                    if (params.row.eventId.trim() === "" ) {
                      toast.error("Event ID is required.", {autoClose: false});
                      return;
                    }

                    if (!isValidUUID(params.row.eventId.trim())) {
                      toast.error("Event ID is not a valid UUID.");
                      return;
                    }          

                    // Check if the same Event ID is alrady in the list.
                    const duplicateEventIdRow = eventIdRows.find( it => it.index !== params.row.index 
                      && it.eventId.toLowerCase() === params.row.eventId.toLowerCase());  
                    if (duplicateEventIdRow)
                    {
                      toast.error("This Event ID is already entered.", {autoClose: false});
                      return;
                    }

                    saveEventIds(eventIdRows.map( row => row.eventId));
                    setEventIdRows(prev => prev.map(row => ({
                      ...row,
                      isNew: false,
                      isEditing: false
                    })));
                  }}
                >
                  <DoneIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Cancel">
                <IconButton
                  className={classes.cancel}
                  onClick={(e) => {
                    setEventIdRows(prev => prev.filter(row => !row.isNew )
                      .map((row, index) => {
                        return {
                          ...row,
                          isEditing: false,
                          index: index
                        };
                      }));
                    }
                  }
                >
                  <ClearIcon />
                </IconButton>
              </Tooltip>
            </>
          );
        } else {
          return (
            <>
              <Tooltip title="Edit">
                <IconButton
                  aria-label="view"
                  className={classes.editIcon}
                  onClick={(e) => {
                    setEventIdRows(prev => {
                      return prev.filter(row => !row.isNew)
                        .map((row, index) => { 
                          return {
                            ...row,
                            index: index,
                            isEditing: false
                          }; 
                        }
                      )
                      .map(row => {
                        if (row.index == params.row.index )
                          return {
                            ...row,
                            isEditing: true
                          };
                        else return row;
                      });
                    });
                  }}
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Delete">
                  <IconButton
                    aria-label="delete"
                    className={classes.deleteIcon}
                    onClick={() => {
                      setEventIdToDelete(params.row.eventId);
                      setEventIdRows(prev => 
                        prev.map(row => ({
                          ...row,
                          isEditing: false
                        })));
                      }
                    }
                  >
                    <DeleteIcon />
                  </IconButton>
              </Tooltip>
            </>
          );
        }
      },
    },
  ];

  const locationExtensionsColumns = [
      {
      field: "locationId",
      headerName: "LOCATION",
      width: 450,
      sortable: true,
      cellClassName: (params) => { 
        if (params.row.isEditing)
          return classes.rowEdit; 
        else return "";
      },
      renderCell: (params) => {
        if (params.row.isEditing) {
          return (
            <Select fullWidth
             style={{height: "auto"}}
              value={params.row.locationId}
              onChange={(e) =>
                setLocationExtensionsRows(locationExtensionsRows.map((row) => {
                  if (row.index === params.row.index) {
                    return {...row, locationId: e.target.value};
                  } else {
                    return row;
                  }
                }))}
            >
              {
                locations.map(a => 
                  (<option value={a.id} >{a.name}</option>)
                )
              
              }
            </Select>
              
          )
        } else {
          return (<span>{locations.filter(a => a.id === params.row.locationId)[0].name }</span>)
        }
      }
    },
    {
      field: "getPlayerCount",
      headerName: "PLAYER COUNT",
      width: 200,
      sortable: true,
      cellClassName: (params) => { 
        if (params.row.isEditing)
          return classes.rowEdit; 
        else return "";
      },
      renderCell: (params) => {
        return (
          <FormControlLabel
          disabled={!params.row.isEditing}
          control={<Checkbox checked={params.row.getPlayerCount} onChange={
            (e) =>
              setLocationExtensionsRows(locationExtensionsRows.map((row) => {
                if (row.index === params.row.index) {
                  return {...row, getPlayerCount: e.target.checked};
                } else {
                  return row;
                }
              }))
          } name="getPlayerCount" />}
          label=""
        />  
        )
        
      }
    },
    {
      field: "getWorkforceCount",
      headerName: "WORKFORCE COUNT",
      width: 200,
      sortable: true,
      cellClassName: (params) => { 
        if (params.row.isEditing)
          return classes.rowEdit; 
        else return "";
      },
      renderCell: (params) => {
        return (
          <FormControlLabel
          disabled={!params.row.isEditing}
          control={<Checkbox checked={params.row.getWorkforceCount} onChange={
            (e) =>
              setLocationExtensionsRows(locationExtensionsRows.map((row) => {
                if (row.index === params.row.index) {
                  return {...row, getWorkforceCount: e.target.checked};
                } else {
                  return row;
                }
              }))
          } name="getWorkforceCount" />}
          label=""
        />  
        )
        
      }
    },
    {
      field: "actions",
      headerName: "ACTIONS",
      sortable: false,
      width: 150,
      cellClassName: (params) => {
        if(params.row.isEditing)
          return classes.rowEdit;
        else return "";
      },
      renderCell: (params) => {
        if (params.row.isEditing) {
          return (
            <>
              <Tooltip title="Save">
                <IconButton
                  className={classes.ok}
                  onClick={(e) => {
                    if (!isValidUUID(params.row.locationId.trim())) {
                      toast.error("Please select location.");
                      return;
                    }
                   if (params.row.id === "00000000-0000-0000-0000-000000000000") {
                    addAccreditLocationExtensions(params.row).then(() => {
                      toast.success("Location Extension saved successfully");
                      refreshLocationData();
                    })
                    .catch((error) => {
                      toast.error(error.message, {autoClose: false});
                    });
                   } 
                   else {
                    updateAccreditLocationExtensions(params.row).then(() => {
                      toast.success("Location Extension saved successfully");
                      refreshLocationData();
                    })
                    .catch((error) => {
                      toast.error(error.message, {autoClose: false});
                    });
                   }
                  }}
                >
                  <DoneIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Cancel">
                <IconButton
                  className={classes.cancel}
                  onClick={(e) => {
                    refreshLocationData();
                    }
                  }
                >
                  <ClearIcon />
                </IconButton>
              </Tooltip>
            </>
          );
        } else {
          return (
            <>
              <Tooltip title="Edit">
                <IconButton
                  aria-label="view"
                  className={classes.editIcon}
                  onClick={(e) => {
                    setLocationExtensionsRows(prev => {
                      return prev.filter(row => !row.isNew)
                        .map((row, index) => { 
                          return {
                            ...row,
                            index: index,
                            isEditing: false
                          }; 
                        }
                      )
                      .map(row => {
                        if (row.index == params.row.index )
                          return {
                            ...row,
                            isEditing: true
                          };
                        else return row;
                      });
                    });
                  }}
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Delete">
                  <IconButton
                    aria-label="delete"
                    className={classes.deleteIcon} 
                    onClick={() => {
                      setLocationExtensionToDeleteId(params.row.id);
                      setLocationExtensionToDeleteName(locations.filter(a => a.id === params.row.locationId)[0].name);
                      setLocationExtensionsRows(prev => 
                        prev.map(row => ({
                          ...row,
                          isEditing: false
                        })));
                      }
                    }
                  >
                    <DeleteIcon />
                  </IconButton>
              </Tooltip>
            </>
          );
        }
      },
    },
  ];
  
  const registrationSubTypeColumns = [
    {
      field: "registrationSubTypeName",
      headerName: "REGISTRATION SUB TYPE",
      width: 450,
      sortable: true,
      cellClassName: (params) => { 
        if (params.row.isEditing)
          return classes.rowEdit; 
        else return "";
      },
      renderCell: (params) => {
        if (params.row.isEditing) {
          return (
            <TextField required={true}
              placeholder={"Registration Sub Type"} 
              type={"text"} 
              value={params.row.registrationSubTypeName} 
              variant={"outlined"} 
              onChange={(e) => {
                setRegistrationSubTypeRows(registrationSubTypeRows.map((row) => {
                  if (row.index === params.row.index) {
                    return {...row, registrationSubTypeName: e.target.value};
                  } else {
                    return row;
                  }
                }));
              }}
              style={{width:"100%", backgroundColor: "#ffffff"}} />
          )
        } else {
          return (<span>{params.row.registrationSubTypeName}</span>)
        }
      }
    },
    {
      field: "registrationSubTypeGroup",
      headerName: "GROUP",
      width: 450,
      sortable: true,
      cellClassName: (params) => { 
        if (params.row.isEditing)
          return classes.rowEdit; 
        else return "";
      },
      renderCell: (params) => {
        if (params.row.isEditing) {
          return (
            <Select fullWidth
             style={{height: "auto"}}
              value={params.row.registrationSubTypeGroup}
              onChange={(e) =>
                setRegistrationSubTypeGroups(registrationSubTypeGroups.map((row) => {
                  if (row.index === params.row.index) {
                    return {...row, registrationSubTypeGroup: e.target.value};
                  } else {
                    return row;
                  }
                }))}
            >
              {
                registrationSubTypeGroups.map(a => 
                  (<option value={a.id} >{a.description}</option>)
                )
              
              }
            </Select>
              
          )
        } else {
          return (<span>{registrationSubTypeGroups.filter(a => parseInt(a.id) === parseInt(params.row.registrationSubTypeGroup))[0].description }</span>)
        }
      }
    },
    {
      field: "actions",
      headerName: "ACTIONS",
      sortable: false,
      width: 150,
      cellClassName: (params) => {
        if(params.row.isEditing)
          return classes.rowEdit;
        else return "";
      },
      renderCell: (params) => {
        if (params.row.isEditing) {
          return (
            <>
              <Tooltip title="Save">
                <IconButton
                  className={classes.ok}
                  onClick={(e) => {
                    if (params.row.registrationSubTypeName.trim() === "") {
                      toast.error("Please enter Registration Sub Type.");
                      return;
                    }
                   if (params.row.id === "00000000-0000-0000-0000-000000000000") {
                    addAccreditRegistrationSubTypes(params.row).then(() => {
                      toast.success("Registration Sub Type saved successfully");
                      refreshRegistrationSubTypeData();
                    })
                    .catch((error) => {
                      toast.error(error.message, {autoClose: false});
                    });
                   } 
                   else {
                    updateAccreditRegistrationSubTypes(params.row).then(() => {
                      toast.success("Registration Sub Type saved successfully");
                      refreshRegistrationSubTypeData();
                    })
                    .catch((error) => {
                      toast.error(error.message, {autoClose: false});
                    });
                   }
                  }}
                >
                  <DoneIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Cancel">
                <IconButton
                  className={classes.cancel}
                  onClick={(e) => {
                    refreshRegistrationSubTypeData();
                    }
                  }
                >
                  <ClearIcon />
                </IconButton>
              </Tooltip>
            </>
          );
        } else {
          return (
            <>
              <Tooltip title="Edit">
                <IconButton
                  aria-label="view"
                  className={classes.editIcon}
                  onClick={(e) => {
                    setRegistrationSubTypeRows(prev => {
                      return prev.filter(row => !row.isNew)
                        .map((row, index) => { 
                          return {
                            ...row,
                            index: index,
                            isEditing: false
                          }; 
                        }
                      )
                      .map(row => {
                        if (row.index == params.row.index )
                          return {
                            ...row,
                            isEditing: true
                          };
                        else return row;
                      });
                    });
                  }}
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Delete">
                  <IconButton
                    aria-label="delete"
                    className={classes.deleteIcon} 
                    onClick={() => {
                      setRegistrationSubTypeToDeleteId(params.row.id);
                      setRegistrationSubTypeToDeleteName(params.row.registrationSubTypeName);
                      setRegistrationSubTypeRows(prev => 
                        prev.map(row => ({
                          ...row,
                          isEditing: false
                        })));
                      }
                    }
                  >
                    <DeleteIcon />
                  </IconButton>
              </Tooltip>
            </>
          );
        }
      },
    },
  ];
  
  return (
    <div>
      {venue !== null ? (
        <LoadPleaseWait show={isLoading1} >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
              marginBottom: "16px"
            }}>
            <Typography variant="h5">Accredit API settings</Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                marginTop: "6px"
              }}>
              <TextField
                id="baseUrl"
                label = "API base URL"
                placeholder="API base URL"
                variant="outlined"
                name="baseUrl"
                value={cachedBaseUrl === null ? settings?.baseUrl ?? '' : cachedBaseUrl}
                onChange={(e) => {
                  const { value } = e.target;
                  setCachedBaseUrl(value);
                }}
                className={classes.textField}
                InputProps={cachedBaseUrl !== null ? {
                  endAdornment: (
                    <InputAdornment
                      position="end">
                      <IconButton 
                        edge="end" 
                        className={classes.ok}
                        onClickCapture={(e) => saveBaseUrl()} 
                        onMouseDown={e => e.stopPropagation()}
                      >
                        <DoneIcon />
                      </IconButton>
                      <IconButton 
                        edge="end" 
                        className={classes.cancel}
                        onClickCapture={(e) => setCachedBaseUrl(null)} 
                        onMouseDown={e => e.stopPropagation()}
                      >
                        <ClearIcon />
                      </IconButton>
                    </InputAdornment>
                  )}
                    :null
                  } 
              />

              <TextField
                id="username"
                label = "Username"
                placeholder="Username"
                variant="outlined"
                name="username"
                value={cachedUsername === null ? settings?.username ?? '' : cachedUsername}
                onChange={(e) => {
                  const { value } = e.target;
                  setCachedUsername(value);
                }}
                className={classes.textField}
                InputProps={cachedUsername !== null ? {
                  endAdornment: (
                    <InputAdornment
                      position="end">
                      <IconButton 
                        edge="end" 
                        className={classes.ok}
                        onClickCapture={(e) => saveUsername()} 
                        onMouseDown={e => e.stopPropagation()}
                      >
                        <DoneIcon />
                      </IconButton>
                      <IconButton 
                        edge="end" 
                        className={classes.cancel}
                        onClickCapture={(e) => setCachedUsername(null)} 
                        onMouseDown={e => e.stopPropagation()}
                      >
                        <ClearIcon />
                      </IconButton>
                    </InputAdornment>
                  )}
                    :null
                  } 
              />

              <TextField
                id="password"
                label = "Password"
                placeholder="Password"
                variant="outlined"
                name="password"
                value={cachedPassword === null ? (settings?.password ?? '') : cachedPassword}
                onChange={(e) => {
                  const { value } = e.target;
                  setCachedPassword(value);
                }}
                onFocus={(e) => {
                  if (settings?.password && settings.password === initialSettings?.password) {
                    setCachedPassword('');
                  }
                }}
                className={classes.textField}
                InputProps={cachedPassword !== null ? {
                  endAdornment: (
                    <InputAdornment
                      position="end">
                      <IconButton 
                        edge="end" 
                        className={classes.ok}
                        onClickCapture={(e) => savePassword()} 
                        onMouseDown={e => e.stopPropagation()}
                      >
                        <DoneIcon />
                      </IconButton>
                      <IconButton 
                        edge="end" 
                        className={classes.cancel}
                        onClickCapture={(e) => setCachedPassword(null)} 
                        onMouseDown={e => e.stopPropagation()}
                      >
                        <ClearIcon />
                      </IconButton>
                    </InputAdornment>
                  )}
                    :null
                  } 
              />

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center"
                }}
                className={classes.eventIdsTitleBar}
              >
                <Typography variant="h5" className={classes.eventIdsTitle}>Accredit Event IDs</Typography>
                <Button
                  className={classes.addEventIdButton}
                  buttonType={ButtonType.TopBarPrimary}
                  variant="contained"
                  color="primary"
                  size="small"
                  startIcon={<AddIcon />}
                  onClick={(e) => {
                    const eventIdRowsLength = eventIdRows.length;
                    var checkEditEnabled = eventIdRows.filter(row => row.isEditing);
                    if (checkEditEnabled.length > 0)
                      {
                        toast.error("Please save or cancel the existing editable row first before adding a new one");
                        return;
                      }
                    setEventIdRows([
                      ...eventIdRows, 
                      {
                        id: uuidv4(),
                        eventId: "", 
                        index: eventIdRowsLength,
                        isEditing: true,
                        isNew: true
                      }
                    ]);
                    setCurrentPage(parseInt(eventIdRowsLength / pageSize));
                  }}
                >
                  Add
                </Button>
              </Box>

              <DataGrid
                page={currentPage}
                onPageChange={(pageNo) => {
                  setCurrentPage(pageNo);
                }}
                disableSelectionOnClick={true}
                hideFooterRowCount={true}
                hideFooterSelectedRowCount={true}
                autoHeight
                editMode="row"
                onPageSizeChange={(val) => {
                  setEventIdRows(prev => prev.filter(row => !row.isNew)
                    .map(row => ({
                      ...row,
                      isEditing: false
                    }))
                  );
                  setPageSize(val);
                }}
                pageSize={pageSize}
                rowsPerPageOptions={[5,10,20]}
                columns={eventIdColumns}
                rows={eventIdRows}
                className={classes.grid}
                isRowSelectable={(row) => {
                  return eventIdRows.map(r => r.id).includes(row.id);
                }}
              />
              
            <Box
                sx={{
                  display: "flex",
                  alignItems: "center"
                }}
                className={classes.eventIdsTitleBar}
              >
                <Typography variant="h5" className={classes.eventIdsTitle}>Accredit Location Extension</Typography>
                <Button
                  className={classes.addEventIdButton}
                  buttonType={ButtonType.TopBarPrimary}
                  variant="contained"
                  color="primary"
                  size="small"
                  startIcon={<AddIcon />}
                  disabled={locations.length === 0}
                  onClick={(e) => {
                    const locationExtensionsRowsLength = locationExtensionsRows.length;
                    var checkEditEnabled = locationExtensionsRows.filter(row => row.isEditing);
                    if (checkEditEnabled.length > 0)
                    {
                      toast.error("Please save or cancel the existing editable row first before adding a new one");
                      return;
                    }
                    setLocationExtensionsRows([
                      ...locationExtensionsRows, 
                      {
                        id: "00000000-0000-0000-0000-000000000000",
                        locationId: locations[0]?.id ?? "00000000-0000-0000-0000-000000000000", // set 1st value from locations automatically
                        venueId: venue.id, 
                        getPlayerCount: true,
                        getWorkforceCount: true,
                        isEditing: true,
                        isNew: true,
                        index: locationExtensionsRowsLength,
                      }
                    ]);
                    setLocationExtensionCurrentPage(parseInt(locationExtensionsRowsLength / locationExtensionPageSize));
                  }}
                >
                  Add
                </Button>
            </Box>

            <DataGrid
              page={locationExtensionCurrentPage}
              onPageChange={(pageNo) => {
                setLocationExtensionCurrentPage(pageNo);
              }}
              disableSelectionOnClick={true}
              hideFooterRowCount={true}
              hideFooterSelectedRowCount={true}
              autoHeight
              editMode="row"
              onPageSizeChange={(val) => {
                setLocationExtensionsRows(prev => prev.filter(row => !row.isNew)
                  .map(row => ({
                    ...row,
                    isEditing: false
                  }))
                );
                setLocationExtensionPageSize(val);
              }}
              pageSize={locationExtensionPageSize}
              rowsPerPageOptions={[5,10,20]}
              columns={locationExtensionsColumns}
              rows={locationExtensionsRows}
              className={classes.grid}
              isRowSelectable={(row) => {
                return locationExtensionsRows.map(r => r.id).includes(row.id);
              }}
            />

            <Box
                sx={{
                  display: "flex",
                  alignItems: "center"
                }}
                className={classes.eventIdsTitleBar}
              >
                <Typography variant="h5" className={classes.eventIdsTitle}>Accredit Registration Sub Types</Typography>
                <Button
                  className={classes.addEventIdButton}
                  buttonType={ButtonType.TopBarPrimary}
                  variant="contained"
                  color="primary"
                  size="small"
                  startIcon={<AddIcon />}
                  disabled={locations.length === 0}
                  onClick={(e) => {
                    const registrationSubTypeRowsLength = registrationSubTypeRows.length;
                    var checkEditEnabled = registrationSubTypeRows.filter(row => row.isEditing);
                    if (checkEditEnabled.length > 0)
                    {
                      toast.error("Please save or cancel the existing editable row first before adding a new one");
                      return;
                    }
                    setRegistrationSubTypeRows([
                      ...registrationSubTypeRows, 
                      {
                        id: "00000000-0000-0000-0000-000000000000",
                        venueId: venue.id,
                        registrationSubTypeName: "",
                        registrationSubTypeGroup: 1, // RegistrationSubTypeGroup.Player                        
                        isEditing: true,
                        isNew: true,
                        index: registrationSubTypeRowsLength
                      }
                    ]);
                    setRegistrationSubTypeCurrentPage(parseInt(registrationSubTypeRowsLength / registrationSubTypePageSize));
                  }}
                >
                  Add
                </Button>
            </Box>

            <DataGrid
              page={registrationSubTypeCurrentPage}
              onPageChange={(pageNo) => {
                setRegistrationSubTypeCurrentPage(pageNo);
              }}
              disableSelectionOnClick={true}
              hideFooterRowCount={true}
              hideFooterSelectedRowCount={true}
              autoHeight
              editMode="row"
              onPageSizeChange={(val) => {
                setRegistrationSubTypeRows(prev => prev.filter(row => !row.isNew)
                  .map(row => ({
                    ...row,
                    isEditing: false
                  }))
                );
                setRegistrationSubTypePageSize(val);
              }}
              pageSize={registrationSubTypePageSize}
              rowsPerPageOptions={[5,10,20]}
              columns={registrationSubTypeColumns}
              rows={registrationSubTypeRows}
              className={classes.grid}
              isRowSelectable={(row) => {
                return registrationSubTypeRows.map(r => r.id).includes(row.id);
              }}
            />
            </Box>
            
            <AlertDialog 
              open={!!eventIdToDelete} 
              onNoButtonClicked={() => setEventIdToDelete(null)} 
              contentText={`Do you want to delete Event ID ${eventIdToDelete} ?`} 
              onYesButtonClicked={(e) => {
                saveEventIds(eventIdRows.filter(row => row.eventId !== eventIdToDelete)
                  .map(row => row.eventId)
                );
                setEventIdRows(prev => prev.filter(row => row.eventId != eventIdToDelete)
                  .map((row, index) => ({
                      ...row,
                      index: index
                    })));
                setEventIdToDelete(null);
              }} 
            />
            <AlertDialog 
                open={!!locationExtensionToDeleteId && !!locationExtensionToDeleteName} 
                onNoButtonClicked={() => {
                  setLocationExtensionToDeleteId(null);
                  setLocationExtensionToDeleteName(null);
                }}
                contentText={`Do you want to delete '${locationExtensionToDeleteName}' ?`} 
                onYesButtonClicked={(e) => {
                  deleteAccreditLocationExtensions(locationExtensionToDeleteId)
                  .then(() => {
                    toast.success("Deleted successfully");
                    setLocationExtensionToDeleteId(null);
                    setLocationExtensionToDeleteName(null);
                    setIsSaving(false);
                    refreshLocationData();
                    
                  })
                  .catch((error) => {
                    toast.error(error.message, {autoClose: false});
                    setIsSaving(false);
                  });
                }}
              />
            <AlertDialog 
                open={!!registrationSubTypeToDeleteId && !!registrationSubTypeToDeleteName} 
                onNoButtonClicked={() => {
                  setRegistrationSubTypeToDeleteId(null);
                  setRegistrationSubTypeToDeleteName(null);
                }}
                contentText={`Do you want to delete '${registrationSubTypeToDeleteName}' ?`} 
                onYesButtonClicked={(e) => {
                  deleteAccreditRegistrationSubTypes(registrationSubTypeToDeleteId)
                  .then(() => {
                    toast.success("Deleted successfully");
                    setRegistrationSubTypeToDeleteId(null);
                    setRegistrationSubTypeToDeleteName(null);
                    setIsSaving(false);
                    refreshRegistrationSubTypeData();
                    
                  })
                  .catch((error) => {
                    toast.error(error.message, {autoClose: false});
                    setIsSaving(false);
                  });
                }}
              />
            
          </Box>
        </LoadPleaseWait>
      ) : (
        <div>
          <Typography variant="h4">Please select venue first</Typography>
        </div>
      )}
   </div>
  );
};


export default AccreditApiSettings;