import React, { useEffect, useState, useContext } from "react";
import { useSelector, shallowEqual } from "react-redux";
import { Box, SvgIcon, Tooltip, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Transition } from 'react-transition-group';
import ClipLoader from "react-spinners/ClipLoader";
import { widgetTypeConstants, dynamicCaptionWidgets } from "../../_constants/widget.type.constants";
import { appViewModeConstants } from "../../_constants/app.view.mode.constants";
import { WidgetBox } from './WidgetBox';
import { widgetDataSelector, widgetRefValuesSettingsSelector } from "../../_reducers/widget.data.selectors";
import WidgetSettingsWrapper from "./settings/SettingsWrapper";
import devconsole from "../_common/devconsole";
import WidgetContext from "./WidgetContext";

const log = function () { devconsole.log('WidgetWrapper', ...arguments); }

const widgetTypesById = Object.values(widgetTypeConstants).reduce((map, obj) => { map[obj.id] = obj; return map; }, {});
const nameOfWidget = (widgetTypeId) => Object.entries(widgetTypeConstants).find(entry => entry[1].id === widgetTypeId)[0];
const isAdminMode = (mode) => mode === appViewModeConstants.ADMIN_MODE;

const complexDataWidgetTypes = [
    widgetTypeConstants.widgetOccupancy.id,
    widgetTypeConstants.widgetTrafficInOut.id,
    widgetTypeConstants.widgetMap.id,
    widgetTypeConstants.widgetFootfall.id,
    widgetTypeConstants.widgetNumber.id,
    widgetTypeConstants.widgetDemographic.id,
    widgetTypeConstants.widgetQueueModel.id,
    widgetTypeConstants.widgetPatronDistribution.id,
    widgetTypeConstants.widgetTicketScansAndSales.id,
    widgetTypeConstants.widgetTicketAttendanceChart.id
];

const fadeInDuration = 500;

const useStyles = makeStyles((theme) => ({
    fadingEmptyWidget: {
        ...theme.widget.fadingEmptyWidget,
        transitionDuration: `${fadeInDuration}ms`
        },
    renewIcon: {
        color: theme.palette.color.primary.main,
        marginRight: theme.spacing(1)
        },
    warningIcon: {
        color: theme.palette.color.warning.bright
    },
    popoverLikeTooltip: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: "0px 0px 4px 0px #00000040",
        width: "200px",
    },
    popoverLikeTooltipArrow: {
        "&::before": {
            backgroundColor: theme.palette.common.white,
            boxShadow: "0px 0px 4px 0px #00000040",
        }
    },
    warning: {
        fontWeight: 500,
        fontSize: "10px",
        lineHeight: "normal",
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    }
}));

const transitionStyles = {
  entering: { opacity: 1 },
  entered:  { opacity: 1 },
  exiting:  { opacity: 0 },
  exited:  { opacity: 0 },
};

export const WidgetWrapper = (props) => {
    const { widgetType, widgetId, viewMode, isPublicViewMode, width, height, bgcolor, border, clientType,
        onDelete, children, setIsDraggable = _ => {}, brandingSettings, onAudioPlay = () => {} } = props;

    const dataStore = nameOfWidget(widgetType);
    const widgetData = useSelector(state => widgetDataSelector(state, dataStore, widgetId), shallowEqual);
    const widgetSettingsRefValues = useSelector(state => widgetRefValuesSettingsSelector(state, dataStore, widgetId), shallowEqual);
    const selectedVenue = useSelector(state => state.venueReducer.selectedVenue);
    // const isDataLoading = useSelector(state => state.dashboards.loading.dashboardDataLoading);

    const [isSettingsOpen, setIsSettingsOpen] = useState(false);
    const [isFading, setIsFading] = useState(false);
    const [widgetCaption, setWidgetCaption] = useState(null);
    
    const classes = useStyles();
    
    const handleDelete = () => onDelete?.call(widgetId);
    const handleSettings = () => setIsSettingsOpen(state => !state);
    const handleCloseSettings = () => {
        setIsDraggable(true);
        setIsSettingsOpen(false);
    }

    log({widgetType, viewMode, isPublicViewMode, widgetData, widgetSettingsRefValues, classes});
    const widgetContextData = useContext(WidgetContext);

    useEffect(() => {
        if (!isAdminMode(viewMode)) {
            setIsFading(true);
        }
    }, [viewMode, setIsFading]);

    const childContent = React.Children.only(children);

    const widgetProps = {
        venueId: selectedVenue?.id,
        widgetId,
        width,
        height,
        viewMode,
        isPublicViewMode,
        clientType,
        data: widgetData,
        brandingSettings,
        onAudioPlay,
        onGetCaption: caption => setWidgetCaption(caption),
        settings: childContent.props.settings ?
            { ...(widgetSettingsRefValues ?? {}), ...childContent.props.settings } : widgetSettingsRefValues
    };

    const isCaptionTextVisible = props.isCaptionTextVisible ?? true;
    const isViewLoading = !isAdminMode(viewMode) && widgetData === undefined;
    const isLoadingIcon = (widgetData?.isRefreshing ?? false) && complexDataWidgetTypes.includes(widgetType) && !isAdminMode(viewMode);
    const isWarningIcon = (widgetData && complexDataWidgetTypes.includes(widgetType) && !isAdminMode(viewMode)
        && widgetData.warnings && widgetData.warnings.length > 0);

    // Temporal solution for displaying caption depending on specific conditions (below):
    const getCaption = () => {
        // Check if this widget is one of widgets that should have dynamic caption:
        if (dynamicCaptionWidgets.hasOwnProperty(widgetType)) {

            // Check if this widget should have caption that is visible in all modes, without any conditions
            if (dynamicCaptionWidgets[widgetType].hasOwnProperty("constantCaptionValue")) {
                return dynamicCaptionWidgets[widgetType].constantCaptionValue;
            }

            // Check if widget should display selected value from corresponding setting (specific setting for each dynamicCaptionWidget)
            if (widgetProps?.settings?.hasOwnProperty(dynamicCaptionWidgets[widgetType]?.setting)) {
                // Check if setting value (from server) should be converted in some other predefined format (example: setting name from server is "Traffic In only", but "Traffic In" should be displayed in caption)
                // else just return corresponding setting value instead of default widget caption
                return dynamicCaptionWidgets[widgetType].hasOwnProperty("predefinedSettingValuesMapping") 
                ? dynamicCaptionWidgets[widgetType]?.predefinedSettingValuesMapping[widgetProps?.settings[dynamicCaptionWidgets[widgetType].setting]?.id] 
                : widgetProps?.settings[dynamicCaptionWidgets[widgetType].setting]?.name
            } 
            
            // Default value
            else if (widgetProps.settings === null && dynamicCaptionWidgets[widgetType].hasOwnProperty("defaultValue")) {
                return dynamicCaptionWidgets[widgetType].defaultValue;
            }
        } 
        
        // Else return regular caption
        else {
            return isAdminMode(viewMode) && isCaptionTextVisible && (widgetCaption || widgetTypesById[widgetType].caption);
        }
    }

    const getHelp = () => {
        const customHelp = widgetProps?.settings?.WidgetSettingCustomHelp;
        if (customHelp) {
            return customHelp;
        }
        return widgetTypesById[widgetType].help;
    };

    return (
        <>
            <WidgetBox
                caption={getCaption()}
                isDynamicCaption={dynamicCaptionWidgets.hasOwnProperty(widgetType)}
                captionColor={widgetContextData?.captionColor}
                isAdminMode={isAdminMode(viewMode)}
                width={width}
                height={height}
                bgcolor={bgcolor}
                border={border}
                canDelete={isAdminMode(viewMode)}
                canConfigure={isAdminMode(viewMode)}
                onDelete={handleDelete}
                onConfigure={handleSettings}
                setIsDraggable={setIsDraggable}
                help={getHelp()}
            >
                {
                    isViewLoading ?
                        <Transition in={isFading} timeout={fadeInDuration}>
                            {state => (
                                <Box className={classes.fadingEmptyWidget} style={{...transitionStyles[state]}} />
                            )}
                        </Transition>
                    :
                        <>
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    position: "absolute",
                                    top: 5,
                                    right: 5
                                }}
                            >
                                <ClipLoader loading={isLoadingIcon}
                                            size="20px" color="#C13984" className={classes.renewIcon} />
                                {
                                    isWarningIcon &&
                                    <Tooltip
                                        title={widgetData.warnings.map((warning, index) => {
                                            return (
                                                <Typography className={classes.warning} key={index}>{warning}</Typography>
                                            );
                                        })}
                                        arrow
                                        placement="top-start"
                                        classes={{
                                            tooltip: classes.popoverLikeTooltip,
                                            arrow: classes.popoverLikeTooltipArrow
                                        }} 
                                    >
                                        <SvgIcon className={classes.warningIcon} style={{marginLeft: 0, marginTop: 4, transform: "scale(1.3)"}}>
                                            <path d="M8.00033 1.33203L0.666992 13.9987H15.3337M8.00033 3.9987L13.0203 12.6654H2.98033M7.33366 6.66536V9.33203H8.66699V6.66536M7.33366 10.6654V11.9987H8.66699V10.6654" />
                                        </SvgIcon>
                                    </Tooltip>
                                }
                            </Box>
                            {React.cloneElement(childContent, widgetProps)}
                        </>
                }
            </WidgetBox>

            {
                (!isPublicViewMode) &&

                <WidgetSettingsWrapper
                    isSettingsOpen={isSettingsOpen}
                    handleCloseSettings={handleCloseSettings}
                    widgetId={widgetId}
                    widgetTypeId={widgetTypesById[widgetType].id}
                    widgetTypeName={dataStore}
                />
            }
        </>
    );
}
