
import { widgetTypeConstants } from "../../../../_constants/widget.type.constants";

/// List of available widget types for dashboard grid layout
export const WidgetTypes = [
  widgetTypeConstants.widgetNumber,
  widgetTypeConstants.widgetOccupancy,
  widgetTypeConstants.widgetTrafficInOut,
  widgetTypeConstants.widgetFootfall,
  widgetTypeConstants.widgetMap,
  widgetTypeConstants.widgetLabel,
  widgetTypeConstants.widgetCamera,
  widgetTypeConstants.widgetEmbedding,
  widgetTypeConstants.widgetDemographic,
  widgetTypeConstants.widgetQueueModel,
  widgetTypeConstants.widgetPatronDistribution,
  widgetTypeConstants.widgetTicketScansAndSales,
  widgetTypeConstants.widgetTicketAttendanceChart,
  //widgetTypeConstants.widgetSample
]