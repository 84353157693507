import React, {useEffect, useState} from "react";
//import * as userGroupApi from "../../../_services/venue.userGroup.service";
import {
  Box,
  FormControl,
  IconButton, 
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";
import {Button, ButtonType} from "../_common/htmlTags";
import {makeStyles} from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import { NIL as NIL_UUID, validate} from "uuid";
import {toast} from "react-toastify";
import { useApiGet } from "../../_helpers/useApiGet";
import {getFactorTypeVendorMappings, addHistoryFactor} from "../../_services/factors.service"
import {convertDateStrToIanaTz, isNumeric} from "../../_helpers/functions.fieldValue";

const useStyles = makeStyles((theme) => {
  return {
    popup: {
      width: "462px",
      backgroundColor: "#FFFFFF",
      position: "absolute",
      top: "50%",
      left: "50%",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      padding: theme.spacing(3),
    },
    title: {
      fontSize: "24px",
      fontWeight: "500",
      flexGrow: 1
    },
    closeIcon: {
      marginTop:"-20px",
      marginRight: "-20px"
    },
    field: {
      width: "400px",
      marginTop: theme.spacing(3)
    },
    applyFromField: {
      width: "200px",
      marginTop: theme.spacing(3),
    },
    buttons: {
      marginTop: theme.spacing(4)
    }
  };
});

const getFactorTypeVendorMappingsCall = async (venueId) => {
  if (venueId) {
    const result = await getFactorTypeVendorMappings();
    return result;
  } else {
    return [];
  }
};

export const AddFactorPopup = ({ venue, locations, factorTypeId, locationId, onSave, onCancel}) =>
{
  const classes = useStyles();

  const [selectedFactorTypeId, setSelectedFactorTypeId] = useState(factorTypeId);
  const [selectedLocationId, setSelectedLocationId] = useState(locationId);
  const [selectedVendorId, setSelectedVendorId] = useState(null);
  const [factorValue, setFactorValue] = useState("1");
  const [applyFrom, setApplyFrom] = useState(null);

    // The "data" here is the array of factor type-vendor mappings as they are loaded from the database.
  const [{data: factorTypeVendorMappings, isLoading : isFactorTypeLoading}] = useApiGet(getFactorTypeVendorMappingsCall, venue.id, []);
  const [factorTypes, setFactorTypes] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [vendorsFilteredByFactorType, setVendorsFilteredByFactorType] = useState([]);
  
  useEffect(()=> {
    if (factorTypeVendorMappings) {
      const newFactorTypes = [];
      factorTypeVendorMappings.forEach((m) => {
        if (!newFactorTypes.find((ft) => ft.id === m.factorType.id)) {
          newFactorTypes.push({
            id: m.factorType.id,
            name: m.factorType.name,
            indicatorId: m.factorType.operationalIndicatorId,
            orderNum: m.factorType.orderNum
          });
        }
      });
      newFactorTypes.sort((a, b) => a.orderNum <= b.orderNum);
      setFactorTypes(newFactorTypes);

      const newVendors = [];
      factorTypeVendorMappings.forEach((m) => {
        if (!newVendors.find((nv) => nv.id === m.vendor.id)) {
          newVendors.push({
            id: m.vendor.id, 
            name: m.vendor.name
          });
        }
      });
      newVendors.sort((a,b) => a.name.localeCompare(b.name));
      setVendors(newVendors);
    }
  },[factorTypeVendorMappings]);

  useEffect(() => {
    const newFilteredVendors = [];
    if (selectedFactorTypeId) {
      vendors.forEach((v) => {
        const foundMapping = factorTypeVendorMappings.find((m) => m.factorType.id == selectedFactorTypeId && m.vendor.id == v.id);
        if (foundMapping) {
          newFilteredVendors.push(v);
        }
      })
    }
    setVendorsFilteredByFactorType(newFilteredVendors);
  }, [factorTypeVendorMappings, vendors, selectedFactorTypeId]);
  
  const [isSaving, setIsSaving] = useState(false);

  const handleFactorTypeSelected = (e) => {
    const newFactorTypeId = e.target.value;
    setSelectedFactorTypeId(newFactorTypeId);
    // dispatch(filterHistoryByFactorType(newFactorTypeId))
  };

  const handleLocationSelected = (e) => {
    const newLocationId = e.target.value;
    setSelectedLocationId(newLocationId);
    // dispatch(filterHistoryByLocation(newLocationId));
  };

  const handleVendorSelected = (e) => {
    const newVendorId = e.target.value;
    setSelectedVendorId(newVendorId);
  }

  const handleFactorValueChange = (e) => {
    setFactorValue( e.target.value);
  };

  const handleSave = (e) => {
    e.preventDefault();

    if (isNumeric(factorValue)) {

      setIsSaving(true);

      addHistoryFactor(venue.id, selectedFactorTypeId, selectedLocationId, selectedVendorId, Number(factorValue), convertDateStrToIanaTz(applyFrom, venue.ianaTimeZoneId))
      .then(result => {
        setIsSaving(false);
        onSave(result.data);
        toast.success("Factor added successfully.");
      })
      .catch((error) => {
        setIsSaving(false);
        toast.error("Failed to add factor. " + error);
      });
    } else {
      toast.error("Factor value must be a number.");
    }
  };

  return (
    <Modal open={true}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          maxHeight: "920px",
          alignItems: "stretch"
        }}
        className={classes.popup}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start"
          }}
        >
          <Typography className={classes.title}>
            Add factor
          </Typography>
          <Tooltip 
            title="Close" 
          >
            <IconButton
              aria-label="view"
              className={classes.closeIcon}
              onClick={(e) => {
                onCancel();
              }}
            >
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </Box>

        <FormControl
          variant="outlined"
          className={classes.field}
        >
          <InputLabel id="factorType-label">Metric</InputLabel>
          <Select
            labelId="factorType-label"
            id="factorType-select"
            name="factorTypeId"
            value={selectedFactorTypeId ?? ''}
            onChange={handleFactorTypeSelected}
            label="Metric"
            disabled={isFactorTypeLoading}
          >
            {factorTypes.map((ft) => (
                <MenuItem key={ft.id} value={ft.id}>
                  {ft.name}
                </MenuItem>
              ))
            }
          </Select>
        </FormControl>

        <FormControl
          variant="outlined"
          className={classes.field}
        >
          <InputLabel id="location-label">Location</InputLabel>
          <Select
            labelId="location-label"
            id="location-select"
            name="locationId"
            value={selectedLocationId ?? ''}
            onChange={handleLocationSelected}
            label="Location"
            disabled={isFactorTypeLoading}
          >
            {locations.map((l) => (
                <MenuItem key={l.id} value={l.id}>
                  <Box
                    ml={l.level*2}>
                      {l.name}
                  </Box>
                </MenuItem>
              ))
            }
          </Select>
        </FormControl>

        <FormControl
          variant="outlined"
          className={classes.field}
        >
          <InputLabel id="vendor-label">Data source</InputLabel>
          <Select
            labelId="vendor-label"
            id="vendor-select"
            name="vendorId"
            value={selectedVendorId ?? ''}
            onChange={handleVendorSelected}
            label="Data source"
            disabled={isFactorTypeLoading || !selectedFactorTypeId}
          >
            {vendorsFilteredByFactorType.map((v) => (
                <MenuItem key={v.id} value={v.id}>
                  {v.name}
                </MenuItem>
              ))
            }
          </Select>
        </FormControl>

        <TextField
          id="factorValue"
          label="Factor value"
          variant="outlined"
          required
          name="factorValue"
          value={factorValue}
          onChange={handleFactorValueChange}
          className={classes.field}
        />

        <TextField
          id="applyFrom"
          label="Apply from"
          type="datetime-local"
          name="applyFrom"
          value={applyFrom ?? ''}
          disabled={isFactorTypeLoading}
          InputLabelProps={{
            shrink: true,
          }}
          fullWidth
          className={classes.applyFromField}
          onChange={(e) => {
            const { value } = e.target;
            setApplyFrom(value);
          }}
        />

        <div className={classes.buttons}>
          <Button
            variant="contained"
            type="submit"
            disabled={isSaving || isFactorTypeLoading || !selectedLocationId || !selectedFactorTypeId || !selectedVendorId || !applyFrom}
            buttonType={ButtonType.Primary}
            onClick={handleSave}
            style={{
              padding: "10px",
              marginRight: "10px",
            }}
          >
            {isSaving ? "Saving..." : "Save"}
          </Button>&nbsp;
          <Button
            className="btn primary"
            variant="outlined"
            type="button"
            buttonType={ButtonType.Secondary}
            onClick={() => {
              onCancel();
            }}
            style={{
              padding: "9px"
            }}
          >Cancel</Button>
        </div>
      </Box>
    </Modal>
  );  
};