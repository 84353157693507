import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {useSelector, useDispatch} from "react-redux";
import {
  Box,
  Card,
  CardContent,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
  Typography
} from "@material-ui/core";
import {DataGrid} from "@material-ui/data-grid";
import {DataGridLoadingOverlay} from "../_common/DataGridLoadingOverlay";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import {useApiGet} from "../../_helpers/useApiGet";
import {Button, ButtonType} from "../_common/htmlTags";
import { getTicketSales } from "../../_services/ticketSales.service";
import TicketSalePopup from "./TicketSalePopup";
import useLocalStorage from "../../_helpers/useLocalStorage";
import useSortModel from "../../_helpers/useSortModel";
import {deleteTicketSale} from "../../_services/ticketSales.service";
import {toast} from "react-toastify";
import {AlertDialog} from "../../_helpers";
import { NIL as NIL_UUID } from "uuid";
import { SignalCellularNullRounded } from "@material-ui/icons";
import {convertDateToISOStringInIanaTz} from "../../_helpers/functions.fieldValue";

const useStyles = makeStyles((theme) => {
  return {
    card: {
      alignSelf: "start",
      overflow: 'auto',
      marginTop: theme.spacing(3),
      width: "auto"
    },
    deleteIcon: { 
      color: theme.palette.color.primary.red
    },
    editIcon: {
      color: theme.palette.color.primary.dark,
    },
    pageHeader: {
      width: "815px"
    },
    dataGrid: {
      width: "795px"
    },
  };
});

const getTicketSalesCall = async (venueId) => {
  const result = await getTicketSales(venueId);
  return result.data;
};

export const TicketSales = () => {
  const classes = useStyles();
  const selectedVenue = useSelector((state) => state.venueReducer.selectedVenue);

  const [{data: initialTicketSales, isLoading, refresh: refreshTicketSales}] = useApiGet(getTicketSalesCall, selectedVenue.id, []);
  const [ticketSales, setTicketSales] = useState([]);
  const [ticketSaleToEdit, setTicketSaleToEdit] = useState(null);
  const [ticketSaleToDelete, setTicketSaleToDelete] = useState(null);
  const [pageSize, setPageSize] = useLocalStorage("pmy-ticketSales-pageSize", 10);
  const [sortModel, setSortModel] = useSortModel("pmy-tickeSales-sortModel", [{field: 'venueLocalDate', sort: 'desc'}]);

  useEffect(() => {
    //if (initialTicketSales.length > 0  ) {
      setTicketSales( initialTicketSales );
    //}
  }, [initialTicketSales]);

  const disableColumnOrnaments = {
    disableColumnMenu: true,
    disableColumnFilter: true,
    disableSelectionOnClick: true,
    disableColumnSelector: true,
    disableDensitySelector: true,
    sortable: true
  };

  const columns = [
    {
      field: "id",
      hide: true,
    },
    {
      field: "venueLocalDate",
      headerName: "DATE",
      width: 220,
      ...disableColumnOrnaments,
      align:'left',
      headerAlign: 'left',
      renderCell: (params) => {
        return (
          <>
            {(new Date(params.row.venueLocalDate)).toLocaleDateString()}
          </>
        );
      },
    },
    {
      field: "ticketSales",
      headerName: "TICKETS SOLD",
      width: 200,
      ...disableColumnOrnaments,
      type:"number",
      align:'center',
      headerAlign: 'center',
    },
    {
      field: "expectedAttendance",
      headerName: "EXPECTED ATTENDANCE",
      width: 250,
      ...disableColumnOrnaments,
      type:"number",
      align:'center',
      headerAlign: 'center',
    },
    {
      field: "action",
      headerName: "ACTIONS",
      headerAlign: "center",
      width: 125,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <>
            <Tooltip title="Edit">
              <IconButton
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={(e) => {
                  setTicketSaleToEdit(params.row);
                }}
              >
                <EditIcon fontSize="small" className={classes.editIcon}/>
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete">
              <IconButton
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={(e) => {
                  setTicketSaleToDelete(params.row);
                }}
              >
                <DeleteIcon fontSize="small" className={classes.deleteIcon} />
              </IconButton>
            </Tooltip>
          </>
        )
      }
    }
  ];

  return (
    <>
      {selectedVenue !== null ?
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "stretch",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
            }}
            className={classes.pageHeader}
          >
            <Typography style={{flexGrow: 1}} variant="h4">Ticket sales</Typography>
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              onClick={(e) => {
                const today = new Date();
                setTicketSaleToEdit({
                  id: NIL_UUID,
                  venueId: selectedVenue.id,
                  venueLocalDate: convertDateToISOStringInIanaTz(today, selectedVenue.ianaTimeZoneId),
                  ticketSales: 0,
                  expectedAttendance: 0,
                });
              }}
            >
              Add
            </Button>
          </Box>

          <Card className={classes.card}>
            <CardContent>
              <DataGrid
                autoHeight={true}
                disableSelectionOnClick
                pageSize={pageSize}
                onPageSizeChange={(value) => setPageSize(value)}
                rowsPerPageOptions={[5,10,20]}
                sortModel={sortModel}
                onSortModelChange={(model) => setSortModel(model)}
                sortingOrder={['desc','asc']}
                hideFooterSelectedRowCount={true}
                columns={columns}
                rows={ticketSales}
                components={{
                  LoadingOverlay: DataGridLoadingOverlay,
                }}
                loading={isLoading}
                className={classes.dataGrid}
              />
            </CardContent>
          </Card>
        </Box>
      : 
        <div>
          <Typography variant="h4">Please select venue first</Typography>
        </div>
      }
      {ticketSaleToEdit != null ?
        <TicketSalePopup
          ticketSale={ticketSaleToEdit}
          venue={selectedVenue}
          onSave={() => {
            setTicketSaleToEdit(null);
            refreshTicketSales();
          }}
          onCancel={() => {
            setTicketSaleToEdit(null);
          }}
        />
        : null
      }
      {ticketSaleToDelete != null ?
        <AlertDialog open={ticketSaleToDelete} contentText={`Do you want to delete the ticket sale for ${new Date(ticketSaleToDelete.venueLocalDate).toLocaleDateString()}?`}
                onNoButtonClicked={() => {
                  setTicketSaleToDelete(null);
                }}
                onYesButtonClicked={(e) => {
                  deleteTicketSale(selectedVenue.id, ticketSaleToDelete.id).then((result) => {  
                    toast.success("Ticket sale has been deleted.");
                    setTicketSaleToDelete(null);
                    refreshTicketSales();
                  })
                  .catch(error => toast.error("Deleting ticket sale failed. " + error, { autoClose: false }));
                }} />
      : null }
    </>
  );
};

export default TicketSales;