import WidgetTicketAttendanceChartContent from "./WidgetTicketAttendanceChartContent";
import { WidgetWrapper } from "../WidgetWrapper";
import { widgetTypeConstants } from "../../../_constants";
import { WidgetContextProvider } from "../WidgetContext";

export const WidgetTicketAttendanceChart = (props) => (
    <WidgetContextProvider>
        <WidgetWrapper
            {...{ ...props, widgetType: widgetTypeConstants.widgetTicketAttendanceChart.id }}
        >
            <WidgetTicketAttendanceChartContent />
        </WidgetWrapper>
    </WidgetContextProvider>
);

export default WidgetTicketAttendanceChart;