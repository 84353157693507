import { AuthService } from "./auth.service";
import axios from "axios";
import { ApiRootConstants } from "../_constants/index";
import TicketSales from "../components/ticketSales/TicketSales";


var Constants = {
  occupancyIndicatorApiRoot: ApiRootConstants.OccupancyIndicatorApiRoot,
};

const authService = new AuthService();

export async function getTicketSales(venueId) {
  const user = await authService.getUser();
  if (user && user.access_token) {
    const headers = {
      Accept: "application/json",
      Authorization: "Bearer " + user.access_token,
    };
    
    return await axios
      .get(Constants.occupancyIndicatorApiRoot + `api/TicketSalesAndAttendance/${venueId}`, {
        headers: headers,
      })
      .catch((error) => {
        console.error(error);
        if (error.response.status === 401) {
          throw new Error("User is not logged in");
        }
        throw error;
      });
  }
};

export async function addTicketSale(ticketSale) {
  const user = await authService.getUser();
  if (user && user.access_token) {
    const options = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + user.access_token,
      },
    };

    const bodyFormData = {
      Id: ticketSale.id,
      VenueId: ticketSale.venueId,
      VenueLocalDate: ticketSale.venueLocalDate,
      TicketSales: ticketSale.ticketSales,
      ExpectedAttendance: ticketSale.expectedAttendance,
    };

    return await axios
      .post(
        Constants.occupancyIndicatorApiRoot + "api/TicketSalesAndAttendance",
        bodyFormData,
        options
      )
      .catch((error) => {
        console.error(error);
        if (error.response.status === 401) {
          throw new Error("User is not logged in");
        } else
          throw new Error(error.response.data.message);
      });
  }
};

export async function updateTicketSale(ticketSale) {
  const user = await authService.getUser();
  if (user && user.access_token) {
    const options = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + user.access_token,
      },
    };

    const bodyFormData = {
      Id: ticketSale.id,
      VenueId: ticketSale.venueId,
      VenueLocalDate: ticketSale.venueLocalDate,
      TicketSales: ticketSale.ticketSales,
      ExpectedAttendance: ticketSale.expectedAttendance,
    };

    return await axios
      .put(
        Constants.occupancyIndicatorApiRoot + "api/TicketSalesAndAttendance",
        bodyFormData,
        options
      )
      .catch((error) => {
        console.error(error);
        if (error.response.status === 401) {
          throw new Error("User is not logged in");
        } else
          throw new Error(error.response.data.message);
      });
  }
};

export async function deleteTicketSale(venueId, ticketSaleId) {
  const user = await authService.getUser();
  if (user && user.access_token) {
    const options = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + user.access_token,
      },
    };

    return await axios
      .delete(
        `${Constants.occupancyIndicatorApiRoot}api/TicketSalesAndAttendance/${venueId}/${ticketSaleId}`,
        options
      )
      .catch((error) => {
        console.error(error);
        if (error.response.status === 401) {
          throw new Error("User is not logged in");
        } else
          throw new Error(error.response.data.message)
      });
  }
}