import { metricConstants } from "./metric.constants";
import { numberValueTypes } from "./widget.numberValueType.constants";
import { widgetSettingsConstants } from "./widget.settings.constants";

export const widgetTypeConstants = {
    widgetSample: {
        id: 99, caption: 'Sample', 
        description: 'A sample widget to display dummy data. This is used for developer to test the widget. Please do not include this in the production',
        iconSrc: "../../../icons/moreVert.svg",
        minW: 3, // minimum width
        maxW: Infinity, // maximum width
        minH: 3, // minimum height
        maxH: Infinity, // maximum height
        w: 3, // default width
        h: 3, // default height
    },
    widgetOccupancy: {
        id: 10, caption: 'Current Occupancy', 
        description: 'A widget to display occupancy value for selected location.',
        help: 'Displays the current number of people counted in the area.',
        iconSrc: "../../../icons/occupancy.svg",
        minW: 3, // minimum width
        maxW: 18, // maximum width
        minH: 3, // minimum height
        maxH: 9, // maximum height
        w: 9, // default width
        h: 3, // default height
    },
    widgetTrafficInOut: {
        id: 11, caption: 'Traffic In/Out', 
        description: 'A widget to display traffic IN / OUT information for selected location.',
        help: 'Shows the count of people who have entered and exited the location, in the given timeframe.',
        iconSrc: "../../../icons/inOutWidget.svg",
        minW: 3, // minimum width
        maxW: 9, // maximum width
        minH: 3, // minimum height
        maxH: 6, // maximum height
        w: 9, // default width
        h: 3, // default height
    },
    widgetNumber: {
        id: 12, caption: 'Number', 
        description: 'A widget to display pre-defined number metric value for selected location.',
        help: 'A widget to display pre-defined number metric value for selected location.',
        iconSrc: "../../../icons/number.svg",
        minW: 3, // minimum width
        maxW: Infinity, // maximum width
        minH: 3, // minimum height
        maxH: Infinity, // maximum height
        w: 3, // default width
        h: 3, // default height
    }, 
    widgetMap: {
        id: 13, caption: 'Map', 
        description: 'A widget to display selected venue map and its polygons.',
        help: '',
        iconSrc: "../../../icons/map.svg",
        minW: 6, // minimum width
        maxW: Infinity, // maximum width
        minH: 6, // minimum height
        maxH: Infinity, // maximum height
        w: 6, // default width
        h: 6, // default height 
    },
    widgetFootfall: {
        id: 14, caption: 'Footfall Traffic', 
        description: 'A widget to display footfall traffic.',
        help: '',
        iconSrc: "../../../icons/footfall.svg",
        minW: 3, // minimum width
        maxW: 9, // maximum width
        minH: 3, // minimum height
        maxH: 3, // maximum height
        w: 9, // default width
        h: 3, // default height 
    },

    // This Label widget configuration was made only for testing of label value setting.
    // TODO: Configure it properly when real component will be in work.
    widgetLabel: {
        id: 15, caption: 'Label', 
        description: 'A widget to display specific label.',
        help: '',
        iconSrc: "../../../icons/label.svg",
        minW: 3, // minimum width
        maxW: Infinity, // maximum width
        minH: 1, // minimum height
        maxH: Infinity, // maximum height
        w: 9, // default width
        h: 1, // default height
    },
    widgetCamera: {
        id: 16,
        caption: 'AI Streams',
        description: 'AI Stream live video from camera with overlaid inferencing.',
        help: '',
        iconSrc: "../../../icons/camera.svg",
        minW: 6, // minimum width
        maxW: Infinity, // maximum width
        minH: 4, // minimum height
        maxH: Infinity, // maximum height
        w: 6, // default width
        h: 4, // default height 
    },
    widgetEmbedding: {
        id: 17,
        caption: 'Embedded widget',
        description: 'A widget which is embedded from side service.',
        help: '',
        iconSrc: "../../../icons/widgetEmbedding.svg",
        minW: Infinity, // minimum width
        maxW: Infinity, // maximum width
        minH: Infinity, // minimum height
        maxH: Infinity, // maximum height
        w: 6, // default width
        h: 4, // default height
    },
    widgetDemographic: {
        id: 18, caption: 'Demographic', 
        description: 'A widget to display number metric value for selected location from Inferencing.',
        help: '',
        iconSrc: "../../../icons/demographic.svg",
        minW: 3, // minimum width
        maxW: Infinity, // maximum width
        minH: 3, // minimum height
        maxH: Infinity, // maximum height
        w: 3, // default width
        h: 3, // default height
    },
    widgetQueueModel: {
         id: 19,
         caption: 'Queue Metrics',
         description: 'How long is the queue? What is the wait time? How long does it take to service one person?',
         help: '',
         iconSrc: "../../../icons/queue.svg",
         minW: 6, // minimum width
         maxW: Infinity, // maximum width
         minH: 6, // minimum height
         maxH: Infinity, // maximum height
         w: 6, // default width
         h: 6, // default height
    },

    widgetPatronDistribution: {
        id: 20,
        caption: 'Patron Distribution',
        description: 'Metrics for two locations compared either as percentage or directly as values displayed side by side.',
        help: 'Shows the proportion of patrons in one area, relative to another',
        iconSrc: "../../../icons/patronDistribution.svg",
        minW: 6, // minimum width
        maxW: 18, // maximum width
        minH: 3, // minimum height
        maxH: 9, // maximum height
        w: 9, // default width
        h: 3, // default height
   },

   widgetTicketScansAndSales: {
        id: 21,
        caption: 'Ticket Scans & Sales',
        description: 'Scanned tickets compared to sold tickets, presented in simple numerical form.',
        help: 'Scanned tickets compared to sold tickets, presented in simple numerical form.',
        iconSrc: "../../../icons/ticketSales.svg",
        minW: 3, // minimum width
        maxW: Infinity, // maximum width
        minH: 3, // minimum height
        maxH: Infinity, // maximum height
        w: 9, // default width
        h: 3, // default height
   },

   widgetTicketAttendanceChart: {
        id: 22,
        caption: 'Ticket Metrics (Chart)',
        description: 'Scanned tickets, sold tickets and expected attendance, presented on a visual chart.',
        help: 'Scanned tickets, sold tickets and expected attendance, presented on a visual chart.',
        iconSrc: "../../../icons/ticketMetrics.svg",
        minW: 9, // minimum width
        maxW: Infinity, // maximum width
        minH: 3, // minimum height
        maxH: Infinity, // maximum height
        w: 9, // default width
        h: 3, // default height
   }
};

export const widgetOccupancyValueTypeConstants = {
    Location: 'trafficLight',
    RawNumber: 'rawNumber',
    Percentage: 'percentage',
    CurrentOccupancy: 'currentOccupancy'
};

// Temporal solution to provide dynamic caption functionality:
// List below contains declaration of which rules/logic should be applied for each widget.
// Check "WidgetWrapper" component to see using these rules and description of logic.
export const dynamicCaptionWidgets = {
    [widgetTypeConstants.widgetTrafficInOut.id]: {
        setting: widgetSettingsConstants.refTrafficInOutDisplayMode.className,
        defaultValue: "Traffic In/Out",
        predefinedSettingValuesMapping: {
            "TrafficIn_only": "Traffic In",
            "TrafficOut_only": "Traffic Out",
            "TrafficInOut_both": "Traffic In/Out",
        }
    },
    
    [widgetTypeConstants.widgetOccupancy.id]: {
        constantCaptionValue: "Current Occupancy"
    },
    
    [widgetTypeConstants.widgetNumber.id]: {
        setting: widgetSettingsConstants.refNumberWidgetValueType.className,
        defaultValue: "",
        predefinedSettingValuesMapping: {
            [numberValueTypes.numberOfPatrons.id]: numberValueTypes.numberOfPatrons.name,
            [numberValueTypes.numberOfReturnPatrons.id]: numberValueTypes.numberOfReturnPatrons.name,
            [numberValueTypes.numberOfNewPatrons.id]: numberValueTypes.numberOfNewPatrons.name,
            [numberValueTypes.dwellTime.id]: numberValueTypes.dwellTime.name,
            [numberValueTypes.seeTicketsCount.id]: numberValueTypes.seeTicketsCount.name,
            [numberValueTypes.posTerminalSum.id]: numberValueTypes.posTerminalSum.name,
            [numberValueTypes.soldTicketsCount.id]: numberValueTypes.soldTicketsCount.name,
        },
    },

    [widgetTypeConstants.widgetPatronDistribution.id]: {
        setting: widgetSettingsConstants.refPatronDistributionMetric.className,
        defaultValue: "Patron distribution",
        predefinedSettingValuesMapping: {
            [metricConstants.occupancy.id]: "Patron distribution (Occupancy)",
            [metricConstants.trafficIn.id]: "Patron distribution (Traffic In)",
            [metricConstants.trafficOut.id]: "Patron distribution (Traffic Out)",
        },
    },

    [widgetTypeConstants.widgetTicketScansAndSales.id]: {
        constantCaptionValue: widgetTypeConstants.widgetTicketScansAndSales.caption
    },

    [widgetTypeConstants.widgetTicketAttendanceChart.id]: {
        constantCaptionValue: "Ticket Metrics"
    },
};

export default widgetTypeConstants;
