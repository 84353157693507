import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Typography,
} from "@material-ui/core";
import { toast } from "react-toastify";
import { Button, ButtonType } from "../../../_common/htmlTags/Button";
import ColorPicker from "../../../_common/ColorPicker";
import NoLogo from "./brandingSettings/NoLogo";
import {
  getVenueBrandingSettings,
  updateVenueBrandingSettings,
  getVenueBrandingMainLogo,
  getVenueBrandingAlternativeLogo,
  getVenueBrandingFooterLogo,
} from "../../../../_actions/venue.actions";
import { Delete } from "@material-ui/icons";
import {
  getVenueBrandingMainLogo as getMainLogoBlob,
  getVenueBrandingAlternativeLogo as getAlternativeLogoBlob,
  getVenueBrandingFooterLogo as getFooterLogoBlob,
} from "../../../../_services/venue.service";
import LoadPleaseWait from "../../../notification/LoadingPleaseWait/LoadingMessage";

const useStyles = makeStyles((theme) => ({
  label: {
    color: theme.palette.color.secondary.main,
    fontWeight: 500
  },

  uploadLabel: {
    fontSize: "10px",
    color: theme.palette.color.secondary.main,
  },
  deleteIcon: {
    color: "red",
    cursor: "pointer",
    marginLeft: "10px"
  },
  image: {
    height: "80px",
    objectFit: "scale-down"
  }
}));

const defaultColorsSettings = [
  {
    title: "Header background:",
    alias: "headerBackgroundColor",
    color: "#000031",
  },

  {
    title: "Header font:",
    alias: "headerFontColor",
    color: "#ffffff",
  },

  {
    title: "Footer background:",
    alias: "footerBackgroundColor",
    color: "#000031",
  },

  {
    title: "Footer font:",
    alias: "footerFontColor",
    color: "#ffffff",
  },
]

export const BrandingSettings = ({ venue }) => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const mainLogoBlobUrl = useSelector((state) => state.venueReducer.venueBrandingSettings.mainLogoBlobUrl);
  const alternativeLogoBlobUrl = useSelector((state) => state.venueReducer.venueBrandingSettings.alternativeLogoBlobUrl);
  const footerLogoBlobUrl = useSelector((state) => state.venueReducer.venueBrandingSettings.footerLogoBlobUrl);

  const settings = useSelector((state) => state.venueReducer.venueBrandingSettings.settings);


  const MAIN_LOGO_ALIAS = "main-logo";
  const ALTERNATIVE_LOGO_ALIAS = "alternative-logo";
  const FOOTER_LOGO_ALIAS = "footer-logo";

  const [mainLogo, setMainLogo] = useState({
    alias: MAIN_LOGO_ALIAS,
    title: "Main logo:",

    file: null,
    blobUrl: mainLogoBlobUrl,
  });

  const [alternativeLogo, setAlternativeLogo] = useState({
    alias: ALTERNATIVE_LOGO_ALIAS,
    title: "Alternative logo:",

    file: null,
    blobUrl: alternativeLogoBlobUrl,
  });

  const [footerLogo, setFooterLogo] = useState({
    alias: FOOTER_LOGO_ALIAS,
    title: "Footer logo:",

    file: null,
    blobUrl: footerLogoBlobUrl,
  });

  const [colorsSettings, setColorsSettings] = useState(defaultColorsSettings);

  const [isSaving, setIsSaving] = useState(false);

  const [isMainLogoLoading, setIsMainLogoLoading] = useState(true);
  const [isAlternativeLogoLoading, setIsAlternativeLogoLoading] = useState(true);
  const [isFooterLogoLoading, setIsFooterLogoLoading] = useState(true);

  const [isSettingLoading, setIsSettingLoading] = useState(true);


  // --------------------- HANDLE EFFECTS ---------------------
  useEffect(() => {
    getMainLogoBlob(venue.id)
      .then(response => {
        setMainLogo({
          ...mainLogo,
          file: new File([response.data], "mainLogo"),
          blobUrl: response.data.size > 0 ? URL.createObjectURL(response.data) : null,
        });

        setIsMainLogoLoading(false);
      });

    getAlternativeLogoBlob(venue.id)
      .then(response => {
        setAlternativeLogo({
          ...alternativeLogo,
          file: new File([response.data], "alternativeLogo"),
          blobUrl: response.data.size > 0 ? URL.createObjectURL(response.data) : null,
        });

        setIsAlternativeLogoLoading(false);
      });

    getFooterLogoBlob(venue.id)
      .then(response => {
        setFooterLogo({
          ...footerLogo,
          file: new File([response.data], "footerLogo"),
          blobUrl: response.data.size > 0 ? URL.createObjectURL(response.data) : null,
        });

        setIsFooterLogoLoading(false);
      });

    return () => {
      URL.revokeObjectURL(mainLogoBlobUrl);
      URL.revokeObjectURL(alternativeLogoBlobUrl);
      URL.revokeObjectURL(footerLogoBlobUrl);

      URL.revokeObjectURL(mainLogo.blobUrl);
      URL.revokeObjectURL(alternativeLogo.blobUrl);
      URL.revokeObjectURL(footerLogo.blobUrl);
    }
  }, [])

  useEffect(() => {
    if (Object.keys(settings).length === 0) {
      setColorsSettings(defaultColorsSettings);
      dispatch(getVenueBrandingSettings(venue.id))
        .then(r => { setIsSettingLoading(false) });
    } else {
      setColorsSettings(colorsSettings.map(item => {
        return {
          ...item,
          color: settings[item.alias]
        }
      }))
    }
  }, [dispatch, venue, mainLogoBlobUrl, alternativeLogoBlobUrl, footerLogoBlobUrl]);

  useEffect(() => {
    dispatch(getVenueBrandingSettings(venue.id))
      .then(r => { setIsSettingLoading(false); });

    dispatch(getVenueBrandingMainLogo(venue.id));
    dispatch(getVenueBrandingAlternativeLogo(venue.id));
    dispatch(getVenueBrandingFooterLogo(venue.id));
  }, [venue])


  // --------------------- SPECIFIC HANDLING METHODS ---------------------
  const handleUploadImage = (e) => {
    const file = e.target.files[0];

    // Clear the value, because otherwise the onChange event on <input type="file">
    // will not fire if the same file is selected for the second time.
    e.target.value = null;

    // Only images are allowed to be used as logo.
    var pattern = /image-*/;
    if (!file.type.match(pattern)) {
      toast.error(`Failed to upload ${file.type} file "${file.name}". Only image files can be uploaded.`, { autoClose: false });
      return;
    }

    switch (e.target.name) {
      case "image-" + "main-logo":
        setMainLogo({
          ...mainLogo,
          file: file,
          blobUrl: URL.createObjectURL(new Blob([file])),
        });
        break;

      case "image-" + "alternative-logo":
        setAlternativeLogo({
          ...alternativeLogo,
          file: file,
          blobUrl: URL.createObjectURL(new Blob([file])),
        });
        break;

      case "image-" + "footer-logo":
        setFooterLogo({
          ...footerLogo,
          file: file,
          blobUrl: URL.createObjectURL(new Blob([file])),
        });
        break;
    }
  }

  const handleSave = (event) => {
    event.preventDefault();
    setIsSaving(true);

    const settingsToSave = JSON.stringify(
      colorsSettings.reduce((acc, cur) =>
        ({ ...acc, [cur.alias]: cur.color }), {}
      )
    );

    dispatch(updateVenueBrandingSettings({
      venueId: venue.id,
      mainLogo: mainLogo.file ?? null,
      alternativeLogo: alternativeLogo.file ?? null,
      footerLogo: footerLogo.file ?? null,
      settings: settingsToSave,
    }))
      .then(() => {
        toast.success("Venue branding settings updated successfully.");
        // update logo 
        dispatch(getVenueBrandingMainLogo(venue.id));
        dispatch(getVenueBrandingAlternativeLogo(venue.id));
        dispatch(getVenueBrandingFooterLogo(venue.id));
        setIsSaving(false);
      })
      .catch((error) => {
        toast.error("Updating venue branding settings failed. " + error, { autoClose: false });
        setIsSaving(false);
      });;
  }


  // Method returns boolean value of specific logo loading
  const getIsLogoLoading = (logoAlias) => {
    let isLogoLoading;

    switch (logoAlias) {
      case MAIN_LOGO_ALIAS:
        isLogoLoading = isMainLogoLoading;
        break;
      case ALTERNATIVE_LOGO_ALIAS:
        isLogoLoading = isAlternativeLogoLoading;
        break;
      case FOOTER_LOGO_ALIAS:
        isLogoLoading = isFooterLogoLoading;
        break;
    }

    return isLogoLoading;
  }

  return (
    <form autoComplete="off" onSubmit={handleSave}>
      <Grid container spacing={8}>
        <Grid container item xs={7} md={7} direction="column" justifyContent="flex-start" spacing={3}>
          <Grid item >
            <Typography variant={"h5"} >
              Logo images & QR code
            </Typography>
          </Grid>

          {[mainLogo, alternativeLogo, footerLogo].map((item, index) => {
            return (
              <Grid item container spacing={3} alignItems="center" justifyContent="flex-start" key={item.alias}>
                <Grid item md={3}>
                  <Typography variant={"body1"} className={classes.label}>
                    {item.title}
                  </Typography>
                </Grid>

                <Grid item md={3}>
                  <LoadPleaseWait show={getIsLogoLoading(item.alias)} >
                    {item.blobUrl ? <img src={item.blobUrl} width="100%" className={classes.image} /> : <NoLogo />}
                  </LoadPleaseWait>
                </Grid>

                <Grid item container md={6} direction="column">
                  <Grid item>
                    <input
                      id={"upload-file-button" + item.alias}
                      type="file"
                      name={"image-" + item.alias}
                      onChange={handleUploadImage}
                      accept=".png,.jpg,.bmp"
                      hidden
                    />
                    <label htmlFor={"upload-file-button" + item.alias}>
                      <Button
                        variant={"contained"}
                        component="span"
                        buttonType={ButtonType.Dark}>
                        Upload
                      </Button>
                    </label>
                    {item.blobUrl ? (
                      <Delete className={classes.deleteIcon} fontSize="small" onClick={(e) => {
                        if (index === 0) // mainLogo
                          setMainLogo({ ...mainLogo, file: null, blobUrl: '' });
                        else if (index === 1) // alternativeLogo
                          setAlternativeLogo({ ...alternativeLogo, file: null, blobUrl: '' });
                        else if (index === 2) // footerLogo
                          setFooterLogo({ ...footerLogo, file: null, blobUrl: '' });
                      }} />
                    ) : ("")}
                  </Grid>

                  <Grid item>
                    <Typography
                      className={classes.uploadLabel}
                    >
                      Image should be 300x80px<br />
                      SVG or transparent PNG recommended. GIF and JPG also supported
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            )
          })}
        </Grid>

        <Grid container item xs={5} md={5} spacing={4} direction="column">
          <Grid item>
            <Typography variant={"h5"} >
              Colours
            </Typography>
          </Grid>

          <Grid item container spacing={1}>
            <LoadPleaseWait show={isSettingLoading} >
              <Grid item container xs={11} md={11} spacing={2}>
                {colorsSettings.map(item => {
                  return (
                    <Grid item container alignItems="center" spacing={2} key={item.alias}>

                      <Grid item xs={8} md={8}>
                        <Typography variant={"body1"} className={classes.label}>
                          {item.title}
                        </Typography>
                      </Grid>

                      <Grid item xs={4} md={4}>
                        <ColorPicker
                          color={item.color}
                          onChange={(newColor, newFlash) => {
                            var newColorSettings = colorsSettings?.map(a => { return { ...a } })
                            newColorSettings.find(colorSetting => {
                              return colorSetting.alias === item.alias
                            }).color = newColor;

                            setColorsSettings(newColorSettings);
                          }}
                        />
                      </Grid>
                    </Grid>
                  )
                })}
              </Grid>
            </LoadPleaseWait>
          </Grid>
        </Grid>
      </Grid>

      <Button
        style={{ marginTop: "50px" }}
        type="submit"
        variant={"contained"}
        disabled={isSaving}
      >
        {isSaving ? "Saving..." : "Save"}
      </Button>
    </form>
  );
}

export default BrandingSettings;