import { AuthService } from "./auth.service";
import { ApiRootConstants } from "../_constants/index";
import axios from "axios";

const authService = new AuthService();

export async function getMappedDataChecker(venueId) {
    const user = await authService.getUser();
    if (user && user.access_token) {
        const headers = {
            Accept: "application/json",
            Authorization: "Bearer " + user.access_token,
        };

        return axios
            .get(ApiRootConstants.OccupancyIndicatorApiRoot + `api/MappedDataChecker/${venueId}`, {
                headers,
            })
            .then((response) => response.data)
            .catch((error) => {
                console.error(error);
                if (error.response) {
                    if (error.response.status === 401) {
                        throw new Error("User is not logged in");
                    } else if (error.response.status === 422) {
                        throw new Error(error.response.data.message);
                    }
                }
                throw error;
            });
    }
};