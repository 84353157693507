import { alpha } from '@material-ui/core';

const widgetTheme = (theme) => ({
  common: {
    headerBar: {      
      backgroundColor: alpha(theme.palette.common.gridColumnHeaderBackground, 0.5),
      cursor: props => props.isAdminMode? "move" : "default",
      height: "30px",
      borderRadius: "0",
    },
    headerCaption: {
      paddingLeft: "10px",
      fontSize: 14,      
    },

    deleteIcon: {
      fontSize: 20,
      color: "white",
      marginLeft: "5px",
    },
    vendorName: {
      paddingLeft: "10px",
    },
    container: {
      backgroundColor: (props) => props.backgroundColor,
      color: theme.palette.common.white,
      width: (props) => (props.width ? props.width : "100%"),
      height: "110px",
      position: "relative",
    },
    greenColumn: {
      backgroundColor: "green",
      width: "12%",
    },
    locationName: {
      display: "-webkit-box",
      WebkitBoxOrient: "vertical",
      WebkitLineClamp: 2,
      whiteSpace: "normal",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },

    commonLayoutValue: {
      color: "rgba(3, 24, 32, 1)", 
      fontSize: "clamp(24px, 2.3em, 32px)",
      // Below is example of another font size scaling approach - using font size from "Scale factor" method directly in clamp() function.
      // Example is left here in case of potential usage, could be removed as unnecessary.
      //fontSize: (props) => props.fontSize ? `clamp(15px, ${props.fontSize * 1.2}px, 44px)` : "clamp(24px, 2.3em, 32px)", 
      fontWeight: "500", 
      marginTop: "15px",
      alignItems: "center",
      justifyContent: "center",
      [theme.breakpoints.down('sm')]: {
        fontSize: "clamp(24px, 1.5em, 32px)",
      },
    },

    commonLayoutName: {
      color: "rgba(3, 24, 32, 1)", 
      fontSize: "clamp(13px, 1.3em, 17px)",
      fontWeight: 400,
      alignItems: "center",
      justifyContent: "center",
      [theme.breakpoints.down('sm')]: {
        fontSize: "clamp(13px, 0.9em, 17px)",
      },
      display: "-webkit-box",
      WebkitBoxOrient: "vertical",
      WebkitLineClamp: 1,
      whiteSpace: "normal",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },

    timePeriodChip: {
      borderRadius: "12px",
      backgroundColor: "rgba(222, 225, 228, 0.3)",
      color: "rgba(98, 126, 132, 1)",
    },
  },

  singleValueWidgets: {
    locationName: {
      fontSize: "14pt",
    },
  },

  complexWidgets: {
    contentBox: {
      height: "100%",
      width: "100%",
      paddingLeft: "10px",
      paddingRight: "10px",
    },
    content: {
      display: "-webkit-box",
      whiteSpace: "normal",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    locationName: {
      marginTop: "10px",
      textAlign: "center",
    },
  },

  occupancy: {
    container: {
      height: "100%",
      width: "100%",      
      alignItems: "center",
      justifyContent: "center",   
      fontSize: (props) => props.fontSize,
    },

    numberContent: {
      fontSize: "clamp(22px, 2em, 26px)",     
      // color: theme.palette.common.white,     
    },

    numberContentRight: {    
      // color: theme.palette.common.white,
      fontSize: "clamp(22px, 2em, 26px)",     
    },

    content: {
      // color: theme.palette.common.white,
      textAlign: "center",
      fontSize: "1em",         
    
    },

    highestValueCurrentOccupancy: {
      fontSize: "clamp(10px, 1.1em, 24px)",
      fontWeight: "500",
      [theme.breakpoints.down('sm')]: {
        fontSize: "clamp(10px, 1.3em, 24px)",
      }
    },
    highestValueWithMaxCapacity: {
      fontSize: "clamp(10px, 1.1em, 24px)", 
      fontWeight: "500",
      [theme.breakpoints.down('sm')]: {
        fontSize: "clamp(10px, 1.3em, 24px)",
      }
    },

    highestValueTitleCurrentOccupancy: {
      textAlign: "center",
      fontSize: "clamp(10px, 1em, 14px)",
    },
    highestValueTitleWithMaxCapacity: {
      textAlign: "center",
      fontSize: "clamp(10px, 1em, 14px)",
    },

    location: {
      // color: theme.palette.common.white,
      textAlign: "center",
      fontSize: "clamp(14px, 4.5vw, 26px)",         
      textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: 'nowrap',
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1)
    },

    dataSourceContent: {
      // color: theme.palette.common.white,
      fontSize: "clamp(10px, 0.8em, 12px)",     
      [theme.breakpoints.down('sm')]: {
        fontSize: "0.8em",
      },
    },

    hoursContent: {
      // color: theme.palette.common.white,
      fontSize: "clamp(10px, 0.8em, 12px)",     
      [theme.breakpoints.down('sm')]: {
        fontSize: "0.8em",
      },
    },

    containerLeftWithBorder: {
      width: "66%",            
      justifyContent: "center",
      textAlign: "center",           
      overflow: "auto",         
      borderRight: "1px solid rgba(220, 227, 232, 0.3)",          
     // borderColor: theme.palette.widget.borderColor,
    },

    containerBox: {
      width:"100%",
      margin: "auto",     
      justifyContent: "center",
      textAlign: "center", 
      overflow: "auto",     
    },

    containerRight: {
      width: "34%",
      justifyContent: "center",
      textAlign: "center",  
      overflow: "auto",
      height: "100%",
    },

   
  },

  emptyContainer: {
    container: {                       
      alignItems: "center",
      justifyContent: "center",
    },
    
    emptyContent: {    
      textAlign: "center",
      justifyContent: "center",
      alignItems: "center",
      fontSize: "16px",     
      color: theme.palette.color.secondary.main,
      [theme.breakpoints.down('sm')]: {
        fontSize: "12px",
      },
    },

    containerLeftWithBorder: {
      width: "66%",            
      justifyContent: "center",
      textAlign: "center",           
      overflow: "auto",   
      borderRight: "1px solid #DCE3E8",      
    },

    containerRight: {
      width: "34%",
      justifyContent: "center",
      textAlign: "center",  
      overflow: "auto",
    },
  
    containerBox: {
      width:"100%",
      margin: "auto",     
      justifyContent: "center",
      textAlign: "center", 
      overflow: "auto",     
    },

    icon: {
      color: theme.palette.common.white,      
      width: "30px",
      height: "25px",
      alignItems: "center",
      justifyContent: "center",
      [theme.breakpoints.down('sm')]: {
        width: "30px",
        height: "25px",
      },
    },
  },

  trafficLight: {
    amber: {
      backgroundColor: theme.palette.dashboard.trafficLight.amber,
    },

    green: {
      backgroundColor: theme.palette.dashboard.trafficLight.green,
    },

    red: {
      backgroundColor: theme.palette.dashboard.trafficLight.red,
    },

    defaultBackground: {
      backgroundColor: theme.palette.dashboard.widgetDefaultBackground,
    },
  },

  traffic: {
    container: {
      border: "1px solid",
      //backgroundColor: props => props?.widgetBackgroundColor ?? "",
      borderColor: theme.palette.widget.borderColor,
      height: "100%",
      width: "100%",
      color: theme.palette.color.secondary.main,
      alignItems: "center",
      justifyContent: "center",
      fontSize: (props) => props.fontSize,
    },

    numberContent: {
      alignItems: "center",
      justifyContent: "center",
      fontSize: "clamp(22px, 2em, 26px)",   
      color: theme.palette.color.secondary.main,
      [theme.breakpoints.down('sm')]: {
        fontSize: "1.5em",
      },
    },

    content: {
      alignItems: "center",
      justifyContent: "center",
      fontSize: "1em",
      color: theme.palette.color.primary.dark,      
      [theme.breakpoints.down('sm')]: {
        fontSize: "0.9em",
      },
      overflow: "auto",     
      margin:"auto"
    },

    location: {
      alignItems: "center",
      justifyContent: "center",
      fontSize: "clamp(12px, 1.2em, 14px)",         
      color: theme.palette.color.primary.dark,      
      [theme.breakpoints.down('sm')]: {
        fontSize: "0.9em",
      },
      overflow: "auto",     
      margin:"auto",
      whiteSpace: 'nowrap',
      padding: theme.spacing(1),
      textOverflow: 'ellipsis',
      overflow: 'hidden'
    },

    dataSourceContent: {
      alignItems: "center",
      justifyContent: "center",
      fontSize: "0.8em",
      color: theme.palette.color.secondary.main,
      marginTop: "-3px",
      overflow: "auto",     
      margin: "auto",
      [theme.breakpoints.down('sm')]: {
        fontSize: "0.6em",
      },
    },

    hoursContent: {
      fontSize: "0.8em",
      color: theme.palette.color.secondary.main,
      overflow: "auto",     
      margin: "auto",
      [theme.breakpoints.down('sm')]: {
        fontSize: "0.6em",
      },
    },

    trafficInOutContainerLeftWithBorder: {
      justifyContent: "center",
      textAlign: "center",
      margin:"auto",
    }, 

    containerLeftWithBorder: {
      width: "66%",
      borderRight: "1px solid #DCE3E8",      
      justifyContent: "center",
      textAlign: "center",
      margin:"auto",
    },    

    containerRight: {
      width: "34%",
      justifyContent: "center",
      textAlign: "center",
      margin:"auto",
    },
    
    icon: {
      width: "30px",
      height: "25px",
      alignItems: "center",
      justifyContent: "center",
    },

    trafficInOutDisplayModeChip: {
      borderRadius: "12px",
      fontSize: "18px",
      backgroundColor: theme.palette.widget.trafficInOut.displayModeChipBackgroundColor,
      color: theme.palette.widget.trafficInOut.displayModeChipFontColor,
    },
  },

  number: {
    container: {
      borderWidth: "1px",
      borderStyle: "solid",
      borderColor: theme.palette.widget.borderColor,
      height: "100%",
      backgroundColor: "",// props => props?.widgetBackgroundColor ?? "",
      color: theme.palette.color.secondary.main,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      fontSize: (props) => props.fontSize,
    },

    value: {
      fontSize: "clamp(10px, 2.5em, 36px)",
      fontWeight: "450",
      textAlign: "center",
      color: "#000031", 
    },

    caption: {
      fontSize: "clamp(10px, 1.5em, 24px)",
      fontWeight: "400",
      textAlign: "center",
      color: theme.palette.color.primary.dark,
      lineHeight: "1.6em",
    },

    desc1: {
      fontSize: "clamp(10px, 1.2em, 24px)",
      fontWeight: "450",
      textAlign: "center",
      color: "#627E84",
    },

    desc2: {
      fontSize: "clamp(10px, 1em, 20px)",
      fontWeight: "400",
      textAlign: "center",
      color: theme.palette.color.primary.dark,
    },
  },

  map: {
    container: {
      display: "flex",
      flexDirection: "column",
      borderWidth: "1px",
      borderStyle: "solid",
      borderColor: theme.palette.widget.borderColor,
      // 100% width and height ensures that the map will take up all available 
      // realestate of the widget. The map componenent will then position the
      // map picture in the middle of the widget (the "meet" behaviour with 
      // potential empty bands on left-right or on top-bottom, as opposite of
      // "slice" behaviour, when the picture gets cropped to fit).
      minHeight: 0,
      height: "100%",
      width: "100%",
      alignItems: "center",
      justifyContent: "center",
      //backgroundColor: props => props?.widgetBackgroundColor ?? "", 
    },
    caption: {
        fontSize: "1.2em",
        fontWeight: "400",
        textAlign: "center",
        color: theme.palette.color.primary.dark,
        lineHeight: "1.6em",
    },
    title: {
        width: "100%",
        height: 30,
        textAlign: "center",
        backgroundColor: "lightgray",
        border: "1px solid silver",
        // borderBottom: 0,
    },
  },

  widgetLabel: {
    container: {
      height: "100%",      
      display: "flex",
      flexDirection: "column",
      alignItems: "inherit",
      justifyContent: "center",
      fontSize: (props) => props.fontSize,
      paddingLeft: "15px",
      paddingRight: "15px",
      backgroundColor: theme.palette.color.primary.background,
      //backgroundColor: props => props?.widgetBackgroundColor ?? theme.palette.dashboard.titleColor, //????
    },

    value: {
      fontSize: "clamp(18px, 2.5em, 30px)",
      fontWeight: "500", 
      display: "-webkit-box",
      WebkitBoxOrient: "vertical",
      WebkitLineClamp: (props) => props.textLinesNumber,
      whiteSpace: "normal",
      overflow: "hidden",
      textOverflow: "ellipsis",
      color: "#000031", 
    },
  },

  camera: {
    container: {
        height: "100%",
        width: "100%",      
        alignItems: "center",
        justifyContent: "center", 
        flexDirection: "column",
        //backgroundColor: props => props?.widgetBackgroundColor ?? "",
        fontSize: (props) => props.fontSize,
      },
    bordered: {
        border: "1px solid",
        borderColor: theme.palette.widget.borderColor,
    },
    caption: {
        fontSize: "1.2em",
        fontWeight: "400",
        textAlign: "center",
        color: theme.palette.color.primary.dark,
        lineHeight: "1.6em",
    },
    title: {
        width: "100%",
        height: 30,
        textAlign: "center",
        backgroundColor: "lightgray",
        border: "1px solid silver",
        // borderBottom: 0,
    },
    icon: {
        width: "150px",
        height: "150px",
        alignItems: "center",
        justifyContent: "center",
      },
  },

  embeddedWidget: {
    container: {
      borderWidth: "1px",
      borderStyle: "solid",
      borderColor: theme.palette.widget.borderColor,
      height: "100%",
      width: "100%",
      alignItems: "center",
      justifyContent: "center",
    },
  },

  queueModelWidget: {
    container: {
      height: "100%",
      width: "100%",      
      alignItems: "center",
      justifyContent: "center", 
      flexDirection: "column",
    },
    bordered: {
      border: "1px solid",
      borderColor: theme.palette.widget.borderColor,
    },
    location: {
      fontSize: 16,
      maxWidth: '100%',
      marginLeft: 'auto',
      marginRight: 'auto',
      color: theme.palette.color.primary.dark,
      textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: 'nowrap',
      padding: theme.spacing(1)
    }
  },

  patronDistribution: {
    container: {
      height: "100%",
      width: "100%",      
      alignItems: "center",
      justifyContent: "center",   
      fontSize: (props) => props.fontSize,
    },
    containerBox: {
      width:"100%",
      margin: "auto",     
      justifyContent: "center",
      textAlign: "center", 
      overflow: "auto",     
    },

    valueComparisonContainer: {
      width: "50%",
      justifyContent: "center",
      textAlign: "center",  
      overflow: "auto",
      height: "100%",
      padding: "0px 5px 0px 5px",
    },
    valueComparisonDividerContainer: {
      justifyContent: "center", 
      alignItems: "center", 
      overflow: "auto", 
      height: "100%",
    },
    valueComparisonDivider: {
      height: "60%",
      borderRight: "3px solid rgba(220, 227, 232, 0.5)",
    },

    patronDistributionTimePeriodChip: {
      display: "flex", 
      justifyContent: "center", 
      marginTop: "8px",
    },

    percentageValue: {
      color: "rgba(3, 24, 32, 1)", 
      fontSize: "clamp(16px, 2.3em, 32px)",
      fontWeight: "500", 
      alignItems: "center",
      justifyContent: "center",
      [theme.breakpoints.down('sm')]: {
        fontSize: "clamp(24px, 1.5em, 32px)",
      },
    },
    percentageSubTitleContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      textAlign: "center",
      overflow: "auto",
    },
  },

  ticketScansAndSales: {
    container: {
      height: "100%",
      width: "100%",      
      alignItems: "center",
      justifyContent: "center",   
      fontSize: (props) => props.fontSize,
    },
    containerBox: {
      width:"100%",
      margin: "auto",     
      justifyContent: "center",
      textAlign: "center", 
      overflow: "auto",   
      borderWidth: "1px",
      borderStyle: "solid",
      borderColor: theme.palette.widget.borderColor,
    },
  },

  ticketAttendanceChart: {
    container:{
      borderWidth: "1px",
      borderStyle: "solid",
      borderColor: theme.palette.widget.borderColor,
    },
    bar: {
      borderRadius: props => `${Math.max(4, Math.floor(props.scaleFactor * 8))}px`
    },
    ticketSalesBar: {
      background: "linear-gradient(180deg, #627E84 0%, #7B9CA4 100%)",
    },
    ticketSalesLegend: {
      background: theme.palette.color.secondary.main
    },
    expectedAttendanceBar: {
      background: "linear-gradient(180deg, #031820 0%, #3A5C69 100%)",
    },
    expectedAttendanceLegend: {
      background: theme.palette.color.primary.dark
    },
    ticketScansBar: {
      background: "linear-gradient(180deg, #C13984 0%, #D988B5 100%)",
    },
    ticketScansLegend: {
      background: theme.palette.color.primary.main
    },
    emptyBar: {
      background: theme.palette.color.primary.disabled,
    },
    legendContainer: {
      marginTop: props => `${props.scaleFactor * 8}px`,
    },
    legendItemContainer: {
      marginLeft: props => `${props.scaleFactor * 12}px`,
      marginRight: props => `${props.scaleFactor * 12}px`,
    },
    legendColorSwatch: {
      width: props => `${props.scaleFactor * 12}px`,
      height: props => `${props.scaleFactor * 12}px`,
      borderRadius: props => `${props.scaleFactor * 6}px`,
    },
    legendTitle: {
      marginLeft: props => `${props.scaleFactor * 4}px`,
      fontSize: props => `${props.scaleFactor * 12}px`,
      fontWeight: 400,
      color: theme.palette.color.secondary.main,
      whiteSpace: "nowrap",
      overflow: "none"
    },
    legendValue: {
      marginLeft: props => `${props.scaleFactor * 16}px`,
      fontSize: props => `${props.scaleFactor * 16}px`,
      fontWeight: 500,
      color: theme.palette.color.primary.dark
    }
  },

  widgetIcon: {
    container: {
      alignItems: "center",
      justifyContent: "center",
      padding: "5px",      
    },

    numberWithMax: {
      width: "60%",
      height: "100%",
      borderRight: "1px solid #38A552",
      //borderColor: theme.palette.widget.borderColor,
    },
  },

  fadingEmptyWidget: {
    backgroundColor: '#E9ECF255',
    width: '100%',
    height: '100%',
    opacity: 0,
    transitionProperty: 'opacity',
    transitionTimingFunction: 'ease-in-out'
  },

  flashingWidget: {
    "@keyframes flashingWidgetEffect": {
        "0%": { opacity: 1 },
        "50%" : { opacity: 0.5 },
        "100%" : { opacity: 1 }
    },
    flashEffect: {
        animation: `$flashingWidgetEffect 1s infinite`
    },
  }

});

export default widgetTheme;
