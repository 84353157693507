import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { DataGrid } from "@material-ui/data-grid";
import {
  Typography,Box,
  IconButton,
  Tooltip,  
  Grid,
  Card,
  CardContent,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import {TextLink} from "../../_common/htmlTags/TextLink";
import {makeStyles} from "@material-ui/core/styles";
import EditIcon from "@material-ui/icons/Edit";
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import DeleteIcon from "@material-ui/icons/Delete";
import {
  loadUsers,
  disableUser,
  enableUser,
  deleteUser
} from "../../../_actions/user.management.actions";
import { getLoginUser } from "../../../_services/userManagement.service";
import { toast } from "react-toastify";
import { AlertDialog } from "../../../_helpers/AlertDialog";
import LoadPleaseWait from "../../notification/LoadingPleaseWait/LoadingMessage";
import {Button} from "../../_common/htmlTags/Button";
import AddIcon from "@material-ui/icons/Add";
import { ROLES} from "../../../_constants/user.permissions.constants";
import useLocalStorage from "../../../_helpers/useLocalStorage";
import useSortModel from "../../../_helpers/useSortModel";

const useStyles = makeStyles((theme) =>
{
  var style = {};
  style.numberCircle = { ...theme.htmlTag.numberCircle }
  style.icon = {
    color: theme.palette.color.primary.dark,
    "&:hover": {
      color: theme.palette.color.primary.main,
    }
  }
  style.disableIcon = {
    color: theme.palette.color.primary.green
  }  
  style.deleteIcon = { 
    color: theme.palette.color.primary.red
  }
  style.inactiveIcon = {
    color: theme.palette.color.secondary.main, 
  }  
  style.inactiveColor = {
    '& .Users--enabled--false': {
      color: theme.palette.color.secondary.main,
    },
  }

  style.circle = {    
    'border-radius': '50%',  
    'text-align': 'center',
    width: '17px',
    height: '17px',     
    background: theme.palette.color.secondary.main,
    color: '#fff',      
    marginLeft: '5px',
  }
  style.circleText = {
    fontSize: '12px'
  }

  style.noLoginHistory = {
    color: theme.palette.color.secondary.main
  };

  return style;
});

const UsersList = () => {
  const classes = useStyles();
  const users = useSelector((state) => state.userManagement.users);
  const isLoading = useSelector(
    (state) => state.userManagement.loading.usersLoading
  );
  const dispatch = useDispatch();
  const [pageSize, setPageSize] = useLocalStorage("pmy-users-pageSize", 5);
  const [sortModel, setSortModel] = useSortModel("pmy-users-sortModel", [{field: 'email', sort: 'asc'}]);
  const [disableOpen, setDisableOpen] = useState(false);
  const [restoreOpen, setRestoreOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [loginUser, setLoginUser] = useState();

  useEffect(() => {
    dispatch(loadUsers()).catch((error) => {
      toast.error("Loading users failed" + error, { autoClose: false });
    });

    dispatch(getLoginUser).then((user) => { setLoginUser(user); }).catch((error) => {
      toast.error("Get login user failed" + error, { autoClose: false });
    });

  }, [dispatch]);

  const handleRemoveUser = (email) => {
    try {
      dispatch(disableUser(email));
      toast.success("User disabled");
    } catch (error) {
      toast.error("Disabling user failed. " + error.message, {
        autoClose: false,
      });
    }
  };

  const handleRestoreUser = (email) => {
    toast.success("User enabled");
    try {
      dispatch(enableUser(email));
    } catch (error) {
      toast.error("Enabling user failed. " + error.message, {
        autoClose: false,
      });
    }
  };

  const handleDeleteUser = (userId, email) => {
    dispatch(deleteUser(userId))
    .then(() => {
      toast.success("User deleted.");
    })
    .catch((error) => {
      toast.error("Deleting user failed. " + error, { autoClose: false });
    });
  };
  
  const venueFormatter = (params) => {
    const venue = params?.venue.map((e) => { return e.name });   
    const numberVenue = 2    

    if (params?.role?.name === ROLES.ADMINISTRATOR)
      return <Typography>All</Typography>;
    
    if (venue?.length !== undefined && venue?.length > 0) {
      let element = "";
      for (let index = 0; index < (venue?.length < numberVenue ? venue?.length : numberVenue); index++) {
        if (element.length > 0)
          element += ", ";
        element += venue[index];
      }
      
      if (venue?.length <= numberVenue) {
        return element;
      }
      else {
        return (
          <Tooltip title={venue.map((m, i) => { return i === 0 ? m : ", " + m })}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center"
              }}
            >
              <Typography>{element}</Typography>
              <Box className={classes.circle}>
                <Typography className={classes.circleText}>
                  +{venue.length - numberVenue}
                </Typography>
              </Box>
            </Box>
          </Tooltip>
        )
      }
    }
  }

  const getIsActionsVisible = (userParams) => {
    // 1. If user in list is current user => not visible
    if (userParams.id === loginUser?.profile.sub)
      return false;

    // 2. If user in list is Admin 
    // (the first created Admin with login Admin, which is not even email)
    // => not visible, because no one can manage this user in no way.
    if (userParams.row.email === "admin")
      return false;

    // 3. If current user is Global Admin:
    if (loginUser?.profile.role === ROLES.ADMINISTRATOR) {
      // 3.1. Global admin can edit and disable user of all roles, 
      // 3.2. except condition 1 and 2 (his/her-self and Admin)
      return true;
    }

    // 4. If current user is Venue Admin:
    if (loginUser?.profile.role === ROLES.VENUE_ADMINISTRATOR) {
      // 4.1. Venue admin can edit and disable users of all roles, 
      // 4.2. except conditions 1 and 2 (his/her-self and Admin)
      // 4.3. except Global admins
      if (userParams?.row?.role?.name === ROLES.ADMINISTRATOR) {
        return false;
      }
    }

    return true;
  }

  const columns = [
    {
      field: "id",
      hide: true,
    },
    {
      field: "firstName",
      headerName: "FIRST NAME",
      flex: 1,
    },
    {
      field: "lastName",
      headerName: "LAST NAME",
      flex: 1,
    },
    {
      field: "email",
      headerName: "LOGIN",
      flex: 1.5,
    },
    {
      field: "lastSuccessfulLoginUtc",
      headerName: "LAST ACCESS",
      flex: 1,
      renderCell: (params) => {
        if (params.row?.lastSuccessfulLoginUtc) {
          const localDatetime = new Date(params.row?.lastSuccessfulLoginUtc); // lastSuccessfulLoginUtc is a string in Zulu format.
          return localDatetime.toLocaleString();
        } else {
          return <Typography className={classes.noLoginHistory}>never accessed</Typography>
        }
      }
    },
    {
      field: "role",
      headerName: "ROLE",
      flex: 1,
      valueFormatter: (params) => params.row?.role?.name,
      sortComparator: (a, b) => a.name.localeCompare(b.name),
    },
    {
      field: "venue",
      headerName: "VENUES",
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            {
              venueFormatter(params.row)             
            }
          </>
        )
      }
    },  
    {
      field: "actions",
      headerName: "ACTIONS",
      sortable: false,
      width: 170,
      sortable: false,
      renderCell: (params) => {
        if (! getIsActionsVisible(params))
          return <></>
          
          return (
            <>
              <Tooltip title="Edit">
                <IconButton
                  aria-label="edit"
                  className={params.row.enabled ? classes.icon : classes.inactiveIcon}
                  disabled={!params.row.enabled}
                  color="primary"
                  component={Link}
                  to={`/user/${params.row.id}`}
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>
  
              <Tooltip title={params.row.enabled ? "Disable" : "Enable"}>
                <span>
                  <IconButton
                    className={params.row.enabled ? classes.disableIcon : classes.inactiveIcon}
                    color="primary"
                    onClick={() => {
                      params.row.enabled ? setDisableOpen(true) : setRestoreOpen(true)
                      setCurrentUser({id: params.row.id, email: params.row.email});
                    }}
                  >
                    <PowerSettingsNewIcon />
                  </IconButton>
                </span>
              </Tooltip>

              <Tooltip title="Delete user permanently">
                <span>
                  <IconButton
                    className={classes.deleteIcon}
                    color="primary"
                    onClick={() => {
                      setDeleteOpen(true);
                      setCurrentUser({id: params.row.id, email: params.row.email, firstName: params.row.firstName, lastName: params.row.lastName});
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </span>
              </Tooltip>
            </>
          );
      },
    },
  ];

  return (
    <>
      <AlertDialog open={disableOpen} contentText={`Do you want to disable this user?`}
        onNoButtonClicked={() => {
          setDisableOpen(false);
        }}
        onYesButtonClicked={(e) => {
          handleRemoveUser(currentUser?.email);
          setDisableOpen(false);
        }} />
      <AlertDialog open={restoreOpen} contentText={`Do you want to enable this user?`}
        onNoButtonClicked={() => {
          setRestoreOpen(false);
        }}
        onYesButtonClicked={(e) => {
          handleRestoreUser(currentUser?.email);
          setRestoreOpen(false);
        }} />
      <AlertDialog 
        open={deleteOpen} 
        contentText={`Do you want to permanently delete user ${currentUser?.firstName} ${currentUser?.lastName} (${currentUser?.email}) and all existing references to that user?`}
        onNoButtonClicked={() => {
          setDeleteOpen(false);
          setCurrentUser(null);
        }}
        onYesButtonClicked={(e) => {
          handleDeleteUser(currentUser?.id);
            setDeleteOpen(false);
            setCurrentUser(null);
        }} />  
      <Grid container direction="row" justifyContent="space-between">
        <Typography variant="h4">Users</Typography>
        <Button
          variant="contained"
          component={TextLink}
          to="/user"
          startIcon={<AddIcon />}
        >
          Add
        </Button>
      </Grid>
      
      <div style={{ display: "flex", height: "100%", paddingTop: 15 }} className={classes.inactiveColor}>
        <div style={{ flexGrow: 1 }}>
          <LoadPleaseWait show={isLoading} >
            <Card style={{ overflow: 'auto' }}>
              <CardContent>
                <DataGrid
                  autoHeight
                  pageSize={pageSize}
                  onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                  rowsPerPageOptions={[5, 10, 20]}
                  sortModel={sortModel}
                  onSortModelChange={(model) => setSortModel(model)}
                  sortingOrder={['desc','asc']}
                  columns={columns}
                  rows={users}
                  getRowClassName={(params) => `Users--enabled--${params.row.enabled}`}
                  pagination                    
                />
              </CardContent></Card>
          </LoadPleaseWait>
        </div>
      </div>
    </>
  );
};

export default UsersList;
