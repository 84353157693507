import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {
  Box,
  FormControl,
  IconButton, 
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import {isInteger, convertDateStrToIanaTz} from "../../_helpers/functions.fieldValue";
import {addTicketSale, updateTicketSale} from "../../_services/ticketSales.service";
import { NIL as NIL_UUID } from "uuid";
import {toast} from "react-toastify";
import {Button, ButtonType} from "../_common/htmlTags/Button";

const useStyles = makeStyles((theme) => {
  return {
    popup: {
      width: "462px",
      backgroundColor: "#FFFFFF",
      position: "absolute",
      top: "50%",
      left: "50%",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      padding: theme.spacing(3),
    },
    title: {
      fontSize: "24px",
      fontWeight: "500",
      flexGrow: 1
    },
    closeIcon: {
      marginTop:"-20px",
      marginRight: "-20px"
    },
    field: {
      width: "415px",
      marginTop: theme.spacing(3)
    },
    dateField: {
      width: "220px",
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3)
    },
    buttons: {
      marginTop: theme.spacing(3),
    }
  };
});

export const TicketSalePopup = ({venue, ticketSale, onSave, onCancel}) => {
  const classes = useStyles();

  const [date, setDate] = useState(ticketSale.venueLocalDate);
  const [sold, setSold] = useState('' + ticketSale.ticketSales);
  const [expectedAttendance, setExpectedAttendance] = useState('' + ticketSale.expectedAttendance);
  const [isSaving, setIsSaving] = useState(false);

  const handleSave = (e) => {
    e.preventDefault();

    const errors = [];

    let soldToSave = sold.trim();
    if (sold === '') {
      setSold('0');
      soldToSave = '0';
    }

    if (!isInteger(soldToSave)) {
      errors.push("Tickets sold must be an integer.");
    } else if (Number(soldToSave) < 0) {
      errors.push("Tickets sold cannot be negative.");
    }

    let expectedAttendanceToSave = expectedAttendance.trim();
    if (expectedAttendance === '') {
      setExpectedAttendance('0');
      expectedAttendanceToSave = '0';
    }

    if (!isInteger(expectedAttendanceToSave)) {
      errors.push("Expected attendance must be an integer.");
    } else if (Number(expectedAttendanceToSave) < 0) {
      errors.push("Expected attendance cannot be negative.");
    } 

    if (!date) {
      errors.push("Date must be selected.");
    }

    if (errors.length === 0) {
      setIsSaving(true);
      const ticketSaleToSave = {
        ...ticketSale,
        id: ticketSale.id,
        venueLocalDate: date,
        ticketSales: Number(soldToSave),
        expectedAttendance: Number(expectedAttendanceToSave)
      };
      
      if (ticketSale.id === NIL_UUID) {
        addTicketSale(ticketSaleToSave)
        .then(result => {
          setIsSaving(false);
          onSave();
          toast.success("Ticket sale added successfully.");
        })
        .catch((error) => {
          setIsSaving(false);
          toast.error("Failed to add ticket sale. " + error);
        });
      } else {
        updateTicketSale(ticketSaleToSave)
        .then(result => {
          setIsSaving(false);
          onSave();
          toast.success("Ticket sale updated successfully.");
        })
        .catch((error) => {
          setIsSaving(false);
          toast.error("Failed to update ticket sale. " + error);
        });
      }

    } else {
      toast.error(errors.join("\n"));
    }
  };

  return (
    <Modal open={true}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          maxHeight: "920px",
          alignItems: "stretch"
        }}
        className={classes.popup}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start"
          }}
        >
          <Typography className={classes.title}>
            Ticket sale details
          </Typography>
          <Tooltip 
            title="Close" 
          >
            <IconButton
              aria-label="view"
              className={classes.closeIcon}
              onClick={(e) => {
                onCancel();
              }}
            >
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </Box>

        <TextField
          id="venueLocalDate"
          label="Date"
          type="date"
          name="venueLocalDate"
          value={date}
          InputLabelProps={{
            shrink: true,
          }}
          fullWidth
          className={classes.dateField}
          onChange={(e) => {
            const { value } = e.target;
            setDate(value);
          }}
        />

        <TextField
          id="sold"
          label="Tickets sold"
          variant="outlined"
          required
          name="sold"
          value={sold}
          onChange={(e) => {
            setSold( e.target.value);
          }}
          className={classes.field}
        />

        <TextField
          id="expectedAttendance"
          label="Expected attendance"
          variant="outlined"
          required
          name="expectedAttendance"
          value={expectedAttendance}
          onChange={(e) => {
            setExpectedAttendance( e.target.value);
          }}
          className={classes.field}
        />

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
          }}
          className={classes.buttons}
        >
          <Button
            variant="contained"
            type="submit"
            disabled={isSaving}
            buttonType={ButtonType.Primary}
            onClick={handleSave}
            style={{
              padding: "10px",
              marginRight: "10px",
            }}
          >
            {isSaving ? "Saving..." : "Save"}
          </Button>&nbsp;
          <Button
            className="btn primary"
            variant="outlined"
            type="button"
            buttonType={ButtonType.Secondary}
            onClick={() => {
              onCancel();
            }}
            style={{
              padding: "9px"
            }}
          >Cancel</Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default TicketSalePopup;