import React from "react";
import { widgetSettingsConstants } from "../../../../../_constants/widget.settings.constants";
import { alertTypeNameMapping } from "../../../../../_constants/dashboard.alertRuleSet.constants";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    selectionOptionFlexContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        maxWidth: 490,
    },
    additionalOptionName: {
        color: theme.palette.color.secondary.main,
        marginLeft: 10,
    },
    optionEllipsisContainer: {
        display: 'flex',
        minWidth: 0,
    },
    optionEllipsis: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
}));

export const SelectionOptionText = (props) => {
    const { settingClassName, item } = props;

    const classes = useStyles();

    switch (settingClassName) {
        case widgetSettingsConstants.refAlertRuleSet.className:
        case widgetSettingsConstants.refTableMapAlerts.className:
            return (
                <div className={classes.selectionOptionFlexContainer}>
                    <div className={classes.optionEllipsisContainer}>
                        <div className={classes.optionEllipsis}>{item?.name}</div>
                    </div>
                    <div className={classes.additionalOptionName}>{alertTypeNameMapping[item?.alertType]}</div>
                </div>
            );

        case widgetSettingsConstants.refLocation.className:
        case widgetSettingsConstants.refBaseLineLocation.className:
        case widgetSettingsConstants.refListLocation.className:
            return (
                <div className={classes.selectionOptionFlexContainer}>
                    <div className={classes.optionEllipsisContainer}>
                        <div className={classes.optionEllipsis}>{item?.name}</div>
                    </div>
                    <div className={classes.additionalOptionName}>{item?.locationType?.name}</div>
                </div>
            );

        default:
            return (item?.name);
    }
}

export default SelectionOptionText;