import React, {useState, useEffect, useRef} from 'react';
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  IconButton,
  Menu,
  MenuItem,
  SvgIcon,
  Typography
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import {DataGrid} from "@material-ui/data-grid";
import { toast } from "react-toastify";
import {DataGridLoadingOverlay} from "../_common/DataGridLoadingOverlay";
import {useApiGet} from "../../_helpers/useApiGet";
import useLocalStorage from "../../_helpers/useLocalStorage";
import useSortModel from "../../_helpers/useSortModel";
import { Button, ButtonType } from "../_common/htmlTags/Button";
import {getIncidents, reopenIncident, resolveIncident} from "../../_services/incident.service";
import IncidentPopup from "./IncidentPopup";
import IncidentStatus from './IncidentStatus';
import { NIL as NIL_UUID} from "uuid";

const useStyles = makeStyles((theme) => ({
  locationNone: {
    color: theme.palette.color.secondary.main
  },
  actionIcon: {
    color: theme.palette.color.primary.dark
  }

}));

const getIncidentsCall = async (venueId) => {
  if (venueId) {
    const result = await getIncidents(venueId);
    return result;
  } else {
    return [];
  }
};

export const utcDateStringToLocaleString = (utcDateString) => {
  const zuluDate = new Date(utcDateString + 'Z');
  return zuluDate.toLocaleDateString([], {}) + ' ' + zuluDate.toLocaleTimeString([], {hour: '2-digit', minute: '2-digit'}); // L10n: the space between the date and time parts may be incorrect in some regions.
};

export const Incidents = () => {
  const selectedVenue = useSelector((state) => state.venueReducer.selectedVenue);
  
  const classes = useStyles();

  // The "data" here is the array of incidents as they are loaded from the database.
  const [{data: initialIncidents, isLoading : isLoading, refresh: refreshIncidents}] = useApiGet(getIncidentsCall, selectedVenue?.id, null);

  const [incidents, setIncidents] = useState([]);
  const [incidentToEdit, setIncidentToEdit] = useState(null);
  const [pageSize, setPageSize] = useLocalStorage("pmy-incidents-pageSize", 10);
  const [sortModel, setSortModel] = useSortModel("pmy-incidents-sortModel", [{field: 'createdDate', sort: 'desc'}]);

  const [selectedIncident, setSelectedIncident] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    if (initialIncidents?.data) {
      setIncidents(initialIncidents?.data);
    }
  }, [initialIncidents]);

  const columns = [{
    field: 'id',
    hide: true
  }, {
    field: 'description',
    headerName: 'DESCRIPTION',
    flex: 1
  }, {
    field: 'locationName',
    headerName: 'LOCATION',
    width: 200,
    renderCell: (params) => {
      return (
        <>
          {
            params.row.locationId ? params.row.locationName
            :
            <Typography className={classes.locationNone}>
              &lt;none&gt;
            </Typography>
          }
        </>
      );
    },
  }, {
    field: "action",
    sortable: false,
    headerName: " ",
    width: 65,
    disableColumnMenu: true,
    renderCell: (params) => {
      return (
        <IconButton
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          onClick={(e) => {
            setSelectedIncident(params.row);
            setAnchorEl(e.currentTarget);
          }}
        >
          <MoreVertIcon className={classes.menuIcon} fontSize="small" />
        </IconButton>
      );
    }
  }, {
    field: 'createdDate',
    headerName: 'CREATED',
    width: 190,
    renderCell: (params) => {
      return (
        <>
          {utcDateStringToLocaleString(params.row.createdDate)}
        </>
      );
    },
  }, {
    field: 'resolvedDate',
    headerName: 'RESOLVED',
    width: 190,
    renderCell: (params) => {
      return (
        <>
          {params.row.resolvedDate? utcDateStringToLocaleString(params.row.resolvedDate) : ''}
        </>
      );
    },
  }, {
    field: 'isResolved',
    headerName: 'STATUS',
    width: 140,
    renderCell: (params) => {
      return (
        <IncidentStatus isResolved={params.row.isResolved} />
      );
    }
  }, {
      field: "lastUpdated",
      headerName: "LAST UPDATED",
      width: 200,
      renderCell: (params) => {
        return (
          <>
            {(new Date(params.row.lastUpdated + "Z")).toLocaleString()}
          </>
        );
      },
    },
    {
      field: "lastUpdatedByUser",
      headerName: "LAST UPDATED BY",
      width: 210,
      renderCell: (params) => {
        return (
          <>
            {params.row.lastUpdatedByUser ?  // lastUpdatedByUser is null if the user has been deleted from STS
              params.row.lastUpdatedByUser
              : <Typography className={classes.notAvailableUser}>not available</Typography>
            }
          </>
        );
      },
  }];

  const handleResolveClick = () => {
    resolveIncident(selectedIncident.id)
    .then(result => {
      toast.success("Incident has been marked as resolved.");
      setAnchorEl(null);
      refreshIncidents();
    })
    .catch((error) => {
      toast.error("Failed to resolve the incident. " + error + (error.response?.data ? ' ' + error.response.data : '') , { autoClose: false });
      setAnchorEl(null);
      console.error(error);
    });
  };

  const handleReopenClick = () => {
    reopenIncident(selectedIncident.id)
    .then(result => {
      toast.success("Incident has been reopened.");
      setAnchorEl(null);
      refreshIncidents();
    })
    .catch((error) => {
      toast.error("Failed to reopen the incident. " + error + (error.response?.data ? ' ' + error.response.data : ''), { autoClose: false });
      setAnchorEl(null);
      console.error(error);
    });
  };

  return (
    <>
      <Box 
        sx={{
          display: "flex",
          flexDirection: "column"
        }}
      >
        <Box
          sx={{
            display: "flex",
            mt: "8px",
            mb: "16px"
          }}
        >
          <Typography 
            style={{
              flexGrow: 1
            }}
            variant="h5"
            >
              Incidents
          </Typography>
          <Button
            buttonType={ButtonType.TopBarPrimary}
            size="small"
            //component={TextLink}
            startIcon={<AddIcon />}
            onClick={(e) => {
              setIncidentToEdit({id: NIL_UUID, venueId: selectedVenue?.id, description: "", locationId : null, locationName: '', isResolved: false});
            }}
          >
            Add
          </Button>
        </Box>
        <DataGrid
          autoHeight={true}
          pageSize={pageSize}
          onPageSizeChange={(value) => setPageSize(value)}
          rowsPerPageOptions={[5,10,20]}
          sortModel={sortModel}
          onSortModelChange={(model) => setSortModel(model)}
          sortingOrder={['desc','asc']}
          columns={columns}
          rows={
            incidents === null ? [] : incidents
          }
          components={{
            LoadingOverlay: DataGridLoadingOverlay,
          }}
        ></DataGrid>
        <Menu
          id="action-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={(e) => setAnchorEl(null)}
        >
            
          {selectedIncident && selectedIncident.isResolved &&
            <MenuItem
              onClick={handleReopenClick}
            >
              <SvgIcon className={classes.actionIcon} style={{marginLeft: 0, marginTop: 4, transform: "scale(1.3)"}}>
                <path d="M6.99996 12.0013H12V13.3346H6.99996C4.60663 13.3346 2.66663 11.3946 2.66663 9.0013C2.66663 6.60797 4.60663 4.66797 6.99996 4.66797H10.78L8.71996 2.60797L9.66663 1.66797L13.3333 5.33464L9.66663 9.0013L8.72663 8.0613L10.78 6.0013H6.99996C5.33329 6.0013 3.99996 7.33464 3.99996 9.0013C3.99996 10.668 5.33329 12.0013 6.99996 12.0013Z" />
              </SvgIcon>
              Reopen
            </MenuItem>
          }

          {selectedIncident && !selectedIncident.isResolved &&
            <MenuItem
              onClick={handleResolveClick}
            >
              <SvgIcon className={classes.actionIcon} style={{marginLeft: 0, marginTop: 4, transform: "scale(1.3)"}}>
                <path d="M5.78788 11.1058L2.44697 7.7625L1.33333 8.87692L5.78788 13.3346L15.3333 3.7824L14.2197 2.66797L5.78788 11.1058Z" />
              </SvgIcon>
              Resolve
            </MenuItem>
          }

          <MenuItem
            onClick={(e) => {
              setAnchorEl(null);
              setIncidentToEdit(selectedIncident);
            }}
          >
            <EditIcon className={classes.actionIcon} fontSize="small" />Edit
          </MenuItem>
        </Menu>
      </Box>
      {
        incidentToEdit ? 
        <IncidentPopup
          incident={incidentToEdit}
          onSave = {() => {
            setIncidentToEdit(null);
            refreshIncidents();
          }}
          onCancel = {() => {
            setIncidentToEdit(null);
          }}
        />
        :
        null
      }
    </>
  );
};

export default Incidents;