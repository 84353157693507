export const widgetSettingsDataTypesConstants = {
    boolean: 1,
    integer: 2,
    refId: 3,
    string: 4,
    refIdList: 5,
    refTable: 6,
    settingsGroup: 7,
    settingsGroupCollection: 8,
}

export const widgetSettingsConstants = {
    widgetSettingTimePeriod: { type: [widgetSettingsDataTypesConstants.refId], className: "WidgetSettingTimePeriod" },
    refVendor: { type: [widgetSettingsDataTypesConstants.refId], className: "RefVendor" },
    refLocation: { type: [widgetSettingsDataTypesConstants.refId], className: "RefLocation" },
    refMap: { type: [widgetSettingsDataTypesConstants.refId], className: "RefMap" },
    refOccupancyViewMode: { type: [widgetSettingsDataTypesConstants.refId], className: "RefOccupancyViewMode" },
    refTrafficType: { type: [widgetSettingsDataTypesConstants.refId], className: "RefTrafficType" },
    refListCohort: { type: [widgetSettingsDataTypesConstants.refIdList], className: "RefListCohort" },
    refNumberWidgetValueType: { type: [widgetSettingsDataTypesConstants.refId], className: "RefNumberWidgetValueType" },
    refDemographicWidgetValueType: { type: [widgetSettingsDataTypesConstants.refId], className: "RefDemographicWidgetValueType" },
    refDemographicWidgetTimePeriod: { type: [widgetSettingsDataTypesConstants.refId], className: "RefDemographicWidgetTimePeriod" },
    refDemographicGenderList: { type: [widgetSettingsDataTypesConstants.refId], className: "Gender" },
    refDemographicAgeGroup: { type: [widgetSettingsDataTypesConstants.refId], className: "AgeGroup" },
    refNumberWidgetTimePeriod: { type: [widgetSettingsDataTypesConstants.refId], className: "RefNumberWidgetTimePeriod" },
    refMapIndicator: { type: [widgetSettingsDataTypesConstants.refId], className: "RefMapIndicator" },
    widgetSettingLabelValue: { type: [widgetSettingsDataTypesConstants.string], className: "WidgetSettingLabelValue" },
    refListLocation: { type: [widgetSettingsDataTypesConstants.refIdList], className: "RefListLocation" },
    refWidgetSettingCameraUrl: { type: [widgetSettingsDataTypesConstants.refId], className: "RefWidgetSettingCameraUrl" },
    refTableMapAlerts: { type: [widgetSettingsDataTypesConstants.refTable], className: "RefTableMapAlerts" },
    refAlertRuleSet: { type: [widgetSettingsDataTypesConstants.refId], className: "RefAlertRuleSet" },
    widgetSettingEmbeddingUrl: { type: [widgetSettingsDataTypesConstants.string], className: "WidgetSettingEmbeddingUrl" },
    refQueueModelAlertMetric: { type: [widgetSettingsDataTypesConstants.refId], className: "RefQueueModelAlertMetric" },
    refTrafficInOutAlertMetric: { type: [widgetSettingsDataTypesConstants.refId], className: "RefTrafficInOutAlertMetric" },
    refTrafficInOutDisplayMode: { type: [widgetSettingsDataTypesConstants.refId], className: "RefTrafficInOutDisplayMode" },
    widgetSettingCustomName: { type: [widgetSettingsDataTypesConstants.string], className: "WidgetSettingCustomName" },
    widgetSettingIncludeDescendants: { type: [widgetSettingsDataTypesConstants.boolean], className: "WidgetSettingIncludeDescendants" },
    refPatronDistributionMetric: { type: [widgetSettingsDataTypesConstants.refId], className: "RefPatronDistributionMetric" },
    refPatronDistributionValueTypes: { type: [widgetSettingsDataTypesConstants.refId], className: "RefPatronDistributionWidgetValueType" },
    refBaseLineLocation: { type: [widgetSettingsDataTypesConstants.refId], className: "RefBaseLineLocation" },
    widgetSettingBaseLineCustomName: { type: [widgetSettingsDataTypesConstants.string], className: "WidgetSettingBaseLineCustomName" },
    widgetSettingBaseLineTimePeriod: { type: [widgetSettingsDataTypesConstants.refId], className: "WidgetSettingBaseLineTimePeriod" },
    widgetSettingBaseLineIncludeDescendants: { type: [widgetSettingsDataTypesConstants.boolean], className: "WidgetSettingBaseLineIncludeDescendants" },
    widgetSettingCustomHelp: {type: [widgetSettingsDataTypesConstants.string], className: "WidgetSettingCustomHelp"},
};