import React, { useEffect, useState, useRef, useContext, useLayoutEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import {
    Box,
    Typography, 
} from "@material-ui/core";
import appViewModeConstants from "../../../_constants/app.view.mode.constants";
import {CommonWidgetLayout} from "../shared/CommonWidgetLayout";
import {useResizeObservedRef} from "../../../_helpers/useResizeObservedRef";
import WidgetContext from "../WidgetContext";

const useStyles = makeStyles((theme) => ({
    ...theme.widget.common,
    ...theme.widget.ticketScansAndSales,
    ...theme.widget.trafficLight,
    container: { ...theme.widget.ticketScansAndSales.container },    
  }));
  
  // define initial values
  const BASE_FONT_SIZE = 10;
  const BASE_WIDGET_SIZE = 100;
  
  const CAPTION_COLOR = "rgba(98, 126, 132, 1)";

export const WidgetTicketScansAndSalesContent = ({width, height, data, settings, viewMode, brandingSettings, onAudioPlay}) => {
    const targetRef = useRef();
    const widgetContextData = useContext(WidgetContext);
    const [fontSize, setFontSize] = useState(BASE_FONT_SIZE);
    const classes = useStyles({ width, fontSize, ...brandingSettings });
    const backgroundColor = classes.defaultBackground;
    
    const isViewMode = viewMode === appViewModeConstants.VIEW_MODE;
  
    // Handling caption color depending on widget's background color.
    widgetContextData.setCaptionColor(CAPTION_COLOR);  
    //data.ticketScans = 25;  // This is just a fake number, for testing.

    const value = ((isViewMode && data?.ticketScans || 0).toLocaleString("en-US")
            + ' / ' + 
            (isViewMode && data?.ticketSales || 0).toLocaleString("en-US"));


    const applyScaleFactor = (width, height) => {
        const newWidgetSize = width > height ? height : width;
        const scaleFactor = newWidgetSize / BASE_WIDGET_SIZE;
    
        setFontSize(BASE_FONT_SIZE * scaleFactor);
    };
        
    const ref = useResizeObservedRef(({ width, height }) => {
        applyScaleFactor(width, height);
    });

    // use this hook to set initial font sizes by applying scale factor based on current widget size. this is used when widget renders for the first time on viewing dashboard
    useLayoutEffect(() => {
        if (targetRef.current) {
            applyScaleFactor(targetRef.current.offsetWidth, targetRef.current.offsetHeight);
        }
    }, []);

    return (
        <Box
            className={`${classes.container} ${backgroundColor}`}
            height={height}
            ref={ref}>
            <Box 
                isplay="flex" height={height} ref={targetRef}>
                <Box style={{ height: '100%' }}
                    display="flex"
                    flexDirection="column"
                    className={`${classes.containerBox} `}
                >
                    <CommonWidgetLayout 
                        value={value} 
                        name="All tickets"                         
                    />
                </Box>
            </Box>
        </Box>
    );
};

export default WidgetTicketScansAndSalesContent;
