import React from 'react';
import { alpha, makeStyles } from "@material-ui/core/styles";
import {
  Typography
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  label: {
    padding: '3px 10px 3px 10px',
    borderRadius: '3px',
  },
  open: {
    color: theme.palette.color.primary.red,
    background: alpha(theme.palette.color.primary.red, 0.2),
  },
  resolved: {
    color: theme.palette.color.primary.green,
    background: alpha(theme.palette.color.primary.green, 0.2),
  }
}));

export const IncidentStatus = ({isResolved}) => {
  const classes = useStyles();

  return (
    <Typography className={`${classes.label} ${isResolved ? classes.resolved : classes.open}`}>
      {isResolved ? "Resolved" : "Open"}
    </Typography>
  )
};

export default IncidentStatus;