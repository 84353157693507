import PermissionsGate from "../_common/permissions/PermissionsGate";
import {Box, Card, CardContent, Tab, Tabs, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {useSelector} from "react-redux";
import React, { useState} from "react";
import {SCOPES} from "../../_constants/user.permissions.constants";
import {TabContext, TabPanel} from "@material-ui/lab";
import PMYVisionMessageChecker from "./PMYVisionMessageChecker";
import PMYVisionTrafficInOutMessageChecker from "./PMYVisionTrafficInOutMessageChecker";
import PMYVisionQueueModelMessageChecker from "./PMYVisionQueueModelMessageChecker";
import PMYVisionDemographicMessageChecker from "./PMYVisionDemographicMessageChecker";
import SeeTicketsMonitor from "./SeeTicketsMonitor";
import ArmMonitor from "./ArmMonitor";
import Incidents from "./Incidents";
import AddAlertIcon from '@material-ui/icons/AddAlert';
import MonitorAlertSettingPopup from "./MonitorAlertSettingPopup";
import {Button} from "../_common/htmlTags";
import Grid from "@material-ui/core/Grid";
import devconsole from "../_common/devconsole";
import MappedDataChecker from "./MappedDataChecker";

const WrappedTab = (props) => {
  const { scopes, ...otherProps } = props;
  return (
    <PermissionsGate scopes={scopes}>
      <Tab {...otherProps} />
    </PermissionsGate>
  )
};
WrappedTab.muiName = "Tab";

const useStyles = makeStyles((theme) => ({
  // KF: The label of the active tab should be highlighted with the green line under the label.
  // I cannot make it work with dynamic tabs. Below is my attempt to fix this, but 
  // result does not look as beautiful, e.g. there ae silly margins on the left and
  // right of the highlighting and there's no animation. That's all I coould do
  // for now. We should give it another go before too long.
  root: {
    borderBottom: '1px solid #BCCBCE',
    '& .MuiTabs-indicator': {
      backgroundColor: "transparent", // theme.palette.color.primary.main,
    },
  },
  cardRoot: {
    display: "flex",
    flexDirection: "column",
    //flexGrow: 1,
    minHeight: 0,
    marginBottom: theme.spacing(3)
  },
  cardContent: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    minHeight: 0,
  },
  tabLabel: {
    color: "#000031",
    fontWeight: "400",
    padding: "0px 20px 12px 20px",
    marginBottom: "-6px",
  },
  activeTabLabel: {
    fontWeight: "400",
    color: theme.palette.color.primary.main,
    padding: "0px 20px 10px 20px",
    marginBottom: "-6px",
    borderColor: theme.palette.color.primary.main,
    borderBottom: '2px solid',
  },
  dataSourcesTabPanel: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    minHeight: 0,
  },
}));

const Monitor = () => {
  const classes = useStyles();
  const selectedVenue = useSelector((state) => state.venueReducer.selectedVenue);
  //const [selectedTab, setSelectedTab] = useState("pmyvisionmessagechecker");
  const [selectedTab, setSelectedTab] = useState("incidents");
  const [showPopup, setShowPopup] = useState(false);
  const [alertMonitoring, setAlertMonitoring] = useState(null);
  const handleTabSelectionChange = (e, selected) => {
    setSelectedTab(selected);
  };
  devconsole.log(selectedTab);
  return (
    <>
      {selectedVenue !== null && selectedVenue !== undefined ? (
          <>

        <Grid container spacing={4}>
          <Grid item xs={12} container justifyContent="space-between">
            <Typography variant="h4" className={classes.title}>
              Monitor
            </Typography>
            <Button
              variant="contained"
              color="primary"
              startIcon={<AddAlertIcon />}
              onClick={() => {
                setAlertMonitoring(true);
                setShowPopup(true);
              }}
            >
              Monitor Alert
            </Button>
          </Grid>
          <Grid item xs={12} >
            <Card
              className={classes.cardRoot}
              style={selectedTab === "data-sources"? {
                flexGrow: 1
              }: null}
            >
              <CardContent
                className={classes.cardContent}>
                <TabContext value={selectedTab}>
                  <Box>
                    <Tabs value={selectedTab} classes={{ root: classes.root }} onChange={handleTabSelectionChange} scrollButtons="auto" variant="scrollable" >
                      <WrappedTab
                          scopes={[SCOPES.CAN_FULL_MANAGEMENT, SCOPES.CAN_EDIT_INCIDENTS]}
                          label={<div className={selectedTab === "incidents" ? classes.activeTabLabel : classes.tabLabel}>INCIDENTS</div>}
                          value="incidents" />
                      <WrappedTab
                        scopes={[SCOPES.CAN_FULL_MANAGEMENT, SCOPES.CAN_VIEW_PMYVISIONMESSAGECHECKER]}
                        label={<div className={selectedTab === "pmyvisionmessagechecker" ? classes.activeTabLabel : classes.tabLabel}>OCCUPANCY</div>}
                        value="pmyvisionmessagechecker" />
                      <WrappedTab
                        scopes={[SCOPES.CAN_FULL_MANAGEMENT, SCOPES.CAN_VIEW_PMYVISIONTRAFFICINOUTMESSAGECHECKER]}
                        label={<div className={selectedTab === "pmyvisiontrafficinoutmessagechecker" ? classes.activeTabLabel : classes.tabLabel}>TRAFFIC IN OUT</div>}
                        value="pmyvisiontrafficinoutmessagechecker" />
                      <WrappedTab
                        scopes={[SCOPES.CAN_FULL_MANAGEMENT, SCOPES.CAN_VIEW_PMYVISIONQUEUEMODELMESSAGECHECKER]}
                        label={<div className={selectedTab === "pmyvisionqueuemodelmessagechecker" ? classes.activeTabLabel : classes.tabLabel}>QUEUE METRICS</div>}
                        value="pmyvisionqueuemodelmessagechecker" />
                      <WrappedTab
                        scopes={[SCOPES.CAN_FULL_MANAGEMENT, SCOPES.CAN_VIEW_PMYVISIONDEMOGRAPHICMESSAGECHECKER]}
                        label={<div className={selectedTab === "pmyvisiondemographicmessagechecker" ? classes.activeTabLabel : classes.tabLabel}>DEMOGRAPHIC</div>}
                        value="pmyvisiondemographicmessagechecker" />
                      <WrappedTab
                        scopes={[SCOPES.CAN_FULL_MANAGEMENT, SCOPES.CAN_VIEW_SEETICKETSMONITOR]}
                        label={<div className={selectedTab === "seeticketsmonitor" ? classes.activeTabLabel : classes.tabLabel}>SEE TICKETS</div>}
                        value="seeticketsmonitor" />
                      <WrappedTab
                        scopes={[SCOPES.CAN_FULL_MANAGEMENT, SCOPES.CAN_VIEW_ARMMONITOR]}
                        label={<div className={selectedTab === "armmonitor" ? classes.activeTabLabel : classes.tabLabel}>ARM</div>}
                        value="armmonitor" />
                      <WrappedTab
                          scopes={[SCOPES.CAN_FULL_MANAGEMENT, SCOPES.CAN_MAPPED_DATA]}
                          label={<div className={selectedTab === "mapped-data" ? classes.activeTabLabel : classes.tabLabel}>MAPPED DATA</div>}
                          value="mapped-data" />
                    </Tabs>
                  </Box>
                  <TabPanel
                    value="pmyvisionmessagechecker">
                    <PermissionsGate
                      scopes={[SCOPES.CAN_FULL_MANAGEMENT, SCOPES.CAN_VIEW_PMYVISIONMESSAGECHECKER]} >
                      <PMYVisionMessageChecker />
                    </PermissionsGate>
                  </TabPanel>
                  <TabPanel
                    value="pmyvisiontrafficinoutmessagechecker">
                    <PermissionsGate
                      scopes={[SCOPES.CAN_FULL_MANAGEMENT, SCOPES.CAN_VIEW_PMYVISIONTRAFFICINOUTMESSAGECHECKER]} >
                      <PMYVisionTrafficInOutMessageChecker />
                    </PermissionsGate>
                  </TabPanel>
                  <TabPanel
                    value="pmyvisionqueuemodelmessagechecker">
                    <PermissionsGate
                      scopes={[SCOPES.CAN_FULL_MANAGEMENT, SCOPES.CAN_VIEW_PMYVISIONQUEUEMODELMESSAGECHECKER]} >
                      <PMYVisionQueueModelMessageChecker />
                    </PermissionsGate>
                  </TabPanel>
                  <TabPanel
                    value="pmyvisiondemographicmessagechecker">
                    <PermissionsGate
                      scopes={[SCOPES.CAN_FULL_MANAGEMENT, SCOPES.CAN_VIEW_PMYVISIONDEMOGRAPHICMESSAGECHECKER]} >
                      <PMYVisionDemographicMessageChecker />
                    </PermissionsGate>
                  </TabPanel>
                  <TabPanel
                    value="seeticketsmonitor">
                    <PermissionsGate
                      scopes={[SCOPES.CAN_FULL_MANAGEMENT, SCOPES.CAN_VIEW_SEETICKETSMONITOR]} >
                      <SeeTicketsMonitor />
                    </PermissionsGate>
                  </TabPanel>
                  <TabPanel
                    value="armmonitor">
                    <PermissionsGate
                      scopes={[SCOPES.CAN_FULL_MANAGEMENT, SCOPES.CAN_VIEW_ARMMONITOR]} >
                      <ArmMonitor />
                    </PermissionsGate>
                  </TabPanel>
                  <TabPanel
                    value="incidents">
                    <PermissionsGate
                      scopes={[SCOPES.CAN_FULL_MANAGEMENT, SCOPES.CAN_EDIT_INCIDENTS]} >
                      <Incidents />
                    </PermissionsGate>
                  </TabPanel>
                  <TabPanel
                      value="mapped-data">
                    <PermissionsGate
                        scopes={[SCOPES.CAN_FULL_MANAGEMENT, SCOPES.CAN_MAPPED_DATA]} >
                      <MappedDataChecker />
                    </PermissionsGate>
                  </TabPanel>
                </TabContext>
              </CardContent>
            </Card>
          </Grid>
         
        </Grid>
        {alertMonitoring !== null ?
            <MonitorAlertSettingPopup
                selectedVenueId={selectedVenue?.id}
                show={showPopup}
                onCancel={() => {
                  setAlertMonitoring(null);
                  setShowPopup(false);
                }}
                onSave={() => {
                  setAlertMonitoring(null);
                  setShowPopup(false);
                }}
            /> : "" }
          </>
      ) : (
        <div>
          <Typography variant="h4">Please select venue first</Typography>
        </div>
      )}
    </>
  )
}

export default Monitor;