import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import {
    Box,
    Typography, 
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  ...theme.widget.ticketAttendanceChart,
}));

const titleBaseFontSize = 12;
const valueBaseFontSize = 16;
const swatchBaseSize = 20;

export const LegendItem = ({metric, title, value, scaleFactor}) => {
  const classes = useStyles({
    scaleFactor: scaleFactor
  });

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start'
      }}
      className={classes.legendItemContainer}
    >  
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center'
        }}
      >
        <Box
          className = {`${classes[metric + 'Legend']} ${classes.legendColorSwatch}`}
        />
        <Typography className={classes.legendTitle}>
          {title}
        </Typography>
      </Box>

      <Typography className={classes.legendValue}>
        {value.toLocaleString()}
      </Typography>  
    </Box>
  );
};

export default LegendItem;