import React from "react";
import appViewModeConstants from "../../../_constants/app.view.mode.constants";
import { Typography, Box } from "@material-ui/core";
import CommonWidgetLayout from "../shared/CommonWidgetLayout";

const TrafficInOutPercentage = (props) => {
    const { data, viewMode, classes, textColor, height, settings } = props;

    const isViewMode = viewMode === appViewModeConstants.VIEW_MODE;
    const percentage = isViewMode ? Math.round(data?.percentageValue ?? 0) : 0;

    const location = data?.location ?? settings?.RefLocation;
    const baseLineLocation = data?.baseLineLocation ?? settings?.RefBaseLineLocation;

    const locationCustomName = settings?.WidgetSettingCustomName;
    const baseLineLocationCustomName = settings?.WidgetSettingBaseLineCustomName;

    const locationTimePeriodName = settings?.WidgetSettingTimePeriod?.name;
    const baseLineLocationTimePeriodName = settings?.WidgetSettingBaseLineTimePeriod?.name;

    return (
        <Box display="flex" height={height}>
            <Box className={classes.containerBox}>
                <Typography className={classes.percentageValue} style={{ color: textColor }}>
                    {Number.isFinite(percentage) ? `${percentage?.toLocaleString("en-US")}%` : "N/A"}
                </Typography>

                <Box display="flex" height={height}>
                    <Box display="flex" className={`${classes.valueComparisonContainer}`}>
                        <Box className={classes.containerBox}>
                            <CommonWidgetLayout
                                name={!locationCustomName ? location?.name : locationCustomName}
                                timePeriod={locationTimePeriodName}
                                contrastFontColor={textColor}
                            />
                        </Box>
                    </Box>

                    <Box display="flex" className={classes.valueComparisonDividerContainer}>
                        <Box display="flex" className={classes.valueComparisonDividerTraffic}>/</Box>
                    </Box>

                    <Box display="flex" className={`${classes.valueComparisonContainer}`}>
                        <Box className={classes.containerBox}>
                            <CommonWidgetLayout
                                name={!baseLineLocationCustomName ? baseLineLocation?.name : baseLineLocationCustomName}
                                timePeriod={baseLineLocationTimePeriodName}
                                contrastFontColor={textColor}
                            />
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}

export default TrafficInOutPercentage;