import WidgetTicketScansAndSalesContent from "./WidgetTicketScansAndSalesContent";
import { WidgetWrapper } from "../WidgetWrapper";
import { widgetTypeConstants } from "../../../_constants";
import { WidgetContextProvider } from "../WidgetContext";

export const WidgetTicketScansAndSales = (props) => (
    <WidgetContextProvider>
        <WidgetWrapper
            {...{ ...props, widgetType: widgetTypeConstants.widgetTicketScansAndSales.id }}
        >
            <WidgetTicketScansAndSalesContent />
        </WidgetWrapper>
    </WidgetContextProvider>
);

export default WidgetTicketScansAndSales;