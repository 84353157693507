import React, {useEffect, useState} from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography } from "@material-ui/core";
import EmptyContainer from "../EmptyContainer";
import QueueMetric from "./QueueMetric";
import appViewModeConstants from "../../../_constants/app.view.mode.constants";

const useStyles = makeStyles((theme) => ({
    ...theme.widget.queueModelWidget,
    ...theme.widget.emptyContainer,
    ...theme.widget.flashingWidget,
}));

export const WidgetQueueModelContent = (props) => {
    const { height, settings, brandingSettings, data, onAudioPlay, viewMode } = props;

    const classes = useStyles({ props, ...brandingSettings });
    
    const location = settings?.RefLocation;

    const [playSound, setPlaySound] = useState(false);

    useEffect(() => {
        setPlaySound(viewMode === appViewModeConstants.VIEW_MODE && data?.alertRule?.sound);
    }, [data?.alertRule?.sound, setPlaySound]);

    useEffect(() => {
        if (playSound) {
            onAudioPlay();
        }
    }, [playSound, onAudioPlay]);

    const bgcolorClass = classes.defaultBackground;
    const isViewMode = viewMode === appViewModeConstants.VIEW_MODE;
    const isAlertFlash =  isViewMode ? data?.alertRule?.color?.flash : false;
    const alertColor = isViewMode ? data?.alertRule?.color?.value : null;

    //debugger;
    const isQueueLength = settings?.RefQueueModelAlertMetric?.id == "queueLength";
    const isTotalQueueTime = settings?.RefQueueModelAlertMetric?.id == "totalQueueTime";
    const isThroughput = settings?.RefQueueModelAlertMetric?.id == "throughput";
    //console.log(data);

    return (
        <Box 
            sx={{
                display: "flex",
                minHeight: 0
            }}
            className={`${classes.container} ${classes.bordered} ${bgcolorClass} ${isAlertFlash ? classes.flashEffect : ''}`}
            height={height}
            bgcolor={alertColor}
        >
            {location?
                <Box 
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "stretch",
                        minWidth: 0,
                        justifyContent: "flex-start",
                        //background: "red"
                    }}>
                    <Typography
                        variant="body1" 
                        component="div"
                        className={classes.location}>
                        {location.name}
                    </Typography>
                    <Box
                        sx={{
                            marginLeft: 'auto',
                            marginRight: 'auto'
                        }}
                    >
                    <QueueMetric 
                        title={settings.RefQueueModelAlertMetric.name}
                        value={isViewMode ? 
                            isQueueLength ?
                                data?.queueLength 
                            : isTotalQueueTime ?
                                data?.totalQueueTime
                            : isThroughput ?
                                data?.throughput
                            :
                                0:0
                        }
                        size="large"
                        />
                    </Box>
                </Box>
            : <EmptyContainer />
            }
        </Box>
    );
};

export default WidgetQueueModelContent;

//
                        {/*<QueueMetric */}
                        {/*    title="Avg service time"*/}
                        {/*    value={isViewMode ? data?.avgServiceTime: 0}*/}
                        {/*    size="small"*/}
                        {/*    formatAsTime*/}
                        {/*/>*/}
                        {/*<Box*/}
                        {/*    sx={{*/}
                        {/*        width: "10px"*/}
                        {/*    }}*/}
                        {/*/>*/}
{/* <Box
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "flex-start",
                            marginLeft: 'auto',
                            marginRight: 'auto'
                        }}>

                        <QueueMetric 
                            title="Total queue time"
                            value={isViewMode ? data?.totalQueueTime: 0}
                            size="small"    
                            formatAsTime
                        />
                    </Box> */}