import {useState, useEffect, useReducer } from 'react';

const apiGetConstants = {
  GET_START: 'GET_START',
  GET_SUCCESS: 'GET_SUCCESS',
  GET_ERROR: 'GET_ERROR',
};

const apiGetReducer = (state, action) => {
  switch (action.type) {
    case apiGetConstants.GET_START:
      return {
        ...state,
        isLoading: true,
        isError: false,
        errorMessage: null,
      };
    case apiGetConstants.GET_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        data: action.payload,
        errorMessage: null,
      };
    case apiGetConstants.GET_ERROR:
      return {
        ...state,
        isLoading: false,
        isError: true,
        errorMessage: action.error,
      };
    default:
      throw new Error(`Unexpected action type ${action.type} in apiGetReducer.`);
  }
};

export const useApiGet = (apiCall, initialParams, initialData) => {
  const [params, setParams] = useState(initialParams);
  const [needsRefresh, refresh] = useState({}); 

  const [state, dispatch] = useReducer(apiGetReducer, {
    isLoading: true,
    isError: false,
    data: initialData,
    errorMessage: null,
  });

  useEffect(() => {
    let mounted = true;

    const loadData = async () => {
      dispatch({ type: apiGetConstants.GET_START });

      try {
        const result = await apiCall(params);

        if (mounted) {
          dispatch({ type: apiGetConstants.GET_SUCCESS, payload: result });
        }
      } catch (error) {
        if (mounted) {
          dispatch({ type: apiGetConstants.GET_ERROR, errorMessage: error });
        }
      }
    };

    loadData();

    return () => {
      mounted = false;
    };
  }, [params, apiCall, needsRefresh]);

  return [{...state, refresh: () => refresh({})}, setParams];
};