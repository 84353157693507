import React, {useState, useEffect, useRef} from 'react';
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from "@material-ui/core";
import DoneIcon from "@material-ui/icons/Done";
import ClearIcon from "@material-ui/icons/Clear";
import { toast } from "react-toastify";
import LoadPleaseWait from "../../../../notification/LoadingPleaseWait/LoadingMessage";
import {useApiGet} from "../../../../../_helpers/useApiGet";
import {getSeeTicketsApiSettings, updateSeeTicketsApiSettings} from "../../../../../_services/venue.seetickets.service";
import {getVenueLocations} from "../../../../../_services/venue.locations.service";
import {NIL as NIL_UUID} from "uuid";

const useStyles = makeStyles((theme) => ({
  ok: {
    color: theme.palette.color.success.main,
    width: "30px",
    height: "100%",
    marginRight: "0px"
  },
  cancel: {
    color: theme.palette.color.danger.main,
    width: "30px",
    height: "100%"
  },
  saveIcon: {
    color: theme.palette.color.success.main,
    height: "100%",
    marginRight: "0px"
  },
  cancelIcon: {
    color: theme.palette.color.danger.main,
    height: "100%"
  },
  textField: {
    marginTop: theme.spacing(3),
    width: '500px'
  },
  selectControl: {
    width: '500px',
    marginTop: theme.spacing(3),
  },
  selectLocation: {
    '& div': {
      marginLeft: 0
    }
  },
  locationType: {
    color: theme.palette.color.secondary.main,
    marginLeft: theme.spacing(1)
  }
}));

const getSeeTicketsApiSettingsCall = async (venueId) => {
  if (venueId) {
    const result = await getSeeTicketsApiSettings(venueId);
    return result.data;
  } else {
    return null;
  }
};

const sortByName = (a, b) => {
  if (a.name < b.name) {
    return -1;
  }
  if (a.name > b.name) {
    return 1;
  }
  return 0;
};

const flattenLocations = ({location, level = 0}) => {
  const result = [
    {
      id: location.id,
      name: location.name,
      isSensor: location.locationType.isSensor,
      isDeleted: location.isDeleted,
      level: level,
      locationTypeName: location.locationType.name
    }];
  
  location.children.sort(sortByName).forEach((childLocation) => {
    result.push(...flattenLocations({location: childLocation, level: level + 1}));
  });

  return result;
};

export const SeeTicketsApiSettings = ({venue}) => {
  const classes = useStyles();

  // The "data" here is the See Tickets API settings as they were loaded from the database.
  const [{data: initialSettings, isLoading : isLoading1}] = useApiGet(getSeeTicketsApiSettingsCall, venue.id, null);

  // The settings is the same as the initialSettings until the user edits and saves
  // one for the fields. The settings variable will be set to the newly saved value.
  const [settings, setSettings] = useState("");

  // If cached[field] is null, then the field have not been edited since
  // the page loaded from the database. If the cached[field] is not null (the empty string is not null), 
  // then at least one of the fields has been edited.
  const [cachedApiUrl, setCachedApiUrl] = useState(null);
  const [cachedToken, setCachedToken] = useState(null);
  const [cachedHistorySyncCron, setCachedHistorySyncCron] = useState(null);
  
  const [isSaving, setIsSaving] = useState(false);

  const isMounted = useRef(false);
  useEffect(() => {
    isMounted.current = true;
    return () => { isMounted.current = false }
  }, []);

    // This is a flat array of all locations.
  const [locations, setLocations] = useState([]);

  useEffect(() => {
    const loadData = async (venueId) => {
      getVenueLocations(venueId).then((response) => {
        if (isMounted.current) {
          const locationHierarchy = response.data;
          if (locationHierarchy && locationHierarchy.length > 0) {
            const rootLocation = locationHierarchy[0];
            setLocations(flattenLocations({location:rootLocation}));
          }
        }
      });
    };
    loadData(venue.id);
    return () => {
      //layoutRef.current = false;
    };
  }, [venue.id]); 

  useEffect(() => {
    setSettings(initialSettings);
    setCachedApiUrl(null);
    setCachedToken(null);
    setCachedHistorySyncCron(null);
  }, [initialSettings]);

  const saveApiUrl = async () => {
    if (isSaving) {
      return;
    }

    let settingsToSave = {
      ...settings,
      VenueId: venue.id,
      ApiUrl: cachedApiUrl ?? settings.apiUrl,
      Token: null,
      DefaultLocationId: settings.defaultLocationId === NIL_UUID ? null : settings.defaultLocationId,
      HistorySyncCron: settings.historySyncCron
    }

    setIsSaving(true);
    updateSeeTicketsApiSettings(settingsToSave)
    .then(() => {
      toast.success("See Tickets API settings have been saved.");
      setSettings(prev => ({
        ...prev,
        apiUrl: cachedApiUrl ?? settings.apiUrl
      }));
      setCachedApiUrl(null);
      setIsSaving(false);
    })
    .catch((error) => {
      toast.error("Failed to save See Tickets API settings." + error.message, {autoClose: false});
      setIsSaving(false);
    });
  };

  const saveToken = async () => {
    if (isSaving) {
      return;
    }

    let settingsToSave = {
      ...settings,
      VenueId: venue.id,
      ApiUrl: settings.apiUrl,
      Token: cachedToken ?? settings.token,
      DefaultLocationId: settings.defaultLocationId === NIL_UUID ? null : settings.defaultLocationId,
      HistorySyncCron: settings.historySyncCron
    }

    setIsSaving(true);
    updateSeeTicketsApiSettings(settingsToSave)
    .then(() => {
      toast.success("See Tickets API settings have been saved.");
      setSettings(prev => ({
        ...prev,
        token: cachedToken ?? settings.token
      }));
      setCachedToken(null);
      setIsSaving(false);
    })
    .catch((error) => {
      toast.error("Failed to save See Tickets API settings." + error.message, {autoClose: false});
      setIsSaving(false);
    });
  };

  const saveDefaultLocation = async (newLocationId) => {
    if (isSaving) {
      return;
    }

    let settingsToSave = {
      ...settings,
      VenueId: venue.id,
      ApiUrl: settings.apiUrl,
      Token: null,
      DefaultLocationId: newLocationId === NIL_UUID ? null : newLocationId,
      HistorySyncCron: settings.historySyncCron
    }

    setIsSaving(true);
    updateSeeTicketsApiSettings(settingsToSave)
    .then(() => {
      toast.success("See Tickets API settings have been saved.");
      setSettings(prev => ({
        ...prev,
        defaultLocationId: newLocationId
      }));
      setIsSaving(false);
    })
    .catch((error) => {
      toast.error("Failed to save See Tickets API settings." + error.message, {autoClose: false});
      setIsSaving(false);
    });
  };

  const saveHistorySyncCron = async () => {
    if (isSaving) {
      return;
    }

    let settingsToSave = {
      ...settings,
      VenueId: venue.id,
      ApiUrl: settings.apiUrl,
      Token: null,
      DefaultLocationId: settings.defaultLocationId === NIL_UUID ? null : settings.defaultLocationId,
      HistorySyncCron: cachedHistorySyncCron ?? settings.historySyncCron
    }

    setIsSaving(true);
    updateSeeTicketsApiSettings(settingsToSave)
    .then(() => {
      toast.success("See Tickets API settings have been saved.");
      setSettings(prev => ({
        ...prev,
        historySyncCron: cachedHistorySyncCron ?? settings.historySyncCron
      }));
      setCachedHistorySyncCron(null);
      setIsSaving(false);
    })
    .catch((error) => {
      toast.error("Failed to save See Tickets API settings." + error.message, {autoClose: false});
      setIsSaving(false);
    });
  };

  let renderedLocationsToChooseFrom = 
    [      
      <MenuItem
        key={NIL_UUID}
        value={NIL_UUID}
      >
        <Box>
          {"<none>"}
        </Box>
      </MenuItem>,
      ...locations
        .filter(l => !l.isDeleted)
        .map( l => {
          return (
            <MenuItem
            key={l.id}
            value={l.id}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: 'space-between',
                width: '100%'
              }}
              ml={l.level*2}>
                <div>{l.name}</div>
                <div className={classes.locationType}>
                  {l.locationTypeName}
                </div>
            </Box>
          </MenuItem>
          );
        })
      ];

  return (
    <div>
      {venue !== null ? (
        <LoadPleaseWait show={isLoading1} >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
              marginBottom: "16px"
            }}>
            <Typography variant="h5">See Tickets API settings</Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                marginTop: "6px"
              }}>
              <TextField
                id="apiUrl"
                label = "API URL"
                placeholder="API URL"
                variant="outlined"
                name="apiUrl"
                value={cachedApiUrl === null ? settings?.apiUrl ?? '' : cachedApiUrl}
                onChange={(e) => {
                  const { value } = e.target;
                  setCachedApiUrl(value);
                }}
                className={classes.textField}
                InputProps={cachedApiUrl !== null ? {
                  endAdornment: (
                    <InputAdornment
                      position="end">
                      <IconButton 
                        edge="end" 
                        className={classes.ok}
                        onClickCapture={(e) => saveApiUrl()} 
                        onMouseDown={e => e.stopPropagation()}
                      >
                        <DoneIcon />
                      </IconButton>
                      <IconButton 
                        edge="end" 
                        className={classes.cancel}
                        onClickCapture={(e) => setCachedApiUrl(null)} 
                        onMouseDown={e => e.stopPropagation()}
                      >
                        <ClearIcon />
                      </IconButton>
                    </InputAdornment>
                  )}
                    :null
                  } 
              />
              <TextField
                id="token"
                label = "Token"
                placeholder="Token"
                variant="outlined"
                name="token"
                //type="password"
                value={cachedToken === null ? (settings?.token ?? '') : cachedToken}
                onChange={(e) => {
                  const { value } = e.target;
                  setCachedToken(value);
                }}
                onFocus={(e) => {
                  if (settings?.token && settings.token === initialSettings?.token) {
                    setCachedToken('');
                  }
                }}
                className={classes.textField}
                InputProps={cachedToken !== null ? {
                  endAdornment: (
                    <InputAdornment
                      position="end">
                      <IconButton 
                        edge="end" 
                        className={classes.ok}
                        onClickCapture={(e) => saveToken()} 
                        onMouseDown={e => e.stopPropagation()}
                      >
                        <DoneIcon />
                      </IconButton>
                      <IconButton 
                        edge="end" 
                        className={classes.cancel}
                        onClickCapture={(e) => setCachedToken(null)} 
                        onMouseDown={e => e.stopPropagation()}
                      >
                        <ClearIcon />
                      </IconButton>
                    </InputAdornment>
                  )}
                    :null
                  } 
              />
              <FormControl
                variant="outlined"
                className={classes.selectControl}
                fullWidth
              >
                <InputLabel id="choose-location-label">Default location</InputLabel>
                <Select
                  value={
                    locations.length > 0 ?
                      settings?.defaultLocationId ?? NIL_UUID
                    : NIL_UUID}
                  onChange={(e) => {
                    const newLocationId = e.target.value;
                    setSettings(prev => ({
                      ...prev,
                      defaultLocationId: newLocationId
                    }));
                    saveDefaultLocation(newLocationId);
                  }}
                  label="Default location"
                  classes={{
                    select: classes.selectLocation
                  }}
                >
                  {renderedLocationsToChooseFrom}
                </Select>
              </FormControl>
              <TextField
                id="historySyncCron"
                label = "History reconciliation schedule (cron)"
                placeholder="History reconciliation schedule (cron)"
                variant="outlined"
                name="historySyncCron"
                value={cachedHistorySyncCron === null ? settings?.historySyncCron ?? '' : cachedHistorySyncCron}
                onChange={(e) => {
                  const { value } = e.target;
                  setCachedHistorySyncCron(value);
                }}
                className={classes.textField}
                InputProps={cachedHistorySyncCron !== null ? {
                  endAdornment: (
                    <InputAdornment
                      position="end">
                      <IconButton 
                        edge="end" 
                        className={classes.ok}
                        onClickCapture={(e) => saveHistorySyncCron()} 
                        onMouseDown={e => e.stopPropagation()}
                      >
                        <DoneIcon />
                      </IconButton>
                      <IconButton 
                        edge="end" 
                        className={classes.cancel}
                        onClickCapture={(e) => setCachedHistorySyncCron(null)} 
                        onMouseDown={e => e.stopPropagation()}
                      >
                        <ClearIcon />
                      </IconButton>
                    </InputAdornment>
                  )}
                    :null
                  } 
              />
            </Box>
          </Box>
        </LoadPleaseWait>
      ) : (
        <div>
          <Typography variant="h4">Please select venue first</Typography>
        </div>
      )}
    </div>
  );
};

export default SeeTicketsApiSettings;