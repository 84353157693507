import axios from "axios";
import { AuthService} from ".";
import { ApiRootConstants } from "../_constants/index";

const constants = {
  api: `${ApiRootConstants.venueManagementApiRoot}api/VenueAccredit`,
};

const authService = new AuthService();

export async function getAccreditApiSettings(venueId) {
  const user = await authService.getUser();
  if (user && user.access_token) {
    const headers = {
      Accept: "application/json",
      Authorization: "Bearer " + user.access_token,
    };

    if (headers !== null) {
      return axios
        .get(
          `${constants.api}/GetApiSettings/${venueId}`,
          { headers }
        )
        .catch((error) => {
          console.error(error);
          if (error.response) {
            if (error.response.status === 401) {
              throw new Error("User is not logged in");
            }
            else {
              throw error;
            }
          }
        });
    }
  }
};

export async function updateAccreditApiSettings(apiSettings) {
  const user = await authService.getUser();
  if (user && user.access_token) {
    const options = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + user.access_token,
        ContentType: "multipart/form-data"
      },
    };

    const formData = apiSettings;
    
    return await axios
      .put(`${constants.api}/UpdateApiSettings`, 
        formData,
        options)
      .catch((error) => {
        console.error(error);
        if (error.response.status === 401) {
          throw new Error("User is not logged in");
        } else if (error.response.status === 422) {
          throw new Error(error.response.data.message);
        }

        throw error;
      });
  }
};
