import React from "react";
import { Box, Tooltip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

const useStyles = makeStyles((theme) => {
  return {
    infoIcon: {
      color: theme.palette.color.secondary.main,
      height: "18px",
      width: "18px",
      marginLeft: theme.spacing(1),
    },
    popoverLikeTooltip: {
      backgroundColor: theme.palette.common.white,
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: "0px 0px 4px 0px #00000040",
      width: "200px",
    },
    popoverLikeTooltipArrow: {
      "&::before": {
        backgroundColor: theme.palette.common.white,
        boxShadow: "0px 0px 4px 0px #00000040",
      }
    },
  };
});

export const WidgetHelp = ({help}) => {
  const classes = useStyles();

  return (
    <Tooltip 
      title={help}
      arrow
      placement="top-start"
      classes={{
        tooltip: classes.popoverLikeTooltip,
        arrow: classes.popoverLikeTooltipArrow
      }}
    >
      <InfoOutlinedIcon
        className={classes.infoIcon}
      />
    </Tooltip>
  )
};

export default WidgetHelp;