import React from 'react';
import { useEffect, useState} from 'react';
import { useSelector } from 'react-redux';
import {getTableauToken} from "../../_services/report.service";
import { useParams } from "react-router";
import { makeStyles } from '@material-ui/core';
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => {
  return {
    iframe: {
      border: 0,
      width: "100%",
      height: "calc(100vh - 110px)"
    }
  };
});

export const LookerStudioReport =() => {
  const classes = useStyles();
  const { lookerStudioViewId } = useParams();

  const selectedVenue = useSelector((state) => state.venueReducer.selectedVenue);

  const lookerStudioView = useSelector((state) => 
    state.venueReducer.reportViews.find(tv => tv.id === lookerStudioViewId)
  );

  return (
    <iframe
      width="600"
      height="450"
      src={lookerStudioView.url}
      frameborder="0"
      className={classes.iframe}
      allowfullscreen
      sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox allow-downloads"
    ></iframe>
  );
};

export default LookerStudioReport;
