import React, {useEffect, useState} from "react";
import * as incidentApi from "../../_services/incident.service";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  IconButton, 
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import {Button, ButtonType} from "../_common/htmlTags";
import {makeStyles} from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import { NIL as NIL_UUID, validate} from "uuid";
import {toast} from "react-toastify";
import { useApiGet } from "../../_helpers/useApiGet";
import {getVenueLocations} from "../../_services/venue.locations.service";
import { utcDateStringToLocaleString } from "./Incidents";

const useStyles = makeStyles((theme) => {
  return {
    deleteIcon: { 
      color: theme.palette.color.primary.red
    },

    popup: {
      width: "500px",
      backgroundColor: "#FFFFFF",
      position: "absolute",
      top: "50%",
      left: "50%",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      padding: theme.spacing(3),
    },
    title: {
      fontSize: "24px",
      fontWeight: "500",
      flexGrow: 1
    },
    closeIcon: {
      marginTop:"-20px",
      marginRight: "-20px"
    },
    label: {
      marginTop: theme.spacing(1)
    },
    labelName: {
      width: "55px"
    },
    labelValue: {
    },
    selectLocationInvitation: {
      color: theme.palette.color.secondary.main
    },
    locationMenuItem: {
      maxWidth: "400px",
      "& $locationFilterMenuItemText": {
        textWrap: "wrap"
      }
    },
    locationMenuItemText: {
      textOverflow: "ellipsis",
      overflow: "hidden",
    },
    field: {
      marginTop: theme.spacing(2),
    },
    saveButton: {
      marginRight: theme.spacing(2)
    },
    cancelButton: {

    }
  };
});

const getVenueLocationsCall = async (venueId) => {
  const result = await getVenueLocations(venueId);
  if ( result.data?.length) {
    const rootLocation = result.data[0];
    return flattenLocations({location: rootLocation});
  } else {
    return [];
  }
};

const flattenLocations = ({location, level = 0}) => {
  const result = [
    {
      id: location.id,
      name: location.name,
      isSensor: location.locationType.isSensor,
      isDeleted: location.isDeleted,
      level: level
    }];
  
  location.children.sort((a,b) => a.name.localeCompare(b.name)).forEach((childLocation) => {
    result.push(...flattenLocations({location: childLocation, level: level + 1}));
  });

  return result;
};

export const IncidentPopup = ({onSave, onCancel, incident}) =>
{
  const classes = useStyles();

  const [{data: locations}] = useApiGet(getVenueLocationsCall, incident.venueId, []);

  const [currentIncident, setCurrentIncident] = useState({
    ...incident
  });
    
  const [isSaving, setIsSaving] = useState(false);

  const handleSave = (e) => {
    if ((currentIncident.description?.trim() ?? "") === "") {
      toast.error("Description cannot be blank.");
      return;
    }
    setIsSaving(true);
    if(currentIncident.id === NIL_UUID) {
      incidentApi.addIncident(currentIncident)
        .then(result => {
          setIsSaving(false);
          toast.success("Incident added successfully.");
          onSave();
        })
        .catch((error) => {
          setIsSaving(false);
          toast.error("Failed to add the incident. " + error, { autoClose: false });
          console.error(error);
        });
    } else {
      incidentApi.updateIncident(currentIncident)
        .then(result => {
          setIsSaving(false);
          toast.success("Incident updated successfully.");
          onSave();
        })
        .catch((error) => {
          setIsSaving(false);
          toast.error("Failed to save the incident. " + error, { autoClose: false });
          console.error(error);
        });
    }
  };

  return (
    <Modal open>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          maxHeight: "920px",
          alignItems: "stretch"
        }}
        className={classes.popup}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start"
          }}
        >
          <Typography className={classes.title}>
            Incident details
          </Typography>
          <Tooltip 
            title="Close" 
          >
            <IconButton
              aria-label="view"
              className={classes.closeIcon}
              onClick={(e) => {
                onCancel();
              }}
            >
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </Box>

        <Box
          sx={{
            display: "flex",
          }}
          className={classes.label}
        >
          <Typography variant="subtitle2" className={classes.labelName}>Created:</Typography>
          <Typography variant="subtitle2" className={classes.labelValue}>
            {currentIncident.id == NIL_UUID ? '—' : utcDateStringToLocaleString(currentIncident.createdDate)}
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
          }}
          className={classes.label}
        >
          <Typography variant="subtitle2" className={classes.labelName}>Resolved:</Typography>
          <Typography variant="subtitle2" className={classes.labelValue}>
            {currentIncident.resolvedDate ? utcDateStringToLocaleString(currentIncident.resolvedDate) : '—'}
          </Typography>
        </Box>

        <TextField 
          className={classes.field}
          id="description" 
          label="Description" 
          name="incidentDescription" 
          multiline 
          minRows={5}
          placeholder={"Describe the incident"} 
          value={currentIncident.description} 
          variant="outlined" 
          required 
          fullWidth 
          onChange={(e) => setCurrentIncident({ ...currentIncident, description: e.target.value })}
        />

        <FormControl
          variant="outlined"
          className={classes.field}
        >
          <InputLabel id="location-label">Location</InputLabel>
          <Select
            labelId="location-label"
            id="location-select"
            name="locationId"
            style={{
              height: "42px",
            }}
            value={currentIncident.locationId == NIL_UUID ? null : currentIncident.locationId}
            onChange={(e) => {
                const newLocationId = e.target.value;
                setCurrentIncident(prev =>({
                  ...prev,
                  locationId: newLocationId
                }));
              }
            }
            label="Location"
          >
            {[
              <MenuItem key={null} value={null}>
                <Typography className={classes.selectLocationInvitation}>&lt;none&gt;</Typography>
              </MenuItem>,
              ...locations
                .filter(l => !l.isDeleted)
                .map((l) => (
                <MenuItem key={l.id} value={l.id} className={classes.locationMenuItem}>
                  <Box
                    className={classes.locationMenuItemText}
                    pl={l.level*2}>
                    <Typography>
                      {l.name}
                    </Typography>
                  </Box>
                </MenuItem>
              ))
            ]}
          </Select>
        </FormControl>     

        <FormControlLabel
          className={classes.field}
          label="Incident is resolved"
          control={
            <Checkbox
              checked={currentIncident.isResolved}
              onChange={(e) => setCurrentIncident(prev => ({
                ...prev,
                isResolved: e.target.checked
              }))}
            />
          }
        />           

        <Box
          sx={{
            display: "flex",
            mt: "8px"
          }}
        >
        <Button
            variant="contained"
            type="button"
            disabled={isSaving}
            buttonType={ButtonType.Primary}
            onClick={handleSave}
            className={classes.saveButton}
          >
            {isSaving ? "Saving..." : "Save"}
          </Button>
          <Button
            variant="outlined"
            type="button"
            buttonType={ButtonType.Secondary}
            onClick={() => {
              onCancel();
            }}
            className={classes.cancelButton}
          >
            Cancel
          </Button>
        </Box>
      </Box>
    </Modal>
  );

}

export default IncidentPopup;